import request from "./request"

// para obtener instructores
export const getCurrentAdmin = async ({idAdmin}) => {
  const url = `/administrator/${idAdmin}`
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      return data.user
    } 
  } catch (error) {
    return error
  }
}
