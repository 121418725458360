import React, { useState, useEffect} from 'react'
import { withRouter } from 'react-router'
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader'
import Modal from '../../Molecules/Modal/Modal'
import ReserveFooter from '../../Organisms/ReserveFooter/ReserveFooter'
import ReserveHeader from '../../Organisms/ReserveHeader/ReserveHeader'
import './VerifyAcount.scss'
const VerifyAcount = ({match}) => {

  const [loader, setLoader] = useState(true)
  const [successVerify, setSuccessVerify] = useState(false)
  const [errorVerify, setErrorVerify] = useState(false)

  useEffect(() => {
    setLoader(true)
    const setting = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${match.params.token}`
      },
    }
    const verifyAcount = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/verify-account`, setting);
      // console.log('response', response)
      if(response.status === 200) {
        setSuccessVerify(true)
        setLoader(false)
      } else {
        setErrorVerify(true)
        setLoader(false)
      }
    }

    verifyAcount();
  }, [match.params.token])

  return (
    <>
      <div className="container-verify-acount">
        <ReserveHeader simple/>
        <div className="reserve-body verify-acount">
          <h1 className="title-upper-blue">Verificando cuenta...</h1>
          {loader && <SpinnerLoader />}
        </div>
        <ReserveFooter />
        {successVerify && <Modal success verifySucess setShowModal={setSuccessVerify} content="SU CUENTA HA SIDO VERIFICADA" />}
        {errorVerify && <Modal error setShowModal={setErrorVerify} content="ERROR AL VERIFICAR SU CUENTA" />}
      </div>
    </>
  )
}

export default withRouter(VerifyAcount)
