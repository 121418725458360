import request from "./request"

  // Obtener todas los actvidades
export const getActivities = async (sport, reservations = 0) => {
    const url = `/activities-by-sport/${sport}/${reservations ? 1 : 0}`
    try {
      const response = await request('GET', url)
      if(response.status === 200) {
        const data = await response.json()
        return data.activities
      } else {
        throw Error('Error al obtener las actividades')
      }
    } catch (error) {
      throw Error('Error al obtener las actividades')
    }
  }
