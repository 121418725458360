import React from 'react';
import './Seat.scss';

function AdminSeat(props) {
	if (props.data === null) {
		return null
	}
	return (
		<>
			{props.data.spotId || props.data.instructor ? 
				props.data.instructor ?
				<div className={`seat mr-5 ml-5 seat-admin-instructor`} onClick={() => props.saveSeat(props.row, props.column, props.data.id)}>
					<span className='seat-number-instructor'>Instructor</span>
				</div>
				:
				<div className={`seat mr-5 ml-5 seat-available`} onClick={() => props.saveSeat(props.row, props.column, props.data.id)} >
					<span className='seat-number'>{props.data.spotId}</span>
				</div>
				:
				<div className={`seat mr-5 ml-5 seat-admin-unavailable`} onClick={() => props.saveSeat(props.row, props.column, props.data.id)}></div>
			}
		</>
	)
}

export default AdminSeat;