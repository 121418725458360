import React, {useState} from 'react';
import useModal from '../../../customHooks/useModal';
import ModalComponent from '../../Molecules/ModalComponent/ModalComponent';
import DetailReserveHistory from '../DetailReserveHistory/DetailReserveHistory';
import './ConsultaSocioDetails.scss';

const ConsultaSocioDetails = ({ penalizations, hoursPlayed }) => {
  const [isOpenModal, openModal, closeModal] = useModal(false)
  const [reserveDetailId, setReserveDetailId] = useState(null)

  const openModalAndViewReserve = async (reserveId) => {
    setReserveDetailId(reserveId)
    openModal()
  }

  return (
    <>
    <div className='main-consulta-socio-details mt-20'>
      <div className='main-consulta-socio-details'>
        <div className="main-consulta-socio-details--grid">
          <div className="main-consulta-socio-details--col-1">
            <p className="title-upper-red no-space">Penalizaciónes : </p>
              {
                penalizations.length <= 0 ? <p className="title-small-blue no-space">No tiene penalizaciones en esta semana</p>
                :
                penalizations.map(penalization => 
                <div key={penalization.name}>
                  <div className='main-consulta-socio-details-items mb-5' >
                    <p className="title-small-blue no-space">{penalization.name}: </p>
                    <span>{penalization.quantity}</span>
                  </div>
                  <div className='main-consulta-socio-details-items mb-5'>
                    <p className="title-small-blue no-space">Pertenece a la reserva:</p>
                    <span>
                      {penalization.reservations.map(num => <p className="no-space link" key={num} onClick={() => openModalAndViewReserve(num)}>{num}</p>)}
                    </span>
                  </div>
                  </div>
                )
              }
          </div>
          <div className="main-consulta-socio-details--col-2">
            <p className="title-upper-red no-space">Horas jugadas : </p>
              {
                hoursPlayed.length <= 0 ? <p className="title-small-blue no-space">No tiene horas jugadas esta semana</p>
                :
                hoursPlayed.map(hour => 
                <div className='main-consulta-socio-details-items mb-5' key={hour.name}>
                  <p className="title-small-blue no-space">{hour.name}: </p>
                  <span>{hour.quantity}</span>
                </div>
                )
              }
          </div>
        </div>
      </div>     
    </div>   
    <ModalComponent isOpen={isOpenModal} closeModal={closeModal} >
      <DetailReserveHistory reserveDetailId={reserveDetailId} closeModal={closeModal}/>
    </ModalComponent>
    </>  
  ) 
}

export default ConsultaSocioDetails;
