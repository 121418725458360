import React from 'react';
import iconTenis from '../../../assets/icon-tenis.svg';
import iconCycling from '../../../assets/icon-cycling.svg';
import iconTrx from '../../../assets/icon-trx.svg';
import iconOthers from '../../../assets/icon-others.svg';
import iconSquash from '../../../assets/icon-squash.svg';
import './ReserveInnerTitle.scss';

const icons = {
	'tenis': iconTenis,
	'cycling': iconCycling,
	'trx': iconTrx,
	'squash': iconSquash,
	'others': iconOthers
}

function ReserveInnerTitle(props) {
    return (
      <div className='flex inner-title mb-20'>
        {props.activity && <img src={icons[props.activity]} alt={props.title} className='inner-title-img'/>}
        <div>
        	<h3 className='title-upper-blue inner-title-item no-space'>{props.title}</h3>
          <h6 className='inner-title-item inner-title--subtitle mt-5'>{props.subtitle}</h6>
        </div>
      </div>
    )
}
export default ReserveInnerTitle
