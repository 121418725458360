import React, { useState, useEffect } from 'react'
import { Radio } from 'antd';

const Qualifyitem  = ({socio, confirmAttended, responsable}) => {
  const [attended, setAttended] = useState(true);

  responsable = socio.responsable

  useEffect(() => {
    confirmAttended(socio.id, attended, responsable)
    // console.log('socio', socio)
    // console.log('socio responsable', responsable)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attended, socio.id, responsable])

  return (
    <div className="reserve-qualify-list--item mb-5">
      <p className="no-space"><span className="title-small-blue reserve-qualify-list--title">{socio.firstName} {socio.lastName} </span>No. {socio.actionNumber}</p>
      <Radio.Group value={attended} className="main-container-input-radio">
        <div className="container-input-radio">
          <div className={attended ? 'active-radio-check small' : 'active-radio small'} onClick={() => setAttended(true)}>
            <Radio value={true}>Asistió</Radio>
          </div>
          <div className={!attended ? 'active-radio-check small' : 'active-radio small'} onClick={() => setAttended(false)}>
            <Radio value={false}>No asistió</Radio>
          </div>
        </div>
      </Radio.Group>
    </div>
  )
}

export default Qualifyitem
