import React, {useState, useEffect, useCallback} from 'react'
import { Button, Menu } from 'antd';
import { UserOutlined, LogoutOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import './ReserveMenu.scss';
import { logoutUser } from '../../../redux/actionsCreators';
import store from '../../../redux/store';
import request from '../../../services/request';
// import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import Modal from '../../Molecules/Modal/Modal'
import { messageCatchRequest } from '../../../services/helpers';
const { SubMenu } = Menu;


function ReserveMenu(props) {
  const [openKeys, setOpenKeys] = useState([localStorage.getItem('latestOpenKey')])
  const [loadingButtonBloking, setLoadingButtonBloking] = useState(false)
  const [settingsBloqued, setSettingsBloqued] = useState(null)
  const [showhandleBloking, setShowhandleBloking] = useState(null)
  const [successBloquedReserve, setSuccessBloquedReserve] = useState(false)
  const [errorBloquedReserve, setErrorBloquedReserve] = useState(false)
  const [bloquedMessage, setBloquedMessage] = useState('')
  const [loader, setLoader] = useState(false)

  let rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4'];

  const onOpenChange = openKey => {
    const latestOpenKey = openKey.find(key => openKeys.indexOf(key) === -1);
    localStorage.setItem('latestOpenKey', latestOpenKey)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKey);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  };


  const AdminSvg = () => (
    <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.67975 3.88018C9.65676 3.45078 9.21792 3.52384 8.78973 3.52384C9.10603 3.60808 9.40525 3.72528 9.67975 3.88018Z" fill="#2A255C"/>
      <path d="M5.10243 2.80404C3.44017 2.80404 2.67506 4.90802 4.00388 5.96621C4.45687 4.75664 5.35864 3.88814 6.54083 3.54533C6.21744 3.09826 5.69496 2.80404 5.10243 2.80404Z" fill="#2A255C"/>
      <path d="M1.66842 5.63696C1.73616 5.85434 1.82393 6.06287 1.9282 6.26045L1.38217 6.80679C1.23553 6.95342 1.23553 7.19143 1.38217 7.33837L2.34463 8.30113C2.41472 8.37122 2.51076 8.41067 2.61086 8.41067C2.70985 8.41067 2.80524 8.37122 2.87655 8.30113L3.42198 7.75479C3.50383 7.79811 3.59456 7.82466 3.67933 7.86202C3.67933 7.8558 3.67872 7.84935 3.67872 7.84313C3.67872 7.39666 3.73 6.9755 3.8213 6.58C3.16277 6.15618 2.72335 5.4205 2.72335 4.58055C2.72335 3.2685 3.79065 2.2009 5.1024 2.2009C5.99064 2.2009 6.75753 2.69538 7.16691 3.41782C7.60397 3.36362 8.01452 3.36362 8.5087 3.45701C8.44513 3.2647 8.37033 3.0765 8.27665 2.90009L8.82268 2.35349C8.89391 2.28309 8.93336 2.18735 8.93336 2.08783C8.93336 1.98826 8.89391 1.89257 8.82268 1.82217L7.86075 0.860013C7.78717 0.786392 7.69113 0.749866 7.59513 0.749866C7.49909 0.749866 7.40309 0.786392 7.33004 0.860013L6.78226 1.40661C6.58438 1.30178 6.37646 1.21401 6.15855 1.14775V0.375502C6.15855 0.168179 5.99124 0 5.78331 0H4.4221C4.21535 0 4.04626 0.168179 4.04626 0.375502V1.14771C3.82892 1.21397 3.62096 1.30174 3.42247 1.40658L2.87655 0.860582C2.80289 0.786961 2.70689 0.750435 2.61086 0.750435C2.51486 0.750435 2.41829 0.786961 2.34524 0.860278L1.38217 1.82244C1.31207 1.89314 1.27263 1.98853 1.27263 2.0884C1.27263 2.18823 1.31207 2.28366 1.38217 2.35405L1.9282 2.90035C1.8239 3.09827 1.73616 3.3065 1.66964 3.52384H0.897999C0.69007 3.52384 0.522156 3.69228 0.522156 3.89991V5.26116C0.522156 5.46818 0.69007 5.63696 0.897999 5.63696H1.66842Z" fill="#2A255C"/>
      <path d="M13.4601 12.9208C14.0621 12.5907 14.4756 11.9587 14.4756 11.2253C14.4756 10.155 13.6045 9.28449 12.5342 9.28449C12.1578 9.28449 11.8091 9.39699 11.5111 9.58311C11.3326 10.2935 11.0516 10.9729 10.6841 11.5631C11.726 11.759 12.682 12.2334 13.4601 12.9208Z" fill="#2A255C"/>
      <path d="M17.4773 10.4908C17.4773 10.2667 17.2952 10.0853 17.072 10.0853H16.2392C16.1673 9.85051 16.0719 9.62552 15.9588 9.41201L16.5483 8.82244C16.625 8.74643 16.6673 8.64337 16.6673 8.53588C16.6673 8.42835 16.6249 8.32526 16.5483 8.24902L15.5105 7.21085C15.4316 7.13158 15.3273 7.09183 15.2237 7.09183C15.12 7.09183 15.0163 7.13158 14.9374 7.21085L14.3466 7.80073C14.1334 7.68762 13.909 7.59284 13.6734 7.52123V6.68837C13.6734 6.46455 13.4925 6.2831 13.2687 6.2831H11.8003C11.7019 6.2831 11.6153 6.32254 11.5447 6.38088C11.6683 6.83205 11.7361 7.32126 11.7361 7.84314C11.7361 8.17445 11.7102 8.51085 11.6619 8.84535C11.9352 8.74461 12.2261 8.68134 12.5342 8.68134C13.9372 8.68134 15.0788 9.8226 15.0788 11.2253C15.0788 12.1218 14.6105 12.9076 13.9078 13.3608C14.4191 13.9216 14.8255 14.5868 15.09 15.3322C15.1335 15.3473 15.1789 15.3582 15.2243 15.3582C15.3279 15.3582 15.4316 15.3187 15.5111 15.2395L16.5496 14.2013C16.6256 14.1253 16.6674 14.0222 16.6674 13.9147C16.6674 13.8072 16.6256 13.7042 16.5496 13.6279L15.9588 13.038C16.0719 12.8248 16.1673 12.5997 16.2392 12.3647H17.072C17.1792 12.3647 17.2835 12.3223 17.3589 12.2457C17.4349 12.17 17.4779 12.0667 17.4779 11.9592L17.4773 10.4908Z" fill="#2A255C"/>
      <path d="M14.2629 15.6992C13.6332 13.8465 11.9693 12.5721 10.0485 12.3987C9.40526 13.0798 8.60889 13.512 7.70704 13.512C6.80588 13.512 6.0089 13.0799 5.3663 12.3987C3.44434 12.5721 1.78034 13.8465 1.15063 15.6995L0.802553 16.7241C0.702988 17.019 0.750665 17.3438 0.93212 17.5968C1.11354 17.8498 1.40571 18 1.71669 18H13.6968C14.0084 18 14.3006 17.8498 14.482 17.5968C14.6629 17.3438 14.7112 17.019 14.611 16.7241L14.2629 15.6992Z" fill="#2A255C"/>
      <path d="M4.5835 7.84313C4.5835 10.1412 5.98245 12.6072 7.70706 12.6072C9.43235 12.6072 10.8312 10.1412 10.8312 7.84313C10.8313 3.07941 4.5835 3.06997 4.5835 7.84313Z" fill="#2A255C"/>
    </svg>
  );

  const ReserveSvg = () => (
    <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.1273 14.8434L15.3236 4.71455C15.2737 4.06195 14.7216 3.55095 14.0672 3.55095H12.4794V5.9035C12.4794 6.19484 12.2436 6.43067 11.9522 6.43067C11.6613 6.43067 11.4251 6.19484 11.4251 5.9035V3.55095H6.57524V5.9035C6.57524 6.19484 6.33906 6.43067 6.04807 6.43067C5.75673 6.43067 5.5209 6.19484 5.5209 5.9035V3.55095H3.9331C3.27874 3.55095 2.72661 4.06195 2.67672 4.71314L1.87263 14.8448C1.81044 15.6563 2.09194 16.4639 2.64473 17.0606C3.19756 17.6573 3.98127 18 4.79485 18H13.2055C14.0191 18 14.8028 17.6573 15.3556 17.0606C15.9084 16.4638 16.1899 15.6562 16.1273 14.8434ZM11.3752 9.68529L8.64872 12.4118C8.54574 12.5147 8.41078 12.5664 8.27585 12.5664C8.14088 12.5664 8.00595 12.5147 7.90298 12.4118L6.62516 11.1339C6.41921 10.928 6.41921 10.5945 6.62516 10.3885C6.8311 10.1826 7.16498 10.1826 7.37058 10.3885L8.27588 11.2935L10.6298 8.93956C10.8354 8.73396 11.1693 8.73396 11.3752 8.93956C11.5811 9.1455 11.5811 9.47935 11.3752 9.68529Z" fill="#2A255C"/>
      <path d="M9.00011 0C7.0816 0 5.52087 1.56108 5.52087 3.47959V3.55092H6.57521V3.47959C6.57521 2.14235 7.66291 1.0543 9.00015 1.0543C10.3374 1.0543 11.4251 2.14235 11.4251 3.47959V3.55092H12.4794V3.47959C12.4794 1.56108 10.9186 0 9.00011 0Z" fill="#2A255C"/>
    </svg>
  );

  const RoomsSvg = () => (
    <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.632 16.8237L16.4585 15.6502C16.2292 15.4209 15.8578 15.4209 15.6285 15.6502C15.3992 15.8793 15.3992 16.2511 15.6285 16.4802L15.8005 16.6522H4.54742L4.7194 16.4802C4.94866 16.2511 4.94866 15.8793 4.7194 15.6502C4.49015 15.4209 4.11869 15.4209 3.8894 15.6502L2.71589 16.8237C2.60075 16.9386 2.54352 17.0955 2.54352 17.2391C2.54352 17.3858 2.60378 17.5426 2.71589 17.6545L3.8894 18.828C4.11869 19.0573 4.49008 19.0573 4.7194 18.828C4.94866 18.5989 4.94866 18.2271 4.7194 17.998L4.54742 17.826H15.8005L15.6285 17.998C15.3992 18.2271 15.3992 18.5989 15.6285 18.828C15.8578 19.0573 16.2292 19.0573 16.4585 18.828L17.632 17.6545C17.7469 17.5398 17.8044 17.3831 17.8044 17.2391C17.8044 17.0885 17.7442 16.9356 17.632 16.8237Z" fill="#2A255C"/>
      <path d="M2.71545 13.3024L2.54347 13.4743V3.0039L2.71545 3.17589C2.94474 3.40517 3.31613 3.40517 3.54546 3.17589C3.77475 2.9466 3.77475 2.57521 3.54546 2.34588L2.37152 1.17198C2.14227 0.942731 1.77081 0.942731 1.54152 1.17198L0.367619 2.34588C0.138331 2.57517 0.138331 2.94656 0.367619 3.17589C0.596873 3.40514 0.968333 3.40514 1.19762 3.17589L1.36957 3.0039V13.4743L1.19759 13.3023C0.968333 13.0731 0.596873 13.0731 0.367584 13.3023C0.138296 13.5316 0.138296 13.903 0.367584 14.1323L1.54148 15.3062C1.77077 15.5355 2.14216 15.5355 2.37149 15.3062L3.54539 14.1323C3.77468 13.9031 3.77468 13.5317 3.54542 13.3024C3.31617 13.0731 2.94471 13.0731 2.71545 13.3024Z" fill="#2A255C"/>
      <path d="M14.8696 5.69564H17.8044V1.58697C17.8044 1.26272 17.5414 1 17.2174 1H9.19568V5.69564H12.1304C12.4545 5.69564 12.7174 5.95836 12.7174 6.2826C12.7174 6.60685 12.4545 6.86957 12.1304 6.86957H9.19568V8.63044C9.19568 8.95468 8.93274 9.21741 8.60871 9.21741C8.28468 9.21741 8.02174 8.95468 8.02174 8.63044V1H5.47826C5.15423 1 4.8913 1.26272 4.8913 1.58697V13.7174C4.8913 14.0416 5.15423 14.3043 5.47826 14.3043H8.02174V11.3696C8.02174 11.0453 8.28468 10.7826 8.60871 10.7826C8.93274 10.7826 9.19568 11.0453 9.19568 11.3696V14.3043H17.2174C17.5414 14.3043 17.8044 14.0416 17.8044 13.7174V6.86957H14.8696C14.5456 6.86957 14.2826 6.60685 14.2826 6.2826C14.2826 5.95836 14.5455 5.69564 14.8696 5.69564Z" fill="#2A255C"/>
    </svg>
  );
  
  // Trae la config para saber si las reservas estan bloqueadoas o no
  const getSetting = useCallback(async () => {
    const response = await request('GET', '/settings')
    const data = await response.json()
    if(response.status === 200) {
      setSettingsBloqued(data.setting.blockReservations)
      setLoadingButtonBloking(false)
    }
  }, [])

  useEffect(() => {
    setLoader(false)
    getSetting()
  }, [loader, getSetting])

 
  const onLogoutUser = () => {
    props.drawer && props.setVisible(false);
    store.dispatch(logoutUser());
    localStorage.clear();
    props.history.push('/');
  }

  // bloquear/desbloquear - reservas
    const reservationBlocking = async ({ information = null }) => {
      console.log('information', information)
      setLoadingButtonBloking(true)
      const body = {
        blockReservations : settingsBloqued ? false : true,
        information
      }
      try {
        const response = await request('PATCH', '/handle-reservation-blocking', body)
        const data = await response.json()
        if(response.status === 200) {
          setBloquedMessage(data.message)
          setSuccessBloquedReserve(true)
          setLoadingButtonBloking(false)
          setShowhandleBloking(false)
          setLoader(true)
        } else {
          setBloquedMessage(data.message)
          setErrorBloquedReserve(true)
          setLoadingButtonBloking(false)
          // setShowhandleBloking(false)
        }
      } catch (error) {
        setBloquedMessage(messageCatchRequest)
        setErrorBloquedReserve(true)
        setLoadingButtonBloking(false)
        // setShowhandleBloking(false)
      }
    }

    return (
        <div className={`container-menu-vertical ${props.hidden && 'hidden'}`}>  
            <div className="container-menu-vertical--header">
              <div className="menu-vertical-img">
                <img src={props.userInfo.pictureUrl ? props.userInfo.pictureUrl : process.env.REACT_APP_DEFAULT_PHOTO_USER} /*alt={props.userInfo.privatePerson.firstName}*/ alt="Super Admin" />
              </div>
              <div className="menu-vertical-data">
                <p className="menu-vertical-data--name">Hola, {props.userInfo.privatePerson ? props.userInfo.privatePerson.firstName : 'Admin'} {props.userInfo.privatePerson && props.userInfo.privatePerson.lastName}</p>
                {props.userInfo.super && <p>Super Administrador</p>}
                {!props.userInfo.super && <p>{props.userInfo.privateUserRoleId === 1 ? 'Administrador' : 'Colaborador'}</p>}
                <p>Correo: {props.userInfo.email ? props.userInfo.email : 'N/A'}</p>
              </div>
            </div>
            <Menu 
              mode="inline" 
              onOpenChange={onOpenChange} 
              openKeys={openKeys} 
              selectedKeys={[props.location.pathname]} 
              // defaultOpenKeys={[props.location.pathname]}
              >
                <SubMenu key="sub1" icon={<UserOutlined />} title='Mis datos' onClick={ () => props.drawer && props.setVisible(false)}>
                    <Menu.Item key='/datos'><Link to='/datos'>Mis datos</Link></Menu.Item>
                    <Menu.Item key='/datos/cambiar-contraseña'><Link to='/datos/cambiar-contraseña'>Cambiar contraseña</Link></Menu.Item>
                </SubMenu>

               {props.userInfo.super && <SubMenu key='sub2' icon={<AdminSvg />} title='Administradores' onClick={ () => props.drawer && props.setVisible(false)}>
                  <Menu.Item key='/crear-administrador'><Link to='/crear-administrador'>Gestión de administradores</Link></Menu.Item>
                  <Menu.Item key='/administradores'><Link to='/administradores'>Listado de administradores</Link></Menu.Item>
                </SubMenu>}
               
                <SubMenu key='sub3' icon={<ReserveSvg />} title='Reservaciones' onClick={ () => props.drawer && props.setVisible(false)}>
                    <Menu.Item key='/reservas/lista'><Link to='/reservas/lista'>Listado de reservas</Link></Menu.Item>
                    <Menu.Item key='/consulta'><Link to='/consulta'>Consultar estatus de socio</Link></Menu.Item>
                    <Menu.Item key='/reserva/socio'><Link to='/reserva/socio'>Consultar por número</Link></Menu.Item>
                </SubMenu>

                {(props.userInfo.super || props.userInfo.privateUserRoleId === 1) && 
                  <SubMenu key='sub4' icon={<RoomsSvg />} title='Módulos' onClick={ () => props.drawer && props.setVisible(false)}>
                    <Menu.Item key='/salas/tenis'><Link to='/salas/tenis'>Listado de salas</Link></Menu.Item>
                    <Menu.Item key='/deportes'><Link to='/deportes'>Listado de deportes</Link></Menu.Item>
                    <Menu.Item key='/actividades'><Link to='/actividades'>Listado de actividades</Link></Menu.Item>
                    <Menu.Item key='/horarios'><Link to='/horarios'>Horarios</Link></Menu.Item>
                    <Menu.Item key='/instructores'><Link to='/instructores'>Instructores</Link></Menu.Item>
                    {/* <Menu.Item key='/consulta'><Link to='/consulta'>Consultar socio</Link></Menu.Item> */}
                </SubMenu>}

                {(props.userInfo.super || props.userInfo.privateUserRoleId === 1) && <SubMenu key='sub5' icon={<AdminSvg />} title='Acciones especiales'>
                  <Button loading={loadingButtonBloking} className="button turquoise outline center-btn mb-10 mt-10" onClick={() => setShowhandleBloking(true)}>{settingsBloqued ? 'Desbloquear' : 'Bloquear'} reservaciones</Button>
                </SubMenu>}

                <Menu.Item key="/" icon={<LogoutOutlined />} className="sub-rol-item" onClick={() => onLogoutUser()}>
                  Cerrar sesión
                </Menu.Item>
                {/* <div className="button-portal-desktop mt-20 mb-50 center-text "><Button className="button turquoise outline ">Ir al Portal de Socios</Button></div> */}
            </Menu>
            {showhandleBloking && <ReserveSuccessModal loading={loadingButtonBloking}/*buttonLoading={}*/ title={`${settingsBloqued ? 'DESBLOQUEAR' : 'BLOQUEAR'} RESERVACIONES`} message={`Al realizar esta acción, las reservas ${settingsBloqued ? 'volverán a quedar activas' : 'quedaran inactivas'} ¿Está seguro?`} settingsBloqued={settingsBloqued} bloquedReservations handleCancel={() => setShowhandleBloking(false)} reservationBlocking={reservationBlocking} setShowhandleBloking={setShowhandleBloking}  />}
            {successBloquedReserve && <Modal success bloquedReserved setShowModal={setSuccessBloquedReserve} content={bloquedMessage} />}
            {errorBloquedReserve && <Modal error bloquedReserved setShowModal={setErrorBloquedReserve} content={bloquedMessage} />}
        </div>
    )
}

const mapStateToProps = state => ({
  userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(ReserveMenu)
