import { LOGOUT_USER, SAVE_USER, SAVE_TABLE, SAVE_NOTIFICATIONS, SAVE_DAY } from "./actions";

const initialUserState = {
  userInfo: {}
}

export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case SAVE_USER:
      return { 
        ...state,
        userInfo: Object.assign(state.userInfo, action.data)
      }
    case SAVE_NOTIFICATIONS:
       return { 
        ...state,
        notifications: action.data[0],
        notificationsCount: action.data[1]
      } 
    case LOGOUT_USER:
      return { 
        ...state,
        userInfo: action.data
      }
    default:
    return state;
  }
}

const initialTableState = {
  sport: 1,
  especialidad: null,
  sala: null,
  tab: "Activa"
}

export const tableReducer = (state = initialTableState, action) => {
  switch (action.type) {
    case SAVE_TABLE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}

const initialDay = {
  id: 1,
  slug: 'Lunes'
}

export const dayReducer = (state = initialDay, action) => {
  switch (action.type) {
    case SAVE_DAY:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}
