import { getNotifications } from "../services/getNotifications"
import { LOGOUT_USER, SAVE_USER, SAVE_TABLE, SAVE_NOTIFICATIONS, SAVE_DAY } from "./actions"

export const saveUser = (data) => ({
  type: SAVE_USER,
  data: data
})

export const logoutUser = () => ({
  type: LOGOUT_USER,
  data: {}
})

export const saveTable = (data) => ({
	type: SAVE_TABLE,
	data: data
})
export const saveDay = (data) => ({
	type: SAVE_DAY,
	data: data
})

export const saveNotifications =  ({page})  => async dispatch  => {
  const { rows } = await getNotifications({page})
  if(rows) {
    const notificationsUnread = rows.filter(notification => notification.read !== true)
    return dispatch({
      type: SAVE_NOTIFICATIONS,
      data: [rows, notificationsUnread.length]
    })
  } else {
    return dispatch({
      type: SAVE_NOTIFICATIONS,
      data: [[], 0]
    }) 
  }
}
