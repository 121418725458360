
import React, {useState, useRef, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './FormRelationSchedule.scss';
import { connect } from 'react-redux';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import request from '../../../services/request';
import { useSports } from '../../../customHooks/useSports';
import { useActivities } from '../../../customHooks/useActivities';
import { useRooms } from '../../../customHooks/useRooms';
import { useInstructors } from '../../../customHooks/useInstructors';
import { useSchedulesByRelation } from '../../../customHooks/useSchudulesByRelation';
import SelectItemList from '../../Atoms/SelectItemList/SelectItemList'
import ModalBox from '../../Molecules/Modal/Modal';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import ErrorSelect from '../../Atoms/ErrorSelect/ErrorSelect';
import { messageCatchRequest } from '../../../services/helpers';

const FormRelationSchedule = (props) => {
  let isEdit = props.history.location.pathname.includes('/editar');
  const [sport, setSport] = useState(1) // Aqui guardo el deporte seleccionado por el select
  const [activitie, setActivitie] = useState(null) // Aqui la actividad del select
  const [room, setRoom] = useState(null) // Aqui guardo la sala del select
  const [instructor, setInstructor] = useState(null) // Aqui la instructor del select
  // eslint-disable-next-line no-unused-vars
  const [maxCoupons, setMaxCoupons] = useState(null) // Aqui el maxCoupons
  const [hasInstructor, setHasInstructor] = useState(true) // Con eso valido si una actividad tiene instructor
  const [successRelationSchedule, setSuccessRelationSchedule] = useState(false) //Mostrar modal cuando se hace la relacion
  const [successMessageRelationSchedule, setSucessMessageRelationSchedule] = useState('') //Mostrar modal cuando se hace la relacion
  const [errorRelationSchedule, setErrorRelationSchedule] = useState(false) //Mostrar modal cuando da error al hacer la relacion
  const [errorMessageRelationSchedule, setErrorMessageRelationSchedule] = useState('') //Mostrar modal cuando da error al hacer la relacion
  const [buttonLoading, setButtonLoading] = useState(false)


  const [loadingRoomWhenNull, setLoadingRoomsNull] = useState(false) // Con este valor actualizo el hook useRooms cuando escojo tenis o squash, ya que el input actividad se bloquea y no puedo cambiar su valor para actualizar las salas
  const [bloquedInput, setBloquedInput] = useState(false) // Con este valor bloque el input de actividad cuando escojo tenis o squash 
  const [loaderRoom, setLoaderRoom] = useState(false) // Con este valor recargo el input de rooms cuando estoy editando, lo recargo para que me traiga su valor por defecto. ya que sin el me deja solo el numero y no el "texto"

  const formRef = useRef()

  // Hooks
  const { loadingSport, sports, errorSports } = useSports(sport); // Hooks que retorna los deportes y el loading
  const { loadingActivities, activities, errorActivities } = useActivities({sport,  bloquedInput: bloquedInput, setBloquedInput: setBloquedInput}); // Hooks que retorna las actividades y el loading
  const { loadingRooms, rooms, errorRooms } = useRooms({sportId : sport, activityId : activitie, loadingRoomWhenActivityNull: loadingRoomWhenNull, setLoadingRoomsNull : setLoadingRoomsNull, loaderRoom, setLoaderRoom}) // Hooks que retorna las salas y el loading
  const { loadingInstructors, instructors, errorInstructors } = useInstructors({sport: sport, activityId : activitie, pagination: false}); // Hooks que retorna los instructores y el loading

  const { loadingSchedulesByRelation, schedulesByRelation } = useSchedulesByRelation(props.match.params.scheduleId, 30, 0)

  useEffect(() => {
    if(isEdit) { // Cuando voy a editar relacion entro aqui y ejecuto lo sig ->
      let findScheduleByRelation
      if(!loadingSchedulesByRelation) {
        // console.log(schedulesByRelation)
        const getFindScheduleByRelation = async () => {
          findScheduleByRelation = schedulesByRelation.rows.find(schedule => schedule.id === parseInt(props.match.params.relationId))
        }
        getFindScheduleByRelation()
        
        if(findScheduleByRelation && findScheduleByRelation.maxCoupons) {
           setMaxCoupons(findScheduleByRelation.maxCoupons)
        }
       
        // console.log('findScheduleByRelation', findScheduleByRelation)
       
        setSport(findScheduleByRelation.sport.id) // Guardo el valor de sport

        // Si el sport es tenis o Squash entonces :
        if(findScheduleByRelation.sport.id === 1 || findScheduleByRelation.sport.id === 4) {
          setHasInstructor(false) // Bloqueo el input de instuctor
          setBloquedInput(true) // Bloqueo el input de actividad
          setActivitie(null) // El valor de actividad pasa a null para ejecutar rooms-by-association/Sport/Activity
        } else { 
          setBloquedInput(false) // desboqueo el input de instuctor
          setHasInstructor(true) // desboqueo el input de actividad
          setActivitie(findScheduleByRelation.activity.id) // le paso el paramtro actividad a rooms-by-association/Sport/Activity
        }
   
        if(findScheduleByRelation.activity && !findScheduleByRelation.activity.hasInstructor) {
          setHasInstructor(false) // Bloqueo el input de instuctor
        }

        // Recargo el input de room, ya comentado arriba en su declaracion
        if(findScheduleByRelation.room) { 
          setLoaderRoom(true)
        }
        
        // console.log('formRef.current', formRef.current)
        // Seteo los valores de input, es como initialValue
        formRef.current.setFieldsValue(
          {
            sport: findScheduleByRelation.sportId === null ? null : findScheduleByRelation.sport.id, 
            room: findScheduleByRelation.room === null ? null : findScheduleByRelation.room.id, 
            activity: findScheduleByRelation.activity === null ? null : findScheduleByRelation.activity.id, 
            instructor: findScheduleByRelation.instructor === null ? null : findScheduleByRelation.instructor.id,
            maxCoupons: findScheduleByRelation.maxCoupons,
            specificLocation: findScheduleByRelation.specificLocation
          }
        )
      }
    }
  }, [loadingSchedulesByRelation, props.match.params.relationId, isEdit, schedulesByRelation])

  const onFinish = async (e) => {
    setButtonLoading(true);
    if(isEdit) { // Editar relacion
      const url = `/update-schedule-association/${parseInt(props.match.params.relationId)}`
      const body = {
        sportId: e.sport,
        activityId: e.activity,
        instructorId: !hasInstructor ? null : e.instructor,
        roomId: sport === 3 ? 5 : e.room,
        maxCoupons: e.maxCoupons,
        specificLocation: e.specificLocation
      }
      try {
        // console.log('body del editar realcion', body)
        const response = await request('PATCH', url, body)
        // console.log('response', response)
        const data = await response.json()
        // console.log('data', data)
        if(response.status === 200) {
          setSuccessRelationSchedule(true);
          setButtonLoading(false);
          setSucessMessageRelationSchedule(data.message)
        } else {
          setButtonLoading(false);
          setErrorRelationSchedule(true)
          setErrorMessageRelationSchedule(data.message)
        }
      } catch (error) {
        // console.log(error)
        setButtonLoading(false);
        setErrorRelationSchedule(true)
        setErrorMessageRelationSchedule(messageCatchRequest)
      }
    } else {
      // console.log('evento', e)
      const url = `/associate-schedule`
      const body = {
        scheduleId: props.match.params.id,
        sportId: sport,
        activityId: activitie,
        instructorId: !hasInstructor ? null : instructor,
        roomId: sport === 3 ? 5 : room,
        maxCoupons: e.maxCoupons,
        specificLocation: e.specificLocation
      }
      try {
        // console.log(body)
        const response = await request('POST', url, body)
        // console.log('response', response)
        const data = await response.json()
        // console.log('data', data)
        if(response.status === 200) {
          setSuccessRelationSchedule(true);
          setButtonLoading(false);
          setSucessMessageRelationSchedule(data.message)
        }else {
          setButtonLoading(false);
          setErrorRelationSchedule(true)
          setErrorMessageRelationSchedule(data.message)
        }
      } catch (error) {
        // console.log(error)
        setButtonLoading(false);
        setErrorRelationSchedule(true)
        setErrorMessageRelationSchedule(messageCatchRequest)
      }
    }
  }


  // Actualizar el valor de sport
  const changeSport = (value) => {
      // console.log('value changeSport', value)
    // Esto es basicamente lo mismo que se hace en el effecto pero cuando cambia el input. 
    // Validamos que si es tenis o squash bloqueamos los input
    if(value === 1 || value === 4) {
      setBloquedInput(true)
      setHasInstructor(false)
       if(value === 1) {
        setActivitie(null)
        setLoaderRoom(true)
      } else {
        setActivitie(null)
        setLoaderRoom(true)
      }
    }else {
      setBloquedInput(false)
      setHasInstructor(true)
    }
    setSport(value)
    setRoom(null)
    setInstructor(null)
   
    formRef.current.setFieldsValue({
      sport: value, 
      activity: null, 
      room: null, 
      instructor: instructor 
    })
  }

    // Obtener Actividad actual para tomar el valor de hasInstructor para habilitar ese input o no
  const getCurrentActivity = async (value) => {
    // Actualizar el valor de la actividad
    // console.log('Activity =>', value)
    const findActivity = await activities.find(activity => activity.id === value)
    value !== null && setHasInstructor(findActivity.hasInstructor)

    if(sport === 1 || sport === 4) {
      setActivitie(null)
      setLoadingRoomsNull(true) 

      formRef.current.setFieldsValue({
        instructor: null 
      })

    } else {
      setHasInstructor(findActivity.hasInstructor)
      setActivitie(value)
      formRef.current.setFieldsValue({
        room: null,
        instructor: instructor
      }) 
    }
  }

  // Actualizar el valor de la sala
  const changeRoom = (value) => { 
    // console.log('value sala: ', value)
    setRoom(value) 
  }

  // Actualizar el valor del instructor
  const changeInstuctor = (value) => { 
    setInstructor(value) 
  }
  
    return ( 
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis datos: " breadcrumb={['Inicio > ', 'Mis datos']}/>
         <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title={isEdit ? "EDITAR RELACIÓN" : "RELACIONAR HORARIO"} subtitle={isEdit ? "A continuación puede editar la relación del horario." : "A continuación puede relacionar un horario."}/>
          <div className='main-form-relation-shedules'> 
          {/* {console.log('maxCoupons', maxCoupons)} */}
            {isEdit && loadingSchedulesByRelation ? <SpinnerLoader small/> :
             
              <Form const ref={formRef} onFinish={(e) => onFinish(e)} className="create-form_shedules" >
                {/* Deporte */}
                {errorSports 
                  ? <ErrorSelect title="Deportes: " message="Error al obtener los deportes"/>
                  : <SelectItemList  loading={loadingSport} title="Deporte" lists={sports} valueItem={sport} nameItem='sport' functionChange={changeSport}/>}
                {/* Actividad */}
                {errorActivities 
                  ? <ErrorSelect title="Actividades: " message="Error al obtener las actividades" /> 
                  : <SelectItemList  loading={loadingActivities} bloquedInput={bloquedInput} title="Actividad" lists={activities} valueItem={activitie} nameItem='activity' functionChange={getCurrentActivity}/>}
                {/* Sala */}
                {sport !== 3 && 
                  errorRooms
                  ? <ErrorSelect title="Salas: " message="Error al obtener las salas" />
                  : <SelectItemList  loading={loadingRooms} title="Sala" lists={rooms} valueItem={room} nameItem='room' functionChange={changeRoom} />}
                {/* maxCoupons */}
                {loadingActivities ? <SpinnerLoader small/> : 
                  (activitie === 7 || activitie === 8 ) && <div>
                  <p className='title-small-blue mb-5'>Cupo (opcional):</p>
                  <Form.Item name="maxCoupons">
                    <Input placeholder={`Cupo maximo:`} className="input small"/>
                  </Form.Item>
                </div>}
                {/* Space the clases de salon */}
                {loadingActivities ? <SpinnerLoader small/> : 
                  sport === 3 && <div>
                  <p className='title-small-blue mb-5'>Espacio: </p>
                  <Form.Item name="specificLocation">
                    <Input placeholder={`Espacio para la actividad:`} className="input small"/>
                  </Form.Item>
                </div>}
                {/* Instuctor */}
                {
                  errorInstructors
                  ? <ErrorSelect title="Instuctores: " message="Error al obtener los instructores"/> 
                  : <SelectItemList loading={loadingInstructors} title="Instuctor" hasInstructor={hasInstructor} lists={instructors} valueItem={instructor}  nameItem='instructor' functionChange={changeInstuctor} />}
                
                <Button htmlType="submit" className="button purple button-schedules" loading={buttonLoading}>
                  {isEdit ? 'Editar relación ': 'Crear relación'}
                </Button>
              </Form> 
            }
            <ReserveInnerFooter back={() => props.history.push('/horarios')}/>
          </div>
          {successRelationSchedule && <ModalBox success createRelation setShowModal={setSuccessRelationSchedule} content={successMessageRelationSchedule}/>}
          {errorRelationSchedule && <ModalBox error setShowModal={setErrorRelationSchedule} content={errorMessageRelationSchedule}/>}
        </div>
      </ReserveWrapper>
    )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(FormRelationSchedule);
