import request from "./request"

// para obtener las reservaciones
export const getReservations = async ({ status = 'Activa', sportId, activityId, roomId, limit = 10, page = 0}) => {
  const url = `/reservations/${status}/${sportId}/${activityId}/${roomId}/${limit}/${limit * page}`
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      console.log('las reservaciones: ', data.reservations)
      return data.reservations
    } else {
      throw Error('Error al obtener las reservaciones')
    }
  } catch (error) {
    throw Error('Error al obtener las reservaciones')
  }
}
