import { useState, useEffect } from 'react'
import { getDays } from '../services/getDays'

export const useDays = (sport) => {
  const [loadingDays, setLoadingDays] = useState(false)
  const [days, setDays] = useState([]) 
  
  useEffect(() => {
    setLoadingDays(true)
    getDays().then(data => {
      setDays(data) 
      setLoadingDays(false) 
    })
  }, [sport])

  return { loadingDays, days }
}
