import { useState, useEffect } from 'react'
import { getSports } from '../services/getSports'

export const useSports = (sport) => {
  const [loadingSport, setLoadingSport] = useState(false)
  const [loadingSportInTable, setLoadingSportInTable] = useState(true)
  const [sports, setSports ] = useState([]) 
  const [errorSports, setErrorSports] = useState(null) 
  
  useEffect(() => {
    setLoadingSport(true)
    getSports().then(data => {
      setSports(data) 
      setLoadingSport(false) 
    }).catch(err => {
      setErrorSports(err)
    })
  }, [sport, loadingSportInTable])

  return { loadingSport, sports, setLoadingSportInTable, loadingSportInTable, errorSports }
}
