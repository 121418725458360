import request from "./request"

export const getHistoryReservation = async ({ uniqueId, startDate, endDate = null, sportId = null, activityId = null, roomId = null, status, limit = 5, page = 0 }) => {
  try {
    const response = await request('GET', `/reservations-by-partner/${uniqueId}/${startDate}/${endDate}/${sportId}/${activityId}/${roomId}/${status}/${limit}/${page * limit}`) 
    const data = await response.json()  
    // console.log('reservations-by-partner', data)
    if(response.status === 200) {
      return data
    } else {
      throw Error('Error al obtener el estado history reservations-by-partner')
    }
  } catch (error) {
    // console.log('error', error)
    throw Error('Error al obtener el estado history reservations-by-partner')
  }
}
