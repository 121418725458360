import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './CreateAdmin.scss';
import Modal from '../../Molecules/Modal/Modal'
import { CameraOutlined } from '@ant-design/icons';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import imageCompression from 'browser-image-compression';
import request from '../../../services/request';
// import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import store from '../../../redux/store';
import { saveUser } from '../../../redux/actionsCreators';
import { connect } from 'react-redux';
import { useCurrentAdmin } from '../../../customHooks/useCurrentAdmin';
import { messageCatchRequest } from '../../../services/helpers';

const CreateAdmin = (props) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    // const [buttonLoadingDelete, setButtonLoadingDelete] = useState(false);
    // const [showModalDelete, setShowModalDelete] = useState(false);
    const [createSuccess, setCreateSucess] = useState(false);
    const [editSuccess, setEditSucess] = useState(false);
    // const [deleteSuccess, setDeleteSucess] = useState(false);
    // const [deleteError, setDeleteError] = useState(false);
    const [createError, setCreateError] = useState(false);
    const [reload, setReload] = useState(false);
    const [profileImg, setProfileImg] = useState(null)
    const [message, setMessage] = useState('')
    let isEdit = props.history.location.pathname.includes('/editar');
    const { currentAdmin, loadingAdmin } = useCurrentAdmin({idAdmin: props.match.params.id})

    useEffect(() => {
      // console.log('profileImg', profileImg)
      if(!isEdit) {
        // console.log('Creando admin')
        if(!props.userInfo.super) return props.history.push('/datos')
      }
    }, [reload, isEdit, profileImg, props.userInfo.super, props.history])

    const onFinish = async (e) => { 
      setButtonLoading(true);
      if(isEdit) {
        const url = `/update-personal-data`
        const body = {
          email: e.email,
          firstName: e.firstName,
          lastName: e.lastName,
          profilePicture: profileImg,
          legalId: e.legalId,
        }
        try {
          const response = await request('PATCH', url, body)
          const data = await response.json()
          // console.log('body edit', body)
          // console.log('data', data)
          if(response.status === 200) {
            setEditSucess(true);
            setButtonLoading(false);
            store.dispatch(saveUser(data.privateUser)); // actualizo la foto en redux
            setMessage(data.message)
          } else {
            setCreateError(true);
            setButtonLoading(false);
            setEditSucess(false)
            setMessage(data.message)
          }
        }
        catch (error) {
          console.log(error)
          setCreateError(true);
          setMessage(messageCatchRequest)
          setButtonLoading(false);
          setEditSucess(false)
        }
      } else {
        const url = `/create-credential`
        const body = {
          email: e.email,
          firstName: e.firstName,
          lastName: e.lastName,
          profilePicture: profileImg,
          privateUserRoleId: e.privateUserRoleId,
          legalId: e.legalId,
        }
        try {
          const response = await request('POST', url, body)
          const data = await response.json()
          // const data = await response.json()
          // console.log('body', body)
          // console.log('response', response)
          if(response.status === 200) {
            setCreateSucess(true);
            setButtonLoading(false);
            setReload(true);
            setMessage(data.message)
          }else {
            setMessage(data.message)
            setCreateError(true);
            setButtonLoading(false);
          }
        } catch (error) {
          setCreateError(true);
          setMessage(messageCatchRequest)
          setButtonLoading(false);
          setEditSucess(false)
          console.log(error)
        }
      }
    }

     // Cargando la  foto del usuari/instructor
     const changeUserPicture = async (e) => {  
      const imageFile = e.target.files[0];  // Foto que sube el usuario 
      const options = {   // Opciones para comprimir la foto 
        maxSizeMB: 1,
        maxWidthOrHeight: 1080,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        if(compressedFile.type.includes('image/')) {   // Previsualizando el archivo
          const reader = new FileReader();
          reader.onload = () =>{
            if(reader.readyState === 2){
              setProfileImg(reader.result) // Convierto la foto a base64
              console.log('reader 2', reader) 
            }
          }
          reader.readAsDataURL(compressedFile)
        }
      } catch (error) {
        console.log('Error al comprimir la foto', error);
        throw Error('Error al comprimir la foto')
      }
    }


  

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Administradores: " breadcrumb={['Inicio > ',  `${isEdit ? 'Editar': 'Crear'} administrador `]}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title={`${isEdit ? 'EDITAR': 'CREACIÓN  DE'} ADMINISTRADOR`} subtitle={`Llene el siguiente formulario para ${isEdit ? 'editar' : 'crear un nuevo usuario'} administrador`}/>
            <div className="main-profile-partner">
              <div className="main-profile-partner--parte-1">
                {
                  profileImg !== null ?  /* Imagen previsualisada */
                  <div className="main-profile-partner--img-container">
                    {!buttonLoading && <label htmlFor="input-file-user"><CameraOutlined className="main-profile-partner--icon-camera"/></label>}
                    <img src={profileImg} alt="Instructor" />
                  </div>
                  :
                  <div className="main-profile-partner--img-container">
                    {!isEdit ? 
                      <img className="create-picture--instuctor" src={process.env.REACT_APP_ICON_CREATE_USER_PHOTO} alt="Administrador" />
                      : 
                      loadingAdmin ? <SpinnerLoader small/> :
                      <img src={currentAdmin.pictureUrl ? currentAdmin.pictureUrl :  process.env.REACT_APP_ICON_CREATE_USER_PHOTO} alt="Administrador" />
                    }
                  </div>
                }
                <input 
                  type="file" 
                  onChange={e => changeUserPicture(e)}
                  className="input-file"
                  accept=".png, .jpg, .jpeg, image/jpeg, image/jpg, image/png, image/x-png"
                  id="input-file-user"
                />
                {/* Muestro el boton de cargar foto si aun no la he puesto */}
                { 
                  profileImg === null && /* Si aun no coloco foto pongo Cargar foto */
                  <label htmlFor="input-file-user" className="button turquoise outline mt-15 label-file-user">Cargar foto</label>
                }
              </div>

              <div className="main-profile-instuctor--parte-2 mb-25">
                {
                  isEdit && loadingAdmin ? <SpinnerLoader small/>
                  : 
                <Form onFinish={(e) => onFinish(e)}  className="create-admin--form"
                   initialValues={{
                      firstName: isEdit ? currentAdmin.privatePerson.firstName : '',
                      lastName: isEdit ? currentAdmin.privatePerson.lastName : '',
                      legalId: isEdit ? currentAdmin.privatePerson.legalId : '',
                      email: isEdit ? currentAdmin.email : ''
                  }}
                 >
                    <div>
                      <p className='title-small-blue mb-5'>Nombre: </p>
                      <Form.Item name="firstName" placeholder="Ingrese su nombre" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Input className="input" />
                      </Form.Item>
                    </div>

                    <div>
                      <p className='title-small-blue mb-5'>Apellido: </p>
                      <Form.Item name="lastName" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Input className="input" />
                      </Form.Item>
                    </div>

                    <div>
                      <p className='title-small-blue mb-5'>C.I.: </p>
                      <Form.Item name="legalId" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Input className="input" />
                      </Form.Item>
                    </div>

                   {!isEdit && <div>
                      <p className='title-small-blue mb-5'>Rol.: </p>
                      <Form.Item className="input-filter block" name="privateUserRoleId" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Select>
                          <Select.Option key={1} value={1}>Administrador</Select.Option>
                          <Select.Option key={2} value={2}>Colaborador</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>}

                    <div>
                      <p className='title-small-blue mb-5'>Correo: </p>
                      <Form.Item name="email" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Input className="input" disabled={isEdit ? true : false}/>
                      </Form.Item>
                    </div>

                  <div className="create-admin--btn mt-20">
                  {!isEdit && <Button htmlType="submit" className="button purple" loading={buttonLoading}>
                      Crear administrador
                    </Button>}
                  </div>
                  {isEdit && 
                    <>
                      <Button htmlType="submit" className="button purple mt-15" loading={buttonLoading}>
                        Guardar Datos
                      </Button>
                    </>
                  }
                </Form>
                }
              </div>
            </div> {/*Fin del padre*/}
        </div>
        {createSuccess && <Modal success createSucess setShowModal={setCreateSucess} content={message} />}
        {editSuccess && <Modal success editInfoAdmin setShowModal={setEditSucess} content={message} />}
        {createError && <Modal error setShowModal={setCreateError} content={message} />}
      </ReserveWrapper>
    )
}

const mapStateToProps = state => ({
  userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(CreateAdmin)
