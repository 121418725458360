import request from "./request"

export const getNotifications = async ({limit = 20, page = 0}) => {
  try {
    const response = await request('GET', `/notifications/${limit}/${page * limit}`)
    const data = await response.json()
    if(response.status === 200) {
     return data.notifications
    } else {
      throw new Error('Error al obtener las notificiones')
    }
  } catch (error) {
    console.log('error', error)
    throw new Error('Error al obtener las notificiones')
  }
}
