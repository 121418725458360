import request from "./request"

export const getStatusWeekedHistory = async ({ uniqueId, limit = 5, page = 0 }) => {
  try {
    const response = await request('GET', `/partner-history/${uniqueId}/${limit}/${page * limit}`) 
    const data = await response.json()  
    // console.log('data partner-history', data)
    if(response.status === 200) {
      return data
    } else if(response.status === 404) {
      // console.log('When response is 404', data)
      return data
    } else {
      throw Error('Error al obtener el estado history de la semana')
    }
  } catch (error) {
    // console.log('error', error)
    throw Error('Error al obtener el estado history de la semana')
  }
}
