import request from "./request"

// Para obtener administradores
export const getAdministrators = async ({ limit = 5, page = 0} = {}) => {
  const url = `/administrators/${limit}/${page * limit}`
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      return data.users
    }  else {
      throw Error('No se pudieron obtener los administradores')
    }
  } catch (error) {
    throw Error('No se pudieron obtener los administradores')
  }
}
