
import React, {useState, useEffect} from 'react';
import { Button, Form, Input, Table } from 'antd';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './ConsultaSocio.scss';
import request from '../../../services/request';
import { Link } from 'react-router-dom';

const columns = [
  {
    title: "ID unico",
    dataIndex: "uniqueId",
    key: "uniqueId",
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Número Acción",
    dataIndex: "actionNumber",
    key: "actionNumber",
  },
  {
    title: "Detalle",
    dataIndex: "details",
    key: "details",
    fixed: "right",
    width: 125,
  },
];

const ConsultaSocio = (props) => {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [listSocios, setListSocios] = useState(null)
    const [accionNumber, setAccionNumber] = useState(null)
  
    const back = () => props.history.goBack() // Ir a atras

    useEffect(() => {
      if(localStorage.getItem('actionNumber')) {
        searchSocios({actionNumber: localStorage.getItem('actionNumber')})
      }
    }, [])

    const searchSocios = async ({actionNumber}) => {
      setAccionNumber(actionNumber)
      setButtonLoading(true)
      localStorage.setItem('actionNumber', actionNumber)
      try {
        const response = await request('GET', `/partners/${actionNumber}/5/0`)
        if(response.status === 200) {
          setButtonLoading(false)
          const data = await response.json()
          // console.log(data)
          setListSocios(
            data.partners.rows.map((partner) => ({
              uniqueId: partner.uniqueId,
              key: partner.id,
              name: partner.firstName,
              actionNumber: partner.actionNumber,
              details: <Link to={`/consulta/${partner.actionNumber}/${partner.uniqueId}`}>Ver detalle</Link>
            }))
          )
        } else if(response.status === 404) {
          setButtonLoading(false)
        } else {
          setButtonLoading(false)
        }
      } catch (error) {
        // console.log('error', error)
      }
    }

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title='Consulta:' breadcrumb={['Inicio > ', 'Consulta']}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title='BÚSQUEDA DE SOCIO' subtitle='A continuación podrá consultar la información de un socio'/>
          {
              localStorage.getItem('actionNumber') &&
              <div>
                <p>Ultima búsqueda: acción: <span className="title-small-blue">{localStorage.getItem('actionNumber')}</span> </p>
              </div>
            }
          <div className='main-consulta-socio-form'>
            <Form onFinish={(e) => searchSocios(e)} className="create-form_shedules" initialValues={{
              actionNumber: localStorage.getItem('actionNumber') || ''
            }}>
              <Form.Item name="actionNumber" rules={[{required: true, message: 'Campo obligatorio' }]}>
                <Input placeholder={`Número de acción: `} className="input"/>
              </Form.Item>
              <Button htmlType="submit" className="button purple" loading={buttonLoading}>
                Buscar
              </Button>
            </Form>
          </div>
          <div className='main-consulta-socio-list mt-30'>
          {
            listSocios === null ? <p className="title-upper-red no-space center-text">NO HAS REALIZADO NINGUNA BÚSQUEDA</p>  : listSocios.length < 1 ? <p className="title-upper-red no-space center-text">NO SE ENCONTRARON RESULTADOS A LA ACCIÓN N° {accionNumber}</p> 
            : 
            <Table
              loading={buttonLoading}
              scroll={{ x: 620 }}
              rowClassName={(a, b) => b % 2 === 0 && "bg-light"}
              className="reserve-active-table"
              columns={columns}
              dataSource={listSocios}
              pagination={false}
            />
            }
          </div>
          <ReserveInnerFooter back={back}/>
        </div>
      </ReserveWrapper>
    )
}

export default ConsultaSocio;


// Endpoint get /partner/:action/:uniqueId
