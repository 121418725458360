import React, { useState, useEffect, useRef } from "react";
import { Table, Select, Form } from "antd";
import { Link } from "react-router-dom";
import ReserveWrapper from "../../Organisms/ReserveWrapper/ReserveWrapper";
import ReserveMainTitle from "../../Atoms/ReserveMainTitle/ReserveMainTitle";
import ReserveInnerTitle from "../../Atoms/ReserveInnerTitle/ReserveInnerTitle";
import "./SalasList.scss";
import { getActivityName, getSportId } from "../../../services/helpers";
import request from "../../../services/request";

function SalasList(props) {
  const [loading, setLoading] = useState(true);
   // eslint-disable-next-line no-unused-vars
  const [especialidad, setEspecialidad] = useState(null);
  const [especialidadOptions, setEspecialidadOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [mounted, setMounted] = useState(false);
  const [roomList, setRoomList] = useState([]);

  const formRef = useRef();

    const activity = props.location.pathname.split('/salas')[1]
    const activityType =
                activity === '/squash' ? {name: 'Squash', shortname: 'squash', id: getSportId('squash')}
                :
                activity === '/tenis' ? {name: 'Tenis', shortname: 'tenis', id: getSportId('tenis')} 
                : 
                activity === '/otros' ? {name: 'Clases de salón', shortname: 'others', id: getSportId('otros')} 
                :
                activity === '/indoor-cycling' && {name: 'Indoor Cycling', shortname: 'cycling', id: getSportId('indoor-cycling')};

  // const back = () => {
  //     props.history.push('/salas')
  // }

  const columns = [
    {
      title: "Sala",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actividad",
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: "Detalles",
      dataIndex: "details",
      key: "details",
      fixed: "right",
      width: 85,
    },
  ];

  const getTableData = (especialidad) => {
    setLoading(true);
    const roomsRequest = async () => {
      const response = await request(
        "GET",
        `/rooms-by-association/${activityType.id}/${especialidad}/0`
      );
      if (response.ok) {
        const data = await response.json();
        // console.log("data.rooms: ", data.rooms);
        // console.log('data:::', data)
        setRoomList(
          data.rooms.map((room) => ({
            name: room.room.name,
            key: room.roomId,
            status: room.room.available ? "Activa" : "Inactiva",
            activity: getActivityName({activityId: especialidad}) || 'N/A',
            details: <Link to={`/salas/detalle/${room.roomId}`}>Ver detalle</Link>,
          }))
        );
        setLoading(false);
        setMounted(true);
      }
    };
    roomsRequest();
  };

  useEffect(() => {
    const activityRequest = async () => {
      const response = await request(
        "GET",
        `/activities-by-sport/${activityType.id}/1`
      );
      if (response.ok) {
        const data = await response.json();
        setEspecialidadOptions(
          data.activities.map((act) => (
            <Select.Option key={act.id} value={act.id}>
              {act.name}
            </Select.Option>
          ))
        );
      }
    };
    const activity = props.location.pathname.split("/salas")[1];
    if (activity === "/indoor-cycling" || activity === "/otros") {
      activityRequest();
    } else {
      setEspecialidadOptions([]);
    }
    setEspecialidad(null);
    formRef.current.setFieldsValue({ especialidad: null });
    getTableData(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  return (
    <ReserveWrapper centerX>
      <ReserveMainTitle
        title="Salas"
        breadcrumb={["Inicio > ", `Gestion de salas`]}
      />
      {/* {console.log('activityType', activityType)} */}
      <div className="box mb-20">
        <ReserveInnerTitle
          activity={activityType.shortname}
          title={`SALAS DE ${activityType.name}`}
          subtitle={`A continuación un un listado de ${activityType.id === 1 || activityType.id === 4 ? 'canchas' : 'salas'} de ${activityType.name}`}
        />
        <Form ref={formRef} initialValues={{ activity: "/salas/tenis" }}>
          <Form.Item
            label="Deporte"
            name="activity"
            className="input-filter mr-20"
          >
            <Select onChange={(value) => props.history.push(value)}>
              <Select.Option value="/salas/tenis">Tenis</Select.Option>
              <Select.Option value="/salas/indoor-cycling">
                Indoor Cycling
              </Select.Option>
              <Select.Option value="/salas/otros">Clases de salón</Select.Option>
              <Select.Option value="/salas/squash">Squash</Select.Option>
            </Select>
          </Form.Item>
          {especialidadOptions.length > 0 && (
            <Form.Item
              label="Actividad"
              name="especialidad"
              className="input-filter"
            >
              <Select
                placeholder="Seleccionar"
                onChange={(value) => {
                  setEspecialidad(value);
                  getTableData(value);
                }}
              >
                {especialidadOptions}
              </Select>
            </Form.Item>
          )}
        </Form>
        <Table
          loading={loading}
          scroll={{ x: 620 }}
          rowClassName={(a, b) => b % 2 === 0 && "bg-light"}
          className="reserve-active-table"
          columns={columns}
          dataSource={roomList}
          pagination={false}
        />
      </div>
    </ReserveWrapper>
  );
}

export default SalasList;
