import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCheckToken } from '../../customHooks/useCheckToken';
import SpinnerLoader from '../Atoms/SpinnerLoader/SpinnerLoader';

const Public = ({component: Component, ...rest}) => {
  const { loadingUser, isLogged } = useCheckToken()

  return (
    loadingUser ? <SpinnerLoader /> :
    isLogged ? <Redirect to="/datos" /> : <Route {...rest} component={Component} />
  )
}

export default Public;
