import React, { useState, useEffect } from "react";
import { Table, Form, Select } from "antd";
import { Link } from "react-router-dom";
import ReserveWrapper from "../../Organisms/ReserveWrapper/ReserveWrapper";
import ReserveMainTitle from "../../Atoms/ReserveMainTitle/ReserveMainTitle";
import ReserveInnerTitle from "../../Atoms/ReserveInnerTitle/ReserveInnerTitle";
import { useActivities } from "../../../customHooks/useActivities";

function ActivitiesList() {
  const [activitiesList, setActivitiesList] = useState([])
  const [sport, setSport] = useState(1)
  const { loadingActivities, activities } = useActivities({sport})
  // console.log('activities', activities)

  const columns = [
    {
      title: "Actividad",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Detella",
      dataIndex: "details",
      key: "details",
      fixed: "right",
      width: 185,
    },
  ];

  useEffect(() => {
    const getTableData = () => {
      setActivitiesList(
        activities.map((activity) => ({
          name: activity.name,
          key: activity.id,
          status: activity.available ? "Activa" : "Inactiva",
          details: <Link to={`/actividades/${activity.id}`}>Ver detalle</Link>,
        }))
      );
    };
    getTableData();
  }, [loadingActivities, activities]);

  return (
    <>
    <ReserveWrapper centerX>
      <ReserveMainTitle
        title="Salas"
        breadcrumb={["Inicio > ", `Gestion de salas`]}
      />
      <div className="box mb-20">
        <ReserveInnerTitle
          activity={null}
          title={'ACTIVIDADES'}
          subtitle={`A continuación un listado de las actividades`}
        />
          <Form initialValues={{ activity: 1 }}>
            <Form.Item
              label="Deporte"
              name="activity"
              className="input-filter mr-20"
            >
            <Select onChange={(value) => setSport(value)}>
              <Select.Option value={1}>Tenis</Select.Option>
              <Select.Option value={2}> Indoor Cycling</Select.Option>
              <Select.Option value={3}>Clases de salón</Select.Option>
              <Select.Option value={4}>Squash</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <Table
          loading={loadingActivities}
          scroll={{ x: 620 }}
          rowClassName={(a, b) => b % 2 === 0 && "bg-light"}
          className="reserve-active-table"
          columns={columns}
          dataSource={activitiesList}
          pagination={false}/>
      </div>
    </ReserveWrapper>
    </>
  );
}

export default ActivitiesList;
