import React from 'react'
import './NotFound.scss'
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import pageNotFound from '../../../assets/VAA-404.svg'

const NotFound = () => {
  return (
    <ReserveWrapper centerY centerX>
      <div className="box container-404">
        <img src={pageNotFound} alt="404" className="container-404--img"/>
      </div>
    </ReserveWrapper>
  )
}

export default NotFound;
