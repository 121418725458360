import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { checkToken } from '../services/checkToken';
import { saveUser } from '../redux/actionsCreators';

export const useCheckToken = () => {
  const [isLogged, setIsLogged] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const dispatch = useDispatch() 
  
  useEffect(() => {
    setLoadingUser(true)
    checkToken()
    .then(data => {
      setIsLogged(true)
      localStorage.setItem('token', data.token);
      dispatch(saveUser(data.data))
      setLoadingUser(false)
    })
    .catch(() => {
      setIsLogged(false)
      setLoadingUser(false)
    }) 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loadingUser, isLogged }
}
