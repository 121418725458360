import React from 'react'
import './SpinnerLoader.scss'
import  {Spin} from 'antd';

const SpinnerLoader = ({small, small_x2}) => {
  return (
    <div className={`spinner_loader ${small && 'small'} ${small_x2 && 'small_2'}`}>
      <Spin size={`${small || small_x2 ? 'middle' : 'large'}`}  />
    </div>
  )
}

export default SpinnerLoader
