import React, {useState, useEffect} from 'react';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import Modal from '../../Molecules/Modal/Modal';
import ReserveQualifyModal from '../../Molecules/ReserveQualifyModal/ReserveQualifyModal';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import request from '../../../services/request';
import './DetailReserve.scss';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import { messageCatchRequest } from '../../../services/helpers';

const DetailReserve = (props) => { 

  // modal para calificar
  const [showModalQualify, setShowModalQualify] = useState(false)
   // modal para Eliminar
  const [showModalCancel, setShowModalCancel] = useState(false)
  // Cuando califica a todas las personas con exito
  const [successQualify, setSuccessQualify] = useState(false)
  // Cuando califica a todas las personas y me da algun error
  const [errorQualify, setErrorQualify] = useState(false)
   // Cuando cancela la reserva con exito 
  const [cancelSucess, setCancelSucess] = useState(false)
  //  // Cuando cancela la reserva  y da errir
  const [errorCancel, setErrorCancel] = useState(false)

  // other
  const [details, setDetails] = useState(null);
  const [responsable, setResponsable] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sportIcon, setSportIcon] = useState('');
  const [messageQualifyModal, setMessageQualifyModal] = useState('');
  const [messageCancel, setMessageCancel] = useState('')
  const [reserve404, setReserve404] = useState(false)
  const [reserveError, setReserveError] = useState(false)
  const [loadingReserve, setLoadingReserve] = useState(false)

  // Ir atras
  const back = () => {
    props.history.goBack()
  }

  // Función para cancelar
  const cancelReserve = async () => {
    setCancelLoading(true);
    try {
      const response = await request('PATCH', `/cancel-reservation/${props.match.params.id}`);
      const data = await response.json()
      if (response.status === 200) {
        setMessageCancel(data.message)
        setShowModalCancel(false)
        setCancelSucess(true)
      } else {
        setMessageCancel(data.message)
        setCancelLoading(false)
        setErrorCancel(true)
      }
    } catch (error) {
      setMessageCancel(messageCatchRequest)
      setCancelLoading(false)
      setErrorCancel(true)
    }
  }

  // Funcion para calificar a cada socio, si asistio o no.
  const qualifyReserve = async attendedUsers => {
    setButtonLoading(true)
    // console.log('EStas calificando')
    // console.log('attendedUsers', attendedUsers)
    const reservations = [] 
    for (let property in attendedUsers) {
      // console.log('attendedUsers property', property)
      reservations.push({id: parseInt(property), attended: attendedUsers[property][0], responsable: attendedUsers[property][1] })
    }
    // console.log('reservations al calificar ',reservations)
    try {
      const response = await request('POST', `/handle-reservation-items-status`, {reservations: reservations} );
      const data = await response.json()
      // console.log('response: ', response)
  
      if (response.status === 200) {
        setButtonLoading(false)
        setSuccessQualify(true)
        setErrorQualify(false)
        setMessageQualifyModal(data.message)
      } 
      else {
        setErrorQualify(true)
        setButtonLoading(false)
        setSuccessQualify(false)
        setMessageQualifyModal(data.message)
      }
    } catch (error) {
      setErrorQualify(true)
      setButtonLoading(false)
      setSuccessQualify(false)
      setMessageQualifyModal(messageCatchRequest)
    }
  }

  useEffect(() => {
    setLoadingReserve(true)
    const reservationRequest = async () => {
      const response = await request('GET', `/reservation/${props.match.params.id}`);
      if (response.status === 200) {
          const data = await response.json();
          if (data.reservation) {
            setResponsable(data.reservation.reservationItem.filter(rsv => rsv.responsable))
            setDetails(data.reservation)
            if(data.reservation.sport.name === 'Tenis') {
              setSportIcon('tenis')
            } else if(data.reservation.sport.name === 'Indoor Cycling') {
              setSportIcon('cycling')
            }else if(data.reservation.sport.name === 'Squash') {
              setSportIcon('squash')
            } else {
              setSportIcon('others')
            }
          }
        setLoadingReserve(false)
      } else if(response.status === 404) {
        setReserve404(true)
        setReserveError(false)
        setLoadingReserve(false)
      }else {
        setReserve404(false)
        setReserveError(true)
        setLoadingReserve(false)
      }
    }
    reservationRequest()
  }, [props.match.params.id])

  if(reserve404) {
    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title='Reservas' breadcrumb={['Inicio > ', 'Detalle > ', props.match.params.id]}/>
        <div className={`${'box'}`}>
          <h6 className="title-upper-red mb-5 center-text">RESERVA NO ENCONTRADA</h6>
        </div>
      </ReserveWrapper>
    )
  }
  if(reserveError) {
    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title='Reservas' breadcrumb={['Inicio > ', 'Detalle > ', props.match.params.id]}/>
        <div className={`${'box'}`}>
          <h6 className="title-upper-red mb-5 center-text">HA OCURRIDO UN ERROR, INTENTELO MÁS TARDE.</h6>
        </div>
      </ReserveWrapper>
    )
  }
  return (
    <ReserveWrapper centerX>
      <ReserveMainTitle title='Reservas' breadcrumb={['Inicio > ', 'Detalle > ', props.match.params.id]}/>
      <div className="box mb-20">
      
      {loadingReserve ? <SpinnerLoader small />
        :
        details && responsable  && responsable.length > 0 ? 
        <div>
          <ReserveInnerTitle activity={sportIcon} title={`RESERVA DE ${details.sport.name}`} subtitle={`N° de reserva principal ${props.match.params.id}`}/>
          <div className="main-detail-reserve">
            <div className="detail-reserve-row-1">
              <h6 className="title-upper-red mb-5">INFORMACIÓN DE RESERVACIÓN:</h6>
              <p className="no-space"><span className="title-small-blue">Responsable de la reserva: </span> <span className="title-small-blue">({responsable[0].actionNumber}) </span> {responsable[0].firstName} {responsable[0].lastName} N° de reserva {responsable[0].id} {details.status === process.env.REACT_APP_USED_RESERVATION && (<span className="title-small-blue">{`- ${details.reservationItem.find(item => item.responsable).status}`}</span>)}</p>
              {/* <p className="no-space"><span className="title-small-blue">Fecha de la reserva: </span>{new Date(details.reservationDate).toLocaleDateString()}</p> */}
              <p className="no-space"><span className="title-small-blue">Fecha de la reserva: </span>{details.reservationDate}</p>
              <p className="no-space"><span className="title-small-blue">Hora de la reserva: </span> {details.schedule.openingTime} - {details.schedule.closingTime} {details.schedule.scheduleBlock}</p>
            </div>
            <div className="detail-reserve-row-2 mt-20">
              <div className="detail-reserve-row-2--datails" >
                <h6 className="title-upper-red">DETALLES:</h6>
                {details.status === process.env.REACT_APP_CANCELLED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> {`${details.status} por ${details.cancelledByPartner ? `el socio ${responsable[0].firstName} ${responsable[0].lastName}` : `el Administrador ${details.privatePersonName && details.privatePersonName}`}`}</p> }
                {details.status === process.env.REACT_APP_CANCELLED_RESERVATION && details.cancellationReason && <p className="no-space"><span className="title-small-blue">Razón de la cancelación: </span> {details.cancellationReason}</p> }
                {details.status === process.env.REACT_APP_USED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> Calificada por {details.privatePersonName}</p> }
                <p className="no-space"><span className="title-small-blue">Actividad:</span> {details.sport ? details.sport.name : 'N/A'} ({details.activity ? details.activity.name : 'N/A'})</p>
                <p className="no-space"><span className="title-small-blue">Sala:</span> {details.room ? details.room.name : 'N/A'}</p>
                {details.specificLocation && <p className="no-space"><span className="title-small-blue">Espacio:</span> {details.specificLocation}</p>}
                <p className="no-space"><span className="title-small-blue">No. de participantes:</span> {details.reservationItem.length}</p>
                {responsable[0].reservationPlaceId && <p className="no-space"><span className="title-small-blue">Bicicleta seleccionada:</span> {responsable[0].reservationPlaceId} </p>}
              </div>
              {details.reservationItem.length > 1 && 
                <div className="detail-reserve-row-2--participants">
                  <h6 className="title-upper-red">INVITADOS:</h6>
                  {
                    details.reservationItem.filter(item => !item.responsable).map((socio, index) => 
                      <p key={index} className="no-space"><span className="title-small-blue"> Invitado {index + 1}:</span> <span className="title-small-blue">({socio.actionNumber}) </span> {socio.firstName} {socio.lastName},  N° de reserva {socio.id} {details.status === process.env.REACT_APP_USED_RESERVATION && (<span className="title-small-blue">{`- ${socio.status}`}</span>)}</p>
                    )
                  }
                </div>
              }
            </div>
          </div>
          <ReserveInnerFooter buttonLoading={buttonLoading} back={back} status={details.status} alreadyPassed={details.alreadyPassed} detailReserve setShowModalCancel={setShowModalCancel} setShowModalQualify={setShowModalQualify}/>
        </div> : <SpinnerLoader small /> 
      }
      {showModalQualify && 
        <ReserveQualifyModal 
          listSocio={details.reservationItem} 
          title='CORFIRMACIÓN DE ASISTENCIA' 
          message='Esta por calificar esta reservación, por favor indica el estatus de asistencia de cada socio' 
          handleCancel={() => setShowModalQualify(false)} 
          qualifyReserve={qualifyReserve} 
          setShowModalQualify={setShowModalQualify} 
          buttonLoading={buttonLoading}
        />
      }
      {showModalCancel && 
        <ReserveSuccessModal 
          activity={details.sport.id === 1 ? 'tenis' : details.sport.id === 2 ? 'cycling' : details.sport.id === 3 ? 'others' : 'squash'} 
          title='CANCELACIÓN DE RESERVA' 
          message={`Usted está por cancelar la reserva ${props.match.params.id}. Por favor confirme esta acción.`} 
          handleCancel={() => setShowModalCancel(false)} 
          cancelReserve={cancelReserve} 
          setShowModalCancel={setShowModalCancel}
          loading={cancelLoading}
        />
      }
      {successQualify && <Modal Qualify sportQualify={details.sport.id} success setShowModal={setSuccessQualify} content={messageQualifyModal} />}
      {errorQualify && <Modal error setShowModal={setErrorQualify} content={messageQualifyModal} />}
      {errorCancel && <Modal error setShowModal={setErrorCancel} content={messageCancel} />}
      {cancelSucess && <Modal success cancelReserveSucess setShowModal={setCancelSucess} content={messageCancel} />}
      </div>
    </ReserveWrapper>
  )
}
export default DetailReserve;
