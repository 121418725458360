import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ReserveWrapper from "../../Organisms/ReserveWrapper/ReserveWrapper";
import ReserveMainTitle from "../../Atoms/ReserveMainTitle/ReserveMainTitle";
import ReserveInnerTitle from "../../Atoms/ReserveInnerTitle/ReserveInnerTitle";
import request from "../../../services/request";
import { useSports } from "../../../customHooks/useSports";
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import Modal from '../../Molecules/Modal/Modal'
import { messageCatchRequest } from '../../../services/helpers';

function SportList() {
  const [sportsList, setSportsList] = useState([]);
  const [sportStatusAction, setSportStatusAction] = useState(null);
  const [sportId, setSportId] = useState(null);
  const [sportName, setSportName] = useState(null);
  const [showHandleSport, setShowHandleSport] = useState(false);
  const [buttonLoadingHandleSport, setButtonLoadingHandleSport] = useState(false);
  // Modales 
  const [message, setMessage] = useState('');
  const [handleSuccess, setHandleSuccess] = useState(false);
  const [handleError, setHandleError] = useState(false);

  const { loadingSport, loadingSportInTable, sports, setLoadingSportInTable } = useSports()

  const columns = [
    {
      title: "Deporte",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Acción",
      dataIndex: "details",
      key: "details",
      fixed: "right",
      width: 185,
    },
  ];

  const handleSport = async ({reason}) => {
    setButtonLoadingHandleSport(true)
    const url = `/handle-sport-availability/${sportId}`
    const body = {
      reason,
      available: sportStatusAction ? false : true
    }
    try {
      const response = await request('PATCH', url, body)
      const data = await response.json()
      // console.log('body edit', body)
      // console.log('data', data)
      if(response.status === 200) {
        setMessage(data.message)
        setButtonLoadingHandleSport(false)
        setShowHandleSport(false)
        setHandleSuccess(true)
        // setLoadingSport(true)
        setLoadingSportInTable(true)
      } else {
        setMessage(data.message)
        setButtonLoadingHandleSport(false)
        setShowHandleSport(false)
        setHandleError(true)
      }
    }
    catch (error) {
      // console.log(error)
      setMessage(messageCatchRequest)
      setButtonLoadingHandleSport(false)
      setButtonLoadingHandleSport(false)
      setHandleError(true)
    }
  }

  useEffect(() => {
    const getTableData = () => {
      setSportsList(
        sports.map((sport) => ({
          name: sport.name,
          key: sport.id,
          status: sport.available ? "Activa" : "Inactiva",
          details: <Link onClick={() => {
            setSportId(sport.id)
            setSportName(sport.name)
            setShowHandleSport(true)
            setSportStatusAction(sport.available)
          }}>{sport.available ? "Deshabilitar" : "Habilitar"}</Link>,
        }))
      );
    };
    getTableData();
    setLoadingSportInTable(false)

  }, [loadingSportInTable, setLoadingSportInTable, sports]);

  return (
    <>
    <ReserveWrapper centerX>
      <ReserveMainTitle
        title="Salas"
        breadcrumb={["Inicio > ", `Gestion de salas`]}
      />
      <div className="box mb-20">
        <ReserveInnerTitle
          activity={null}
          title={'DEPORTES'}
          subtitle={`A continuación un listado de los deportes`}
        />
        <Table
          loading={loadingSport}
          scroll={{ x: 620 }}
          rowClassName={(a, b) => b % 2 === 0 && "bg-light"}
          className="reserve-active-table"
          columns={columns}
          dataSource={sportsList}
          pagination={false}
        />
      </div>
    </ReserveWrapper>
    {showHandleSport && <ReserveSuccessModal buttonLoadingHandleSport={buttonLoadingHandleSport} sportStatusAction={sportStatusAction} title={`${sportStatusAction ? "Deshabilitar" : "Habilitar"} ${sportName} `} message={`Usted está por ${sportStatusAction ? "Deshabilitar" : "Habilitar" } el deporte ${sportName}, ¿Estás seguro?`} handleCancel={() => setShowHandleSport(false)} handleSport={handleSport} setShowHandleSport={setShowHandleSport}  />}
    {handleSuccess && <Modal handleSport success setShowModal={setHandleSuccess} content={message} />}
    {handleError && <Modal error setShowModal={setHandleError} content={message} />}
    </>
  );
}

export default SportList;
