import { useState, useEffect } from 'react'
import { getRooms } from '../services/getRooms'

export const useRooms = ({reservations, sportId, activityId, loadingRoomWhenActivityNull, setLoadingRoomsNull, loaderRoom, setLoaderRoom} = {}) => {
  const [loadingRooms, setLoadingRooms] = useState(false)
  const [rooms, setRooms] = useState([]) 
  const [errorRooms, setErrorRooms] = useState(null) 
  
  useEffect(() => {
    setLoadingRoomsNull && setLoadingRoomsNull(false)
    setLoadingRooms(true)
    setLoaderRoom && setLoaderRoom(false)
    getRooms({sportId, activityId, reservations}).then(data => {
      // console.log('data desde useRooms', data)
      setRooms(data) 
      setLoadingRooms(false) 
    }).catch(err => {
      setErrorRooms(err)
    })
  }, [activityId, reservations, loadingRoomWhenActivityNull, loaderRoom, sportId, setLoadingRoomsNull, setLoaderRoom])

  return { loadingRooms, rooms, errorRooms }
}
