import React from 'react';
import {Modal, Button, Input, Form} from 'antd';
import {withRouter} from 'react-router-dom';
import './ReserveSuccessModal.scss' ;
import logo from '../../../assets/logo-vaac-blue.svg';
import iconTenis from '../../../assets/icon-tenis.svg';
import iconCycling from '../../../assets/icon-cycling.svg';
import iconSquash from '../../../assets/icon-squash.svg';
import iconOthers from '../../../assets/icon-others.svg';


const icons = {
	'tenis': iconTenis,
	'cycling': iconCycling,
	'squash': iconSquash,
	'others': iconOthers
}

function ReserveSuccessModal(props) {

  // Esto es para cancelar una relacion
  const changeFinish = (e) => {
    props.cancelClass(e)
  }

  // Esto es para deshabilitar/habilitar un deporte 
  const changeFinishHandleSportStatus = (e) => {
    props.handleSport(e)
  }

  // Esto es para deshabilitar/habilitar una actividad 
  const changeFinishHandleActivityStatus = (e) => {
    props.handleStatusActivity(e)
  }

  // Esto es para deshabilitar/habilitar una actividad 
  const changeFinishBloquedReserves = (e) => {
    props.reservationBlocking(e)
  }

  // Esto es para deshabilitar/habilitar una sala 
  const changeFinishRoomStatus = (e) => {
    console.log('changeFinishRoomStatus', e)
    props.disableSale({cancellationReason: e.cancellationReason})
  }

  return (
    <>
     <div className="modal-reserves">
      <Modal footer={null} visible={true} centered closable={props.handleCancel ? true : false} onCancel={() => props.handleCancel && props.handleCancel()}>
        <div className="modal-body-reserves">
          <div className="main-modal-body-reserves--logo"><img src={logo} alt="Logo Vaac" className="modal-body-reserves--logo"/></div>
          {props.activity && <div><img src={icons[props.activity]} alt="Icono" className="modal-body-reserves--icon"/></div>}
          <h6 className="title-upper-blue mt-20">{props.title}</h6>
          <p className="modal-body-reserves-message">{ props.message }</p>
          {
            props.detailSala &&  // Botones para cuando quiere desactivar/ACTIVAR una sala
            <>
            {props.statusSale
            ?
            <>
              <Form onFinish={changeFinishRoomStatus}>
                <div>
                  <p className='title-small-blue mb-5'>Indique la razón: </p>
                  <Form.Item name="cancellationReason" placeholder="Ingrese la razón" rules={[{required: true, message: 'Campo obligatorio' }]}>
                    <Input className="input" />
                  </Form.Item>
                </div>
                <Button htmlType="submit" className="button purple mb-10" loading={props.loadingDesactivatedSale}>Sí, {props.statusSale ? 'desactivar' : 'activar'}</Button>
              </Form>
              <div><p className="modal-body-reserves-link" onClick={() => props.setShowModal(false)} type='link' >Dejarla {props.statusSale ? 'activa' : 'desactivada'}.</p></div>
              </>
              :
              <>
                <Button className="button purple mb-10" loading={props.loadingDesactivatedSale} onClick={() => changeFinishRoomStatus({cancellationReason: null})}>Sí, {props.statusSale ? 'desactivar' : 'activar'}</Button>
                <div><p className="modal-body-reserves-link" onClick={() => props.setShowModal(false)} type='link' >Dejarla {props.statusSale ? 'activa' : 'desactivada'}.</p></div>
              </>
            }
            </>
          }
          {
            props.qualifyReserve && // Botones para cuando quiere calificar una reserva
            <>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.loading} onClick={() => props.qualifyReserve(true)}>SÍ, ASISTIÓ</Button>
              <Button className="button purple outline ml-5 mr-5 mb-10" loading={props.loading} onClick={() => props.qualifyReserve(false)}>NO ASISTIÓ</Button>
            </>
          } 
          {
            props.cancelReserve &&  // Botones para cuando quiere cancelar reserva
            <>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.loading} onClick={() => props.cancelReserve()}>SÍ, CANCELAR ESTA RESERVA</Button>
              <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowModalCancel(false)} type='link' >No deseo cancelarla.</p></div>
            </>
          } 
          {
            props.bloquedReservations &&  // Botones para bloquear las reservaciones en semana radical u otro caso
            <>
            {!props.settingsBloqued
            ?
            <>
              <Form onFinish={changeFinishBloquedReserves}>
                <div>
                  <p className='title-small-blue mb-5'>Indique la razón: </p>
                  <Form.Item name="information" placeholder="Ingrese la razón" rules={[{required: true, message: 'Campo obligatorio' }]}>
                    <Input className="input" />
                  </Form.Item>
                </div>
                <Button htmlType="submit" className="button purple mb-10  ml-5 mr-5" loading={props.loading}>SÍ {props.settingsBloqued ? 'DESBLOQUEAR' : 'BLOQUEAR'}</Button>
              </Form>
              <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowhandleBloking(false)} type='link' >No, dejarlas {props.settingsBloqued ? 'bloqueadas' : 'desbloquedas'}.</p></div>
              </>
              :
              <>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.loading} onClick={() => changeFinishBloquedReserves({information: null})}>SÍ {props.settingsBloqued ? 'DESBLOQUEAR' : 'BLOQUEAR'}</Button>
               <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowhandleBloking(false)} type='link' >No, dejarlas {props.settingsBloqued ? 'bloqueadas' : 'desbloquedas'}.</p></div> 
              </>}
              </>
            } 
          {
            props.deleteInstructor &&  // paRa elimnar un instructor
            <>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.buttonLoading} onClick={() => props.deleteInstructor()}>SÍ, ELIMINAR</Button>
              <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowModalDelete(false)} type='link' >No deseo eliminarlo.</p></div>
            </>
          } 
          {
            props.deleteAdmin &&  // paRa elimnar un instructor
            <>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.buttonLoading} onClick={() => props.deleteAdmin()}>SÍ, ELIMINAR</Button>
              <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowModalDelete(false)} type='link' >No deseo eliminarlo.</p></div>
            </>
          } 
          {
            props.changeStatus &&  // 
            <>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.buttonLoading} onClick={() => props.changeStatus()}>SÍ, CAMBIAR</Button>
              <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowModalStatus(false)} type='link' >No deseo cambiarlo.</p></div>
            </>
          } 
          {
            props.statusRelation &&  // Para cambiar estatus de la relacion
            <>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.buttonLoading} onClick={() => props.changeAvailability()}>SÍ, CAMBIAR</Button>
              <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowModalStatus(false)} type='link' >No deseo cambiarlo.</p></div>
            </>
          } 
          {
            props.handleSport &&  // DESHABILITAR/HABILITAR DEPORTE
            <>
              <Form onFinish={changeFinishHandleSportStatus}>
                <div>
                  <p className='title-small-blue mb-5'>Indique la razón: </p>
                  <Form.Item name="reason" placeholder="Ingrese la razón" rules={[{required: true, message: 'Campo obligatorio' }]}>
                    <Input className="input" />
                  </Form.Item>
                </div>
                <Button className="button purple mb-10  ml-5 mr-5" htmlType="submit" loading={props.buttonLoadingHandleSport} >{props.sportStatusAction ? "Sí, Deshabilitar" : "Sí, Habilitar"}</Button>
              </Form>
              <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowHandleSport(false)} type='link' >{props.sportStatusAction ? "No, dejarlo habilitado" : "No, dejarlo deshabilitado"}</p></div>
            </>
          } 
          {
            props.handleStatusActivity &&  // DESHABILITAR/HABILITAR ACTIVIDAD
            <>
              <Form onFinish={changeFinishHandleActivityStatus}>
                <div>
                  <p className='title-small-blue mb-5'>Indique la razón: </p>
                  <Form.Item name="reason" placeholder="Ingrese la razón" rules={[{required: true, message: 'Campo obligatorio' }]}>
                    <Input className="input" />
                  </Form.Item>
                </div>
                <Button className="button purple mt-10 ml-5 mr-5" htmlType="submit" loading={props.buttonLoadingStatusActivity} >{props.sportStatusAction ? "Sí, Deshabilitar" : "Sí, Habilitar"}</Button>
              </Form>
              <div><p className="modal-body-reserves-link mt-10" href="#" onClick={() => props.setShowModalStatusActivity(false)} type='link' >{props.sportStatusAction ? "No, dejarla habilitado" : "No, dejarla deshabilitado"}</p></div>
            </>
          } 
          {
            props.cancelClassRelation &&  // Para cancelar la clase / relacion
            <>
            <Form onFinish={changeFinish}>
              <div>
                <p className='title-small-blue mb-5'>Indique la razón: </p>
                <Form.Item name="reason" placeholder="Ingrese la razón" rules={[{required: true, message: 'Campo obligatorio' }]}>
                  <Input className="input" />
                </Form.Item>
              </div>
              <Button className="button purple mb-10 ml-5 mr-5" htmlType="submit" loading={props.buttonLoadingCancelClass} >SÍ, CANCELAR</Button>
            </Form>
              
            <div><p className="modal-body-reserves-link" href="#" onClick={() => props.setShowcancelClass(false)} type='link' >No, no deseo cancelarla.</p></div>
            </>
          } 
        </div>
      </Modal>
     </div>     
    </>
  )
}
export default withRouter(ReserveSuccessModal);
