import  { useState, useEffect } from "react";
import { getStatusWeekedHistory } from "../services/getStatusWeekedHistory";

const INITIAL_PAGE = 0

const useStatusWeekedHistory = ({ uniqueId }) => {
  const [notFoundPartnerHistory	, setNotFoundPartnerHistory] = useState(false)
  const [errorSearchSocioHistory	, setErrorSearchSocioHistory] = useState(false)
  const [dataHistory, setDataHistory] = useState([])
  const [dataHistoryCount, setDataHistoryCount] = useState(null)
  const [loadingHistory, setLoadingHistory] = useState(true)
  const [page, setPage] = useState(INITIAL_PAGE) 

  useEffect(() => {
    setLoadingHistory(true)
    getStatusWeekedHistory({ uniqueId, page: page }).then(data => {
      if(data.message === 'No tenemos registro de este socio.') {
        setNotFoundPartnerHistory(true)
        setLoadingHistory(false)
        return
      }
      setDataHistoryCount(data.history.count)
      setDataHistory(prevDataHistory => prevDataHistory.concat(data.history.rows)) 
      setLoadingHistory(false)
    }).catch(err => {
      setErrorSearchSocioHistory(true)
      setLoadingHistory(false)
    })
  }, [uniqueId, page])

  return { notFoundPartnerHistory, loadingHistory, dataHistoryCount, dataHistory, errorSearchSocioHistory, setPage }
}

export default useStatusWeekedHistory
