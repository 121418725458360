import { useState, useEffect } from 'react'
import { getActivity } from '../services/getActivity'

export const useActivity= ({ activityId }) => {
  const [loadingActivity, setLoadingActivity] = useState(true)
  const [activity, setActivity] = useState([]) 
  const [loadingnViewActivityDetails, setLoadingnViewActivityDetails] = useState(false)
  
  useEffect(() => {
    setLoadingnViewActivityDetails(false)
    // console.log('Se ejecuto')
    setLoadingActivity(true)
    getActivity(activityId).then(data => {
      setActivity(data) 
      setLoadingActivity(false) 
    })
  }, [activityId, loadingnViewActivityDetails])

  return { loadingActivity, activity, setLoadingActivity, setLoadingnViewActivityDetails  }
}
