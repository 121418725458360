import { useState, useEffect } from 'react'
import { getActivities } from '../services/getActivities'

export const useActivities = ({sport, reservations, bloquedInput} = {}) => {
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [activities, setActivities] = useState([]) 
  const [errorActivities, setErrorActivities] = useState(null) 
  
  useEffect(() => {
    if(sport === null) {
      setLoadingActivities(false)
      return 
    }
    setLoadingActivities(true)
    getActivities(sport, reservations).then(data => {
      setActivities(data) 
      setLoadingActivities(false) 
    }).catch(err => {
      setErrorActivities(err)
    })
  }, [sport, bloquedInput, reservations])

  return { loadingActivities, activities, errorActivities }
}
