export default function request(method, endpoint, body = null, newHeaders = null) {
	let headers = {
		'Content-Type': 'application/json',
		// 'Authorization': 'token aqui'
		'Authorization': `Bearer ${localStorage.getItem('token')}`
	}
	if (newHeaders) {
		headers = newHeaders;
	}
	if (!body) {
		return fetch(process.env.REACT_APP_API_URL + endpoint, {
			method: method,
			headers: headers
		}) 
	} else {
		return fetch(process.env.REACT_APP_API_URL + endpoint, {
			method: method,
			headers: headers,
			body: JSON.stringify(body)
		})
	}
}
