import React from 'react';
import { Modal } from 'antd';
import './Modal.scss' ;
import logo from '../../../assets/logo-vaac-blue.svg';
import { withRouter } from 'react-router';

const ModalBox = (props) => {


  const closeDrawer = () => {
    props.setShowModal(false);
    if(props.password) return props.history.push('/datos');
    if(props.instructorDelete) return props.history.push('/instructores');
    if(props.instructorCreate) return props.history.push('/instructores');
    if(props.instructorEdit) return props.history.push('/instructores');
    if(props.sheduleCreate) return props.history.push('/horarios');
    if(props.createRelation) return props.history.push('/horarios');
    if(props.editInfoAdmin) return props.history.push('/datos');
    if(props.createSucess) return props.history.push('/administradores');
    if(props.verifySucess) return props.history.push('/');
    // if(props.handleSport) return () => props.setLoadingSport(true);
    if(props.successRecoverPassword) return props.history.push('/');
    // if(props.bloquedReserved) return props.history.push('/perfil');
    if(props.changeStatusRelation) {
      return props.history.push(`/editar-horario/${props.idRelationStatus}`);
    }
    if(props.cancelReserveSucess) return props.history.push('/reservas/lista');
    // CUANDO CALIFICO Y LE DOY OK ME LLEVA A
    if(props.Qualify && props.sportQualify === 1) {
      return props.history.push('/reservas/lista')
    }
    if(props.Qualify && props.sportQualify === 2) {
      return props.history.push('/reservas/lista')
    } 
    if(props.Qualify && props.sportQualify === 3) {
      return props.history.push('/reservas/lista')
    }
  }
  
  return (
    <>
     <div className="modal-reserves">
      <Modal footer={null} visible={true} centered closable={false}>
        <div className="modal-body-reserves">
          <div className="main-modal-body-reserves--logo"><img src={logo} alt="Logo Vaac" className="modal-body-reserves--logo"/></div>
          {/* {props.activity && <div><img src={icons[props.activity]} alt="Icono" className="modal-body-reserves--icon"/></div>} */}
          {
            props.error && 
            <div className="mt-20 mb-20">
              {/* <h6 className="title-upper-blue">ERROR</h6> */}
              <p className="modal-body-reserves-message">{props.content}</p>
            </div>
          } 
          {
            props.success && 
            <>
            <h6 className="title-upper-blue mt-20 mb-20">{props.content}</h6>
            </>
          } 
         <div><p className="modal-body-reserves-link" href="#" onClick={() => closeDrawer()} type='link'>CERRAR VENTANA</p></div> 
        </div>
      </Modal>
     </div>     
    </>
  )
}
export default withRouter(ModalBox);
