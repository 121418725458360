
import React, {useState, useEffect} from 'react';
import { Button, Divider, Form, Select } from 'antd';
// import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './FormHorario.scss';
import { connect, useDispatch } from 'react-redux';
import request from '../../../services/request';
import ModalBox from '../../Molecules/Modal/Modal';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ListRelationByShedules from '../ListRelationByShedules/ListRelationByShedules';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import { useDays } from '../../../customHooks/useDays';
import { getDayString, messageCatchRequest } from '../../../services/helpers';
import { saveDay } from '../../../redux/actionsCreators';
// import { calcHour } from '../../../services/calcHour'

const FormHorario = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  // const [days, setDays] = useState([])
  const [currentSchedule, setCurrentSchedule] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successScheduleMessage, setSuccessScheduleMessage] = useState(false);
  const [successSchedule, setSuccessSchedule] = useState(false); // para cuando cree un horaio salga el modal sucesss
  const [errorSchedule, setErrorSchedule] = useState(false); // para cuando falle al crear un horaio salga el modal error
  const [errorScheduleMessage, setErrorScheduleMessage] = useState(''); // para cuando falle al crear un horaio salga el modal error
  // Hora inicial
  const [valueOpeningTime, setValueOpeningTime] = useState(null);

  // Hora final
  const [valueClosingTime, setValueClosingTime] = useState(null);

  // Valor del input closingTime
  // const [inputClosingTime, setInputClosingTime] = useState(null);

  // Hooks que retorna los dias 
  const { loadingDays, days } = useDays()

    let isEdit = props.history.location.pathname.includes('/editar');
    
    const back = () => props.history.push('/horarios');

    useEffect(() => {
      const getCurrentSchedule = async () => {
        const url = `/schedule/${props.match.params.id}`
        try {
          const response = await request('GET', url)
          // console.log(response)
          if(response.status === 200) {
            const data = await response.json()
            // console.log(data)
            setCurrentSchedule(data.schedule)
            setValueOpeningTime(data.schedule.openingTime);
            setValueClosingTime(data.schedule.closingTime);
            // setInputClosingTime(data.schedule.ReserveInnerTitleclosingTime)
            // const closingTime = calcHour({hourString : data.schedule.openingTime})
          } 
          if(response.status === 404) {
            props.history.push('/horarios')
          } 
          setLoading(false);
        } catch (error) {
          // console.log(error)
        }
      }
      isEdit && getCurrentSchedule();
    }, [isEdit, props.history, props.match.params.id])


    // Creando/Editando horarios
    const onFinish = async (e) => { 
      setButtonLoading(true);
      if(isEdit) {
        const url = `/update-schedule/${currentSchedule.id}`
        const body = {
          dayId: e.day,
          openingTime: valueOpeningTime,
          closingTime: valueClosingTime,
          scheduleBlock : e.bloqDay,
        }
        // console.log('body.edit', body)
        try {
          const response = await request('PATCH', url, body)
          const data = await response.json()
          // console.log('response', response)
          if(response.status === 200) {
            setSuccessSchedule(true);
            setButtonLoading(false);
            setSuccessScheduleMessage(data.message)
          }else {
            setButtonLoading(false);
            setErrorSchedule(true)
            setErrorScheduleMessage(data.message)
          }
        } catch (error) {
          // console.log(error)
          setButtonLoading(false);
          setErrorSchedule(true)
          setErrorScheduleMessage(messageCatchRequest)
        }
      } else {
        const url = `/create-schedule`
        const body = {
          dayId: e.day,
          openingTime: valueOpeningTime,
          closingTime: valueClosingTime,
          available: true,
          scheduleBlock : e.bloqDay,
        }
        // console.log('body.create', body)
        try {
          const response = await request('POST', url, body)
          // console.log('response', response)
          const data = await response.json()
          
          if(response.status === 200) {
            setSuccessSchedule(true);
            setButtonLoading(false);
            setSuccessScheduleMessage(data.message)
            dispatch(saveDay({
              id: e.day,
              slug: getDayString(e.day)
            }))
          } else {
            setButtonLoading(false);
            setErrorSchedule(true)
            setErrorScheduleMessage(data.message)
          }
        } catch (error) {
          // console.log(error)
          setButtonLoading(false);
          setErrorSchedule(true)
          setErrorScheduleMessage(messageCatchRequest)
        }
      }
    }

    const onChangeOpeningTime = time => {
      // console.log(time.target.value)
      setValueOpeningTime(time.target.value);
      // const closingTime = calcHour({hourString : time.target.value})
      // setInputClosingTime(closingTime)
    };

    const onChangeClosingTime = time => {
      // console.log(time.target.value)
      setValueClosingTime(time.target.value);
    };

    return ( 
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis datos: " breadcrumb={['Inicio > ', 'Mis datos']}/>
         <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title={isEdit ? 'EDITAR HORARIO' : 'CREAR HORARIO'} subtitle={`A continuación puede ${isEdit ? 'editar' : 'crear'} un horario.`}/>
          <div className='main-form-shedules'>
            {
              isEdit && loading ? <SpinnerLoader small/>
              : 
            <Form onFinish={(e) => onFinish(e)} className="create-form_shedules"
              initialValues={{
                day: isEdit && currentSchedule ? currentSchedule.day.id : '',
                openingTime: isEdit && currentSchedule ? currentSchedule.openingTime : '',
                closingTime: isEdit && currentSchedule ? currentSchedule.closingTime : '',
                bloqDay: isEdit && currentSchedule ? currentSchedule.scheduleBlock : ''
              }}
              >
                <div className="mb-15">
                  <p className='title-small-blue mb-5'>Bloque de día: </p>
                  <Form.Item placeholder='Bloque de día:' name='bloqDay' className='input-filter block no-space' rules={[{required: true, message: 'Campo obligatorio' }]}>
                    <Select placeholder='Bloque de día:' /*onChange={value => setSala(value)}*/>
                      <Select.Option key='am' value='am'>AM</Select.Option>,
                      <Select.Option key='pm' value='pm'>PM</Select.Option>,
                    </Select>
                  </Form.Item>
                </div>

                <div className="mb-25">
                  <p className='title-small-blue mb-5'>Día: </p>
                  <Form.Item name='day' className='input-filter block no-space' rules={[{required: true, message: 'Campo obligatorio' }]}>
                   {loadingDays ? <SpinnerLoader small/> : <Select placeholder='Día' /*onChange={value => setSala(value)}*/>
                      {
                        days.map(day => 
                          <Select.Option key={day.id} value={day.id}>{day.name}</Select.Option>
                        )
                      }
                    </Select>}
                  </Form.Item>
                </div>

                <div className="mb-25">
                  <p className='title-small-blue mb-5'>Inicio: </p>
                  <input id="date" type="time" placeholder='Finaliza' className="input-time" defaultValue={currentSchedule.openingTime} name='openingTime' onChange={onChangeOpeningTime}  />
                </div>

                <div className="mb-15">
                  <p className='title-small-blue mb-5'>Finaliza: </p>
                  <input id="date" type="time" placeholder='Finaliza' className="input-time"  defaultValue={currentSchedule.closingTime} name='closingTime' onChange={onChangeClosingTime} />
                </div>

                <Button htmlType="submit" className="button purple button-schedules" loading={buttonLoading}>
                  {isEdit ? 'Guardar Horario': 'Crear Horario'}
                </Button>
            </Form>
            }

            {isEdit && !loading &&  <Divider />}
          </div>

          {/* lISTA DE RELACIONES CON EL HORARIO */}
          {isEdit && <div>
            <ListRelationByShedules scheduleId={props.match.params.id}/>
          </div>}

          {/*Modal cuando creo/edito un horario con exito  */}
          {successSchedule && <ModalBox sheduleCreate success setShowModal={setSuccessSchedule} content={successScheduleMessage}/>}
          {/*Modal cuando no puedo crar horario */}
          {errorSchedule && <ModalBox error errorSchedule setShowModal={setErrorSchedule} content={errorScheduleMessage} />}
          <ReserveInnerFooter back={back}/>
        </div>
      </ReserveWrapper>
    )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(FormHorario);
