import React from 'react'
import { Form, Select } from 'antd';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';

const SelectItemList = ({lists, title, nameItem, loading, functionChange, hasInstructor, bloquedInput}) => {

  return (
    <div>
        <p className='title-small-blue mb-5'>{title}: </p>
        <Form.Item name={nameItem} className='input-filter block' >
          {
            loading ? <SpinnerLoader small/> : 
            <Select placeholder={title === 'Instuctor' ? hasInstructor ? title : 'N/A' : bloquedInput ? 'N/A' : title} onChange={value => functionChange(value)}  disabled={title === 'Instuctor' ? hasInstructor ? false : true : title === 'Actividad' && bloquedInput ? true : false}>
            {
              
              lists.map(list => 
                <Select.Option key={list.id} value={title === 'Sala' ? list.room.id : list.id}>{title === 'Instuctor' ? list.firstName :  title === 'Sala' ? list.room.name : list.name} </Select.Option>
              )
            }
          </Select>
          }
        </Form.Item>
    </div>
  ) 
}

export default SelectItemList
