
import React, {useState} from 'react';
import { Button, Form, Input } from 'antd';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import DetailReserveHistory from '../DetailReserveHistory/DetailReserveHistory';

const SearchReserveSocioDetails = () => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const [reserveDetailIdValue, setReserveDetailId] = useState(null)

  const onFinish = ({ reserveDetailId }) => {
    if(reserveDetailIdValue === reserveDetailId) return 
    setButtonLoading(true)
    setReserveDetailId(reserveDetailId)
  }

  return (
    <ReserveWrapper centerX>
      <ReserveMainTitle title='Consulta:' breadcrumb={['Inicio > ', 'Consulta']}/>
      <div className='box mb-30'>
      <ReserveInnerTitle activity={false} title='BÚSQUEDA DE RESERVA' subtitle='A continuación podrá consultar la información de una reserva'/>
        <div className='main-consulta-socio-form'>
          <Form className="create-form_shedules" onFinish={onFinish}>
            <Form.Item name="reserveDetailId" rules={[{required: true, message: 'Campo obligatorio' }]}>
              <Input placeholder={`Número de reserva: `} className="input"/>
            </Form.Item>
            <Button htmlType="submit" className="button purple" loading={buttonLoading}>
              Buscar
            </Button>
          </Form>
        </div>
        <div className='main-consulta-socio-list mt-30'>
          {reserveDetailIdValue && <DetailReserveHistory setButtonLoading={setButtonLoading} noBox reserveDetailId={reserveDetailIdValue} />}
        </div>
      </div>
    </ReserveWrapper>
  )
}

export default SearchReserveSocioDetails
