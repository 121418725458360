import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getShedules } from "../services/getSchedules";

const INITIAL_PAGE = 0;
const useSchedules = ({ dayId }) => {
  const [loadingSchedules, setLoadingSchedules] = useState(true);
  const [loadingNextSchedules, setLoadingNextSchedules] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [schedulesCount, setSchedulesCount] = useState(0)
  const [page, setPage] = useState(INITIAL_PAGE)

  useEffect(() => {
    let dataRows = [];
    setLoadingSchedules(true);
    getShedules({ dayId }).then((schedules) => {
      setSchedulesCount(schedules.count);
      schedules.rows.forEach((row) => {
        dataRows.push({
          openingTime: row.openingTime ? row.openingTime : "N/A",
          closingTime: row.closingTime ? row.closingTime : "N/A",
          scheduleBlock: row.scheduleBlock ? row.scheduleBlock : "N/A",
          details: (
            <Link to={`/editar-horario/${row.id}`}>Editar/Relacionar</Link>
          ),
          key: row.id,
        });
      });
      setSchedules(dataRows);
      setLoadingSchedules(false);
    }).catch(err => {
      console.log('Error al obtener horarios', err)
    })
  }, [dayId]);

  useEffect(() => {
    if(page === INITIAL_PAGE) return
    let dataRows = [];
    setLoadingNextSchedules(true);
    getShedules({ dayId, page }).then((schedules) => {
      setSchedulesCount(schedules.count);
      schedules.rows.forEach((row) => {
        dataRows.push({
          openingTime: row.openingTime ? row.openingTime : "N/A",
          closingTime: row.closingTime ? row.closingTime : "N/A",
          scheduleBlock: row.scheduleBlock ? row.scheduleBlock : "N/A",
          details: (
            <Link to={`/editar-horario/${row.id}`}>Editar/Relacionar</Link>
          ),
          key: row.id,
        });
      });
      setSchedules(prevSchudules => prevSchudules.concat(dataRows));
      setLoadingNextSchedules(false);
    });
  }, [dayId, page]);

  return { schedules, schedulesCount, loadingSchedules, loadingNextSchedules, setPage };
};

export default useSchedules;
