import React, {useState, useEffect} from 'react';
import {Button } from 'antd';
import SeatMap from '../../Organisms/SeatMap/SeatMap';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import request from '../../../services/request';
import ModalBox from '../../Molecules/Modal/Modal';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';

function EditMap(props) {
	// const [activity, setActivity] = useState(null);
	const [loading, setLoading] = useState(true);
	const [seatList, setSeatList] = useState([]);
	const [maxSeats, setMaxSeats] = useState(0);
	const [editedSeats, setEditedSeats] = useState([])
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [buttonLoading, setButtonLoading] = useState(false);

	const saveSeat = (row, column, id) => {
		let seatData;
		setSeatList(prevSeats => {
			const seat = prevSeats[row][column];
			if (!seat.spotId && !seat.instructor) { //primer click, activo
				/*let seatID = 0;
				prevSeats.forEach(row => {
					row.forEach(seat => {
						if (seat && seat.id > seatID) {
							seatID = seat.id
						}
					})
				})
				prevSeats[row][column] = {id: seatID + 1}*/
				let seatID = 1;  //obtengo minimo id
				for (let i=1; i <= maxSeats; i++) {
					let found = false;
					for (let j=0; j < prevSeats.length ; j++) {
						for (let k=0; k < prevSeats[j].length; k++) {
							if (prevSeats[j][k] && prevSeats[j][k].spotId === i) {
								found = true;
								break;
							}
						}
						if (found) {
							break;
						}
					}
					if (!found) {
						seatID = i;
						break;
					}
				}
				prevSeats[row][column] = {
					...prevSeats[row][column],
					spotId: seatID,
					instructor: false,
					usable: true,
				}
				seatData = {spotId: seatID, instructor: false, usable: true, id};
			} else if (seat.instructor) { //tercer click, desactivo
				prevSeats[row][column] = {
					...prevSeats[row][column],
					spotId: null,
					instructor: false,
					usable: false,
				}
				seatData = {spotId: null, instructor: false, usable: false, id}
			} else if (seat.spotId) { //segundo click, convierto a isntructor
				prevSeats[row][column] = {
					...prevSeats[row][column],
					spotId: null,
					instructor: true,
					usable: false
				}
				seatData = {spotId: null, instructor: true, usable: false, id}
				/*const missingID = seat.spotId;  //reducir los numeros de los otros asientos
				prevSeats.forEach((row, rowi) => {
					row.forEach((seat, seati) => {
						if (seat && seat.spotId > missingID) {
							prevSeats[rowi][seati].spotId = prevSeats[rowi][seati].spotId - 1
						}
					})
				})*/
			}
			const newSeats = [...prevSeats];
			return newSeats;
		})
		setEditedSeats(prevSeats => {
			const newEdited = [...prevSeats];
			const editIndex = prevSeats.indexOf(seatData.id)
			if (editIndex !== -1) {
				newEdited[editIndex] = seatData.id
			} else {
				newEdited.push(seatData.id)
			}
			return newEdited;
		})
	}
	const saveSeatList = async () => {
		setButtonLoading(true)
		const editedSeatsData = [];
		editedSeats.forEach(id => {
			seatList.forEach((row, rowIndex) => {
				row.forEach((col, colIndex) => {
					if (col && col.id === id) {
						editedSeatsData.push(col)
					}
				})
			})
		})
		const response = await request('POST', '/edit-places', {
			places: editedSeatsData
	  	})
	  	setButtonLoading(false)
	  	if (response.ok) {
		  	setSuccess(true)
	  	} else {
	  		setError(true)
	  	}
	}

	useEffect(()=>{
	  	const mapRequest = async () => {
		  	const response = await request('POST', '/map', {
				sportId: 2,
				activityId: 3,
				roomId: props.match.params.roomId
		  	})
		  	if (response.ok) {
			  	const data = await response.json();
			  	if (data && data.map) {
			  		setSeatList(data.map)
			  		setMaxSeats(data.map.length * data.map[0].length);
			  		setLoading(false)
			  	}
		  	}
	  	}
	  	mapRequest()
  	}, [props.match.params.roomId])

	return (
		<>
			{!loading ?
				<>
					<ReserveWrapper centerX>
						<ReserveMainTitle title='Salas' breadcrumb={['Inicio > ', `Gestion de salas > `, 'Indoor Cycling > ', 'Editar']}/>
						<div className='box mb-20'>
							<ReserveInnerTitle activity='cycling' title={`INDOOR CYCLING`} subtitle={`Activos o espacios habilitados para Indoor Cycling`}/>
							<SeatMap admin seatList={seatList} saveSeat={saveSeat} />
							<Button loading={buttonLoading} onClick={saveSeatList} className="button purple center-btn mt-35">Guardar</Button>
						</div>
					</ReserveWrapper>
					{error && <ModalBox error setShowModal={setError} content="Error al actualizar el mapa, inténtelo de nuevo"/>}
					{success && <ModalBox success setShowModal={setSuccess} content="Mapa actualizado con éxito"/>}
				</>
				: <SpinnerLoader />
			}
		</>
	)
}

export default EditMap;
