import React from 'react';
import './ReserveMainTitle.scss';

function ReserveMainTitle(props) {
	return (
    <>
		{/* <div className='container-reserve-main-title'>
			<h1 className='container-reserve-main-title--title'>{props.title}</h1>
			{
        props.breadcrumb.map(name =>
          <span key={name} className='container-reserve-main-title--breadcrumb'>{name}</span>
        )
      }
		</div> */}
    <div className="separador"></div>
    </>
	)
}

export default ReserveMainTitle
