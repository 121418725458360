import React from 'react'
import './ModalComponent.scss'

const ModalComponent = ({ isOpen, closeModal, children }) => {
  return (
    <div className={`modal-reserve-container ${isOpen && 'open'}`} onClick={closeModal}>
      <div className={`modal-reserve`} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default ModalComponent
