import React, { useEffect, useState } from 'react'
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter'
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle'
import request from "../../../services/request"
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader'


const DetailReserveHistory = ({ closeModal, reserveDetailId, noBox, setButtonLoading }) => {
  const [loadingReserve, setLoadingReserve] = useState(true)
  const [reserve, setReserve] = useState(true)
  const [responsable, setResponsable] = useState(null)
  const [reserve404, setReserve404] = useState(false)
  const [reserveError, setReserveError] = useState(false)
  const [sportIcon, setSportIcon] = useState(null)

  useEffect(() => {
    if (!reserveDetailId) return 
    setLoadingReserve(true)
    const getReserve = async ({reserveId}) => {
      // const response = await request('GET', `/reservation-item/${reserveId}`) 
      const response = await request('GET', `/reservation/${reserveId}`);
      const data = await response.json()  
      if(response.status === 200) {
        setReserve404(false)
        setReserveError(false)
        setButtonLoading && setButtonLoading(false)
        setReserve(data.reservation)
        setResponsable(data.reservation.reservationItem.filter(rsv => rsv.responsable))
        console.log('socio responsable', data.reservation.reservationItem.filter(rsv => rsv.responsable))
        console.log('data.reservation', data.reservation)
        if(data.reservation.sport.name === 'Tenis') {
          setSportIcon('tenis')
        } else if(data.reservation.sport.name === 'Indoor Cycling') {
          setSportIcon('cycling')
        }else if(data.reservation.sport.name === 'Squash') {
          setSportIcon('squash')
        } else {
          setSportIcon('others')
        }
        // setReserve(data.reservationItem)
        // console.log('data', data)
        // if(data.reservationItem.reservation.sport.name === 'Tenis') {
        //   setSportIcon('tenis')
        // } else if(data.reservationItem.reservation.sport.name === 'Indoor Cycling') {
        //   setSportIcon('cycling')
        // }else if(data.reservationItem.reservation.sport.name === 'Squash') {
        //   setSportIcon('squash')
        // } else {
        //   setSportIcon('others')
        // }
        setLoadingReserve(false)
      } else if(response.status === 404) {
        setReserve404(true)
        setReserveError(false)
        setButtonLoading && setButtonLoading(false)
      } else {
        setReserveError(true)
        setReserve404(false)
        setButtonLoading && setButtonLoading(false)
      }
    } 

    getReserve({reserveId: reserveDetailId})
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserveDetailId])

  if(reserve404) {
    return (
      <div className={`${!noBox && 'box'}`}>
         <h6 className="title-upper-red mb-5 center-text">RESERVA NO ENCONTRADA</h6>
      </div>
    )
  }
  if(reserveError) {
    return (
      <div className={`${!noBox && 'box'}`}>
         <h6 className="title-upper-red mb-5 center-text">HA OCURRIDO UN ERROR, INTENTELO MÁS TARDE.</h6>
      </div>
    )
  }
  return (
    <div className={`${!noBox && 'box'}`}>
       {
      loadingReserve 
       ? <SpinnerLoader small />
       : 
       <div>
          {/* <ReserveInnerTitle activity={sportIcon} title={`RESERVA DE ${reserve.reservation.sport.name}`} subtitle={`N° de reserva ${reserveDetailId}`}/> */}
          <ReserveInnerTitle activity={sportIcon} title={`RESERVA DE ${reserve.sport.name}`} subtitle={`N° de reserva ${reserveDetailId}`}/>
          <div className="main-detail-reserve">
            <div className="detail-reserve-row-1">
              <h6 className="title-upper-red mb-5">INFORMACIÓN DE RESERVACIÓN:</h6>
              {/* <p className="no-space"><span className="title-small-blue">Responsable de la reserva: </span>{reserve.firstName} {reserve.lastName} <span className="title-small-blue">- ({reserve.status})</span></p>
              <p className="no-space"><span className="title-small-blue">Fecha de la reserva: </span>{reserve.reservation.reservationDate}</p>
              <p className="no-space"><span className="title-small-blue">Hora de la reserva: </span>{reserve.reservation.schedule.openingTime} - {reserve.reservation.schedule.closingTime} {reserve.reservation.schedule.scheduleBlock}</p> */}
              <p className="no-space"><span className="title-small-blue">Responsable de la reserva: </span> <span className="title-small-blue">({responsable[0].actionNumber}) </span> {responsable[0].firstName} {responsable[0].lastName} <span className="title-small-blue">- ({reserve.status})</span></p>
              <p className="no-space"><span className="title-small-blue">Fecha de la reserva: </span>{reserve.reservationDate}</p>
              <p className="no-space"><span className="title-small-blue">Hora de la reserva: </span>{reserve.schedule.openingTime} - {reserve.schedule.closingTime} {reserve.schedule.scheduleBlock}</p>
            </div>
            <div className="detail-reserve-row-2 mt-20">
              <div className="detail-reserve-row-2--datails" >
                <h6 className="title-upper-red">DETALLES:</h6>
                {/* {reserve.reservation.status === process.env.REACT_APP_CANCELLED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> {`${reserve.reservation.status} por ${reserve.reservation.cancelledByPartner ? `el socio ${reserve.firstName} ${reserve.lastName}` : `el Administrador ${reserve.reservation.privatePersonName && reserve.reservation.privatePersonName}`}`}</p> }
                {reserve.reservation.status === process.env.REACT_APP_CANCELLED_RESERVATION && reserve.reservation.cancellationReason && <p className="no-space"><span className="title-small-blue">Razón de la cancelación: </span> {reserve.reservation.cancellationReason}</p> }
                {reserve.reservation.status === process.env.REACT_APP_USED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> Calificada por {reserve.reservation.privatePersonName}</p> } */}
                {reserve.status === process.env.REACT_APP_CANCELLED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> {`${reserve.status} por ${reserve.cancelledByPartner ? `el socio ${reserve.firstName} ${reserve.lastName}` : `el Administrador ${reserve.privatePersonName && reserve.privatePersonName}`}`}</p> }
                {reserve.status === process.env.REACT_APP_CANCELLED_RESERVATION && reserve.cancellationReason && <p className="no-space"><span className="title-small-blue">Razón de la cancelación: </span> {reserve.cancellationReason}</p> }
                {reserve.status === process.env.REACT_APP_USED_RESERVATION &&  <p className="no-space"><span className="title-small-blue">Estatus de la reserva: </span> Calificada por {reserve.privatePersonName}</p> }
                {/* <p className="no-space"><span className="title-small-blue">Actividad:</span> {reserve.reservation.sport ? reserve.reservation.sport.name : 'N/A'} ({reserve.reservation.activity ? reserve.reservation.activity.name : 'N/A'})</p>
                <p className="no-space"><span className="title-small-blue">Sala:</span> {reserve.reservation.room ? reserve.reservation.room.name : 'N/A'}</p>
                {reserve.specificLocation && <p className="no-space"><span className="title-small-blue">Espacio:</span> {reserve.reservation.specificLocation}</p>}
                {reserve.reservationPlaceId && <p className="no-space"><span className="title-small-blue">Bicicleta seleccionada:</span> {reserve.reservationPlaceId} </p>} */}
                <p className="no-space"><span className="title-small-blue">Actividad:</span> {reserve.sport ? reserve.sport.name : 'N/A'} ({reserve.activity ? reserve.activity.name : 'N/A'})</p>
                <p className="no-space"><span className="title-small-blue">Sala:</span> {reserve.room ? reserve.room.name : 'N/A'}</p>
                {reserve.specificLocation && <p className="no-space"><span className="title-small-blue">Espacio:</span> {reserve.specificLocation}</p>}
                {reserve.reservationPlaceId && <p className="no-space"><span className="title-small-blue">Bicicleta seleccionada:</span> {reserve.reservationPlaceId} </p>}

                <p className="no-space"><span className="title-small-blue">No. de participantes:</span> {reserve.reservationItem.length}</p>
                {reserve.reservationItem[0].reservationPlaceId && <p className="no-space"><span className="title-small-blue">Bicicleta seleccionada:</span> {reserve.reservationItem[0].reservationPlaceId} </p>}
              </div>
              {/* Si tiene mas de una persona, los que no son responsables osea los invitados */}
              {
                reserve.reservationItem.length > 1 && 
                <div className="detail-reserve-row-2--participants">
                  <h6 className="title-upper-red">INVITADOS:</h6>
                  {
                    reserve.reservationItem.filter(item => !item.responsable).map((socio, index) => 
                      <>
                     { console.log('invitado', socio)}
                      <p key={index} className="no-space"><span className="title-small-blue"> Invitado {index + 1}:</span> <span className="title-small-blue">({socio.actionNumber}) </span> {socio.firstName} {socio.lastName},  N° de reserva {socio.id} {reserve.status === process.env.REACT_APP_USED_RESERVATION && (<span className="title-small-blue">{`- ${socio.status}`}</span>)}</p>
                    </>
                    )
                  }
                </div>
              }
            </div>
          </div>
          {closeModal && <ReserveInnerFooter reserveHistory back={closeModal} />}
        </div>}
    </div>
  )
}

export default DetailReserveHistory
