import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getReservations } from '../services/getReservations'

const INITIAL_PAGE = 0; 

const useReservations = ({ status, sportId, activityId, roomId }) => {
  const [loadingReservations, setLoadingReservations] = useState(false)
  const [loadingNextPageReservations, setLoadingNextPageReservations] = useState(false)
  const [errorGetReservations, setErrorGetReservations] = useState(false)
  const [reserveList, setReserveList] = useState([]);
  const [reserveCount, setReserveCount] = useState(null);
  const [reserveCountRow, setReserveCountRow] = useState(0);
  const [page, setPage] = useState(INITIAL_PAGE)

  useEffect(() => {
    setLoadingReservations(true)
    getReservations({status, sportId, activityId, roomId})
    .then(reservations => {
      const newReserves = reservations.rows.map(rsv => ({
        id: rsv.id,
        date: rsv.reservationDate,
        time: `${rsv.schedule.openingTime} - ${rsv.schedule.closingTime} ${rsv.schedule.scheduleBlock}`,
        activity: `${rsv.sport.name} (${rsv.activity.name})`,
        details: <Link to={`/reservas/detalle/${rsv.id}`}>Ver detalle</Link>,
        key: rsv.id
      }))
      setReserveList(newReserves);
      setReserveCount(reservations.count);
      setReserveCountRow(reservations.rows.length);
      // console.log('reservations', reservations)
      setLoadingReservations(false)
    }).catch(error => {
      setLoadingReservations(false)
      console.log('error', error)
      setErrorGetReservations(true)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId, status, roomId, sportId])

  useEffect(() => {
    if(page === INITIAL_PAGE) return 
    setLoadingNextPageReservations(true)
    getReservations({status, sportId, activityId, roomId, page})
    .then(reservations => {
      const newReserves = reservations.rows.map(rsv => ({
        id: rsv.id,
        date: rsv.reservationDate,
        time: `${rsv.schedule.openingTime} - ${rsv.schedule.closingTime} ${rsv.schedule.scheduleBlock}`,
        activity: `${rsv.sport.name} (${rsv.activity.name})`,
        details: <Link to={`/reservas/detalle/${rsv.id}`}>Ver detalle</Link>,
        key: rsv.id
      }))
      setReserveList(prevNewReserves => prevNewReserves.concat(newReserves));
      setReserveCount(reservations.count);
      setReserveCountRow(reservations.rows.length);
      // console.log('Pagination reservations', reservations)
      setLoadingNextPageReservations(false)
    }).catch(error => {
      setLoadingNextPageReservations(false)
      console.log('error', error)
      setErrorGetReservations(true)
    })
  }, [activityId, status, roomId, sportId, page])

  return { reserveList, loadingNextPageReservations, reserveCount, reserveCountRow, errorGetReservations, setPage, loadingReservations }
}

export default useReservations
