import request from "./request"

// para obtener instructores
export const getInstructors = async ({ limit = 5, page = 0} = {}) => {
  const url = `/instructors/${limit}/${page * limit}`
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      // console.log('data.instructors', data.instructors)
      return data.instructors
    } else {
      throw Error('Error al obtener los instructors')
    }
  } catch (error) {
    throw Error('Error al obtener los instructors')
  }
}
