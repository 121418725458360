import request from "./request"

// para obtener instructores
export const getInstructorsSelect = async () => {
  const url = `/instructors`
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      return data.instructors
    } else {
      throw Error('Error al obtener los instructors')
    }
  } catch (error) {
    throw Error('Error al obtener los instructors')
  }
}
