import React from 'react';
import './Seat.scss';

function Seat(props) {
	return (
		<>
			{props.data ? 
				props.data.instructor ?
				<div className={`seat mr-5 ml-5 ${props.trx ? 'trx-instructor': 'seat-instructor'}`}>
					<span className='seat-number-instructor'>Instructor</span>
				</div>
				:
				props.reservedSeats.includes(props.data.id) ? 
				<div className={`seat mr-5 ml-5 ${props.trx ? 'trx-unavailable':'seat-unavailable'}`}></div>
				:
				<div className={`seat mr-5 ml-5 ${props.selectedSeats.includes(props.data.id) ? props.trx ? 'trx-selected': 'seat-selected' : props.trx? 'trx-available': 'seat-available'}`} onClick={() => props.saveSeat(props.data.id, props.row, props.column)} >
					<span className='seat-number'>{props.data.id}</span>
				</div>
				:
				<div className='seat mr-5 ml-5'></div>
			}
		</>
	)
}

export default Seat;