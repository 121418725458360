import  { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHistoryReservation } from "../services/getHistoryReservation";

const INITIAL_PAGE = 0

const useHistoryReservation = ({ openModalAndViewReserve, uniqueId, startDate, endDate, sportId, activityId, roomId, status }) => {
  const [errorGetReserveHistory	, setErrorGetReserveHistory] = useState(false)
  const [dataReserveHistory, setDataReserveHistory] = useState([])
  const [dataReserveHistoryCount, setDataReserveHistoryCount] = useState(null)
  const [loadingHistoryReserve, setLoadingHistoryReserve] = useState(true)
  const [page, setPage] = useState(INITIAL_PAGE) 

  useEffect(() => {
    if(startDate === null || endDate === null) {
      setLoadingHistoryReserve(false)
      return 
    }
    setLoadingHistoryReserve(true)
    getHistoryReservation({ uniqueId, startDate, endDate, sportId, activityId, roomId, status, page: page }).then(data => {
      // console.log('datadatadatadata', data.reservations)
      const newReserves = data.reservations.rows.map(rsv => ({
        id: rsv.id,
        date: rsv.reservationDate,
        time: `${rsv.schedule.openingTime} - ${rsv.schedule.closingTime} ${rsv.schedule.scheduleBlock}`,
        activity: `${rsv.sport.name} (${rsv.activity.name})`,
        status: `${rsv.reservation.status === 'Cancelado' ? rsv.reservation.cancelledByPartner ? 'Cancelado por el socio' : 'Cancelado por el administrador' : `El socio ${rsv.status}` }`,
        details: <Link onClick={() => openModalAndViewReserve(rsv.id)}>Ver detalle</Link>,
        key: rsv.id
      }))
      if(page === INITIAL_PAGE) {
        setDataReserveHistory(newReserves) 
      }else {
        setDataReserveHistory(prevDataReserveHistory => prevDataReserveHistory.concat(newReserves)) 
      }
      setDataReserveHistoryCount(data.reservations.count)
      setLoadingHistoryReserve(false)
    }).catch(err => {
      setErrorGetReserveHistory(err)
      setLoadingHistoryReserve(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId, startDate, endDate, sportId, activityId, roomId, status, page])

  return { loadingHistoryReserve, dataReserveHistoryCount, dataReserveHistory, errorGetReserveHistory, setPage }
}

export default useHistoryReservation
