import  { useState, useEffect } from "react";
import { getStatusWeekedCurrent } from "../services/getStatusWeekedCurrent";

const useStatusWeekedCurrent = ({ action, uniqueId }) => {
  const [partner, setPartner] = useState([])
  const [penalizations, setPenalizations] = useState([])
  const [hoursPlayed	, setHoursPlayed] = useState([])
  const [buttonLoading, setButtonLoading] = useState(true)
  const [notFoundPartner, setNotFoundPartner] = useState(false)
  const [errorSearchSocio, setErrorSearchSocio] = useState(false)

  useEffect(() => {
    setButtonLoading(true);
    getStatusWeekedCurrent({action, uniqueId}).then(data => {
      if(data.message === 'No tenemos registro de este socio.') {
        setNotFoundPartner(true)
        setButtonLoading(false)
        return
      }
      setPartner(data.partner.personalData)
      setPenalizations(data.partner.penalizations.filter(penalizationesFilter => penalizationesFilter.quantity > 0))
      setHoursPlayed(data.partner.hoursPlayed.filter(hourPlayed => hourPlayed.quantity > 0))
      setButtonLoading(false)
     }).catch(err => {
      setErrorSearchSocio(true)
      setButtonLoading(false)
    })
  }, [action, uniqueId])
  
    return { partner, penalizations, hoursPlayed, buttonLoading, notFoundPartner, errorSearchSocio}
  };  

export default useStatusWeekedCurrent;
