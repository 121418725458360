import React from 'react'
import './ErrorSelect.scss';

const ErrorSelect = ({ title, message }) => {
  return (
    <div className="container-errorSelect">
      <p className="title-small-blue mb-5">{title}</p>
      <span>{message}</span>
    </div>
  )
}

export default ErrorSelect
