import React, { useEffect, useState } from 'react'
import {  Select, Form, Divider, Table, Button } from 'antd';
import './ReserveHistory.scss'
import { useParams } from 'react-router-dom';
import ModalComponent from '../../Molecules/ModalComponent/ModalComponent';
import useModal from '../../../customHooks/useModal';
import DetailReserveHistory from '../DetailReserveHistory/DetailReserveHistory';
import useHistoryReservation from '../../../customHooks/useHistoryReservation';
import { useActivities } from '../../../customHooks/useActivities';
// import { useRooms } from '../../../customHooks/useRooms';
import { getRooms } from '../../../services/getRooms';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';

const columns = [{
  title: 'N° Reserva',
  dataIndex: 'id',
  key: 'id',
  fixed: 'left',
  width: 110
},
{
  title: 'Fecha',
  dataIndex: 'date',
  key: 'date',
},
{
  title: 'Hora',
  dataIndex: 'time',
  key: 'time',
},
{
  title: 'Actividad',
  dataIndex: 'activity',
  key: 'activity',
},
{
  title: 'Estatus',
  dataIndex: 'status',
  key: 'status',
},
{
  title: 'Detalles',
  dataIndex: 'details',
  key: 'details',
  fixed: 'right',
  width: 100
}];

const ReserveHistory = () => {
  const [isOpenModal, openModal, closeModal] = useModal(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [activityId, setActivityId] = useState(null) //Aqui guardo la actividad del input Select
  const [disabledActivity, setDisabledActivity] = useState(null) 
  const [loadingActivityForReloadRooms, setLoadingActivityForReloadRooms] = useState(false) 
  const [loadingSportWhenCangeDate, setLoadingSportWhenCangeDate] = useState(false) 
  const [loadingStartDateForReloadDateEnd, setLoadingStartDateForReloadDateEnd] = useState(false) 
  
  const [roomId, setRoomId] = useState(null) //Aqui guardo la sala del input Select

  const [sportId, setSport] = useState(null)
 
  const [reserveDetailId, setReserveDetailId] = useState(null)
  const { uniqueId } = useParams()

  // PASAR A HOOKS
  // eslint-disable-next-line no-unused-vars
  const [loadingRooms, setLoadingRooms] = useState(false)
  const [rooms, setRooms] = useState([])

  // Función para mostrar el modal con la reserva
  const openModalAndViewReserve = async (reserveId) => {
    setReserveDetailId(reserveId)
    openModal()
  }

  const { loadingHistoryReserve, dataReserveHistoryCount, setPage, dataReserveHistory } = useHistoryReservation({ openModalAndViewReserve, uniqueId, sportId, activityId, roomId, startDate, endDate, status : 'Usada' })
  const { loadingActivities, activities } = useActivities({sport: sportId})

  useEffect(() => {
    setPage(0)
    setLoadingActivityForReloadRooms(false)
    setLoadingStartDateForReloadDateEnd(false)
    setLoadingSportWhenCangeDate(false)
    if(sportId === null) {
      return 
    }
    setLoadingRooms(true)
    getRooms({sportId, activityId}).then(data => {
      setRooms(data) 
      setLoadingRooms(false) 
    }).catch(err => {
      throw Error(err)
      // console.log(err)
    })
  }, [sportId, activityId, loadingSportWhenCangeDate, setPage, roomId])

  // CAMBIO EL DESDE
  const handleSetStartDate = (e) => {
    setLoadingSportWhenCangeDate(true)
    setPage(0)
    setSport(null)
    setActivityId(null)
    setRoomId(null)
    setStartDate(e)
    setEndDate(null)
    setLoadingStartDateForReloadDateEnd(true)
  }

  // CAMBIO EL HASTA
  const handleSetEndDate = (e) => {
    setLoadingSportWhenCangeDate(true)
    setPage(0)
    setSport(null)
    setActivityId(null)
    setRoomId(null)
    setEndDate(e)
  }

  // CAMBIO DE SPORT 
  const handleSetSport = (e) => {
    setRoomId(null)
    setActivityId(null)
    setSport(e)
    if(e === 1 || e === 4) {
      setDisabledActivity(true)
    } else {
      setDisabledActivity(false)
    }
  }

  // CAMBIO DE ACTIVIDAD
  const handleSetActivity = (e) => {
    setActivityId(e)
    setRoomId(null)
    setLoadingActivityForReloadRooms(true)
  }

  // CAMBIO DE SALA
  const handleSetRoom = (e) => {
    setRoomId(e)
  }
  return (
    <>
    <div className="reserve-for-range">
      <p className="title-small-blue mt-15 mb-15">Seleccione una fecha de generación de reporte de historial de reservas:</p>
      <div className="reserve-for-range-input-container">
        <div className="reserve-for-range-start">
          <span>Desde: </span>
          <input type="date" id="start" name="trip-start" onChange={(e) => handleSetStartDate(e.target.value)} />
        </div>
        <div className="reserve-for-range-finish">
          <span>Hasta: </span>
          {loadingStartDateForReloadDateEnd ? <SpinnerLoader small/> :
          <input type="date" id="finish" min={startDate} name="trip-finish" onChange={(e) => handleSetEndDate(e.target.value)} />}
        </div>
      </div>
    
    <div className="reserve-for-range--select">
        {
        startDate && endDate &&
        <div className="mt-20">
         {loadingSportWhenCangeDate ? <SpinnerLoader small /> : <Form.Item placeholder="Deporte" label='Deporte' name='sport' className='input-filter block mr-20 mb-40'>
            <Select value={sportId} placeholder="Deporte" onChange={(value) => handleSetSport(value)}>
              <Select.Option value={1}>Tenis</Select.Option>
              <Select.Option value={2}>Indoor Cycling</Select.Option>
              <Select.Option value={3}>Clases de Salón</Select.Option>
              <Select.Option value={4}>Squash</Select.Option>
            </Select>
          </Form.Item>}
        </div>
        }

        {
        sportId && 
        <div className="mt-20">
          { loadingActivities ? <SpinnerLoader small /> :<Form.Item  placeholder={`${disabledActivity ? 'N/A' : 'Actividad'}`} label='Actividad' name='activity' className='input-filter block mr-20 mb-40'>
            <Select disabled={disabledActivity}  value={activityId} placeholder={`${disabledActivity ? 'N/A' : 'Actividad'}`} onChange={(value) => handleSetActivity(value)}>
              {
                activities.map(activity => 
                  <Select.Option key={activity.id} value={activity.id}>{activity.name}</Select.Option>
                )
              }
            </Select>
          </Form.Item>}
        </div>
        }
        {
        sportId && 
        <div className="mt-20">
          {loadingActivities || loadingActivityForReloadRooms ? <SpinnerLoader small/> :<Form.Item  placeholder="Sala" label='Sala' name='room' className='input-filter block mr-20 mb-40'>
            <Select value={roomId} placeholder="Sala" onChange={(value) => handleSetRoom(value)}>
              {
                rooms.map(room => 
                  <Select.Option  key={room.room.id} value={room.room.id}>{room.room.name}</Select.Option>
                )
              }
            </Select>
          </Form.Item>}
        </div>
        }
      </div>
      <Divider></Divider>
      <div>
        <Table loading={loadingHistoryReserve} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={dataReserveHistory} pagination={false}/>
        {(dataReserveHistory.length !== dataReserveHistoryCount) && dataReserveHistoryCount && <Button className="button turquoise outline center-btn mt-20" onClick={() => setPage(prevPage => prevPage + 1)}>Mostrar más</Button>}
      </div>
    </div>
    <ModalComponent isOpen={isOpenModal} closeModal={closeModal} >
      <DetailReserveHistory reserveDetailId={reserveDetailId} closeModal={closeModal}/>
    </ModalComponent>
    </>
  )
}

export default ReserveHistory
