import request from "./request"

export const getSchudulesRelation = async (scheduleId,limit,offset) => {
  const url = `/associations-by-schedule/${scheduleId}/${limit}/${offset}`
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      // console.log(data.scheduleAssociations)
      return data.scheduleAssociations
    } 
  } catch (error) {
    throw Error(error)
  }
}
