import React, {useState, useEffect} from 'react';
import {Table, Button} from 'antd';
import {Link} from 'react-router-dom';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
// import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import { useInstructors } from '../../../customHooks/useInstructors';

function InstructorList(props) {
    const [rows, setRows] = useState([]);

    const back = () => props.history.goBack() // Ir a atras

    const columns = [{
        title: 'Nombre',
        dataIndex: 'fname',
        key: 'fname'
    },
    {
        title: 'Apellido',
        dataIndex: 'lname',
        key: 'lname',
    },
    {
        title: 'Cédula',
        dataIndex: 'ci',
        key: 'ci',
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Detalles',
        dataIndex: 'details',
        key: 'details',
        fixed: 'right',
        width: 85
    }];

    const { loadingInstructors, instructors, instructorsCount, setPage } = useInstructors()
     
    // Funcion para mostrar mas instructores
     const showMoreInstructors = () => {
      // console.log('more instcuror')
      setPage(prevPage => prevPage + 1)
    }
  
    useEffect(()=>{
       // Funcion para obtener instructores
      const getInstructors = async () => {
        let dataRows = []
        instructors.forEach(row => 
          {
            dataRows.push({
              fname: row.firstName,
              lname: row.lastName,
              ci: row.legalId,
              status: row.available ? "Activo" : "Inactivo",
              details: <Link to={`/editar-instructor/${row.id}`}>Editar</Link>,
              key: row.id,
            })   
          }
        )
        setRows(dataRows)
      }
      
        getInstructors();
      }, [instructors])

   
    
    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title='Salas' breadcrumb={['Inicio > ', `Gestion de instructores`]}/>
        <div className='box mb-15'>
          <ReserveInnerTitle title={`LISTA DE INSTRUCTORES`} subtitle={`A continuación un listado de instructores`}/>
            {!loadingInstructors && <Link to="/crear-instructor"><Button className="button turquoise" on>Crear instructor</Button></Link>}
            <Table loading={loadingInstructors} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={rows} pagination={false}/>
            {/* {console.log('instructors', instructors.length)} */}
            {!loadingInstructors && instructors.length !== instructorsCount && <Button className="button turquoise outline center-btn mt-20" onClick={() => showMoreInstructors()}>Mostrar más</Button>}
          <ReserveInnerFooter back={back} table/>
        </div>
      </ReserveWrapper>
    )
}

export default InstructorList;
