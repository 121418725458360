import request from "./request";

export const getShedules = async ({ dayId, limit = 5, page = 0 }) => {
  const url = `/schedules/${dayId}/${limit}/${limit * page}`;
  try {
    const response = await request("GET", url);
    if (response.status === 200) {
      const data = await response.json();
      console.log('data.schedules desde funcion', data.schedules)
      return data.schedules;
    }
  } catch (error) {
    throw Error(error);
  }
};
