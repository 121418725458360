import React from 'react';
import {Button} from 'antd';
import './ReserveInnerFooter.scss';

function ReserveInnerFooter(props) {
    return (
        <div className="reserve-inner-footer">
          {props.reserveHistory && <p className="reserve-back-button" onClick={props.back}>Cerrar</p>}
          {!props.reserveHistory && <p className="reserve-back-button" onClick={props.back}>Volver al paso anterior</p>}
           {props.detailReserve && props.status !== process.env.REACT_APP_CANCELLED_RESERVATION &&  props.status !== process.env.REACT_APP_USED_RESERVATION && // Estos botones aparecen en una reserva individual 
            <div className="container-btn-edit">
              {props.alreadyPassed === false && <Button className="button purple outline" onClick={() => props.setShowModalCancel(true)}>CANCELAR RESERVA</Button>}
              {props.alreadyPassed === true && <Button className="button purple" onClick={() => props.setShowModalQualify(true)}>CALIFICAR RESERVA</Button>}
            </div>
           }
        </div>
    )
}
export default ReserveInnerFooter;
