import { useState, useEffect } from 'react'
import { getAdministrators } from '../services/getAdministrators'

const INITIAL_PAGE = 0

export const useAdministrators = () => {
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [users, setUsers] = useState([]) 
  const [usersCount, setUsersCount] = useState(0) 
  const [page, setPage] = useState(INITIAL_PAGE) 
  const [errorUsers, setError] = useState(null) 
  
  useEffect(() => {
    // if(page === INITIAL_PAGE) return 
    setLoadingUsers(true)
    getAdministrators({page: page}).then(data => {
      setUsers(prevInstructors => prevInstructors.concat(data.rows)) 
      setUsersCount(data.count) 
      setLoadingUsers(false) 
    })
    .catch(err => {
      setError(err)
    }) 
  }, [page])

  return { loadingUsers, users, usersCount, setPage, errorUsers }
}
