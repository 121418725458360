import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ModalBox from '../../Molecules/Modal/Modal';
import { messageCatchRequest } from '../../../services/helpers';

const UpdatePasswordForm = (props) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [successUpdatePassword, setSuccessUpdatePassword] = useState(false);
    const [successUpdateMessagePassword, setSuccessUpdateMessagePassword] = useState('');
    const [ErrorUpdateMessagePassword, setErrorUpdateMessagePassword] = useState('');
    const [errorUpdatePassword, setErrorUpdatePassword] = useState(false);

    const onFinish = async (e) => { 
      setButtonLoading(true);

      // console.log(e)
      const url = `${process.env.REACT_APP_API_URL}/update-password`
      const config = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization':  `BEARER ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          "password": e.password.trim()
        })
      }
      try {
        const response = await fetch(url, config);
        const data = await response.json();
        // console.log('response', response)
        // console.log('data', data)
        
        if(response.status === 200) {
          setButtonLoading(false);
          setSuccessUpdatePassword(true);
          setSuccessUpdateMessagePassword(data.message)
        } else {
          setButtonLoading(false);
          setErrorUpdatePassword(true);
          setErrorUpdateMessagePassword(data.message)
        }
      } catch (error) {
        setButtonLoading(false);
        setErrorUpdatePassword(true);
        setErrorUpdateMessagePassword(messageCatchRequest)
      }
    }

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Datos: " breadcrumb={['Inicio > ', 'Cambiar contraseña']}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title='CAMBIA TU CONTRASEÑA' subtitle='Complete el formulario para actualizar su contraseña '/>
            <Form onFinish={(e) => onFinish(e)}  className="create-admin--form">
                <div>
                  <p className='title-small-blue mb-5'>Nueva contraseña: </p>
                  <Form.Item name='password' hasFeedback rules={[{whitespace: true, required: true, message: 'Campo obligatorio' }, {validator: (_, value) => /\S{8,}/.test(value) ? Promise.resolve() : Promise.reject('Minimo 8 catacteres')}]}>
                    <Input.Password placeholder="Nueva contraseña" className='input' />
                  </Form.Item>
                </div>

                <div>
                  <p className='title-small-blue mb-5'>Confirma contraseña: </p>
                  <Form.Item name='confirmed-password' hasFeedback dependencies={['password']} rules={[{whitespace: true, required: true, message: 'Campo obligatorio'}, ({getFieldValue}) => ({validator(_,value) {if (!value || !getFieldValue('password') || getFieldValue('password').trim() === value.trim()) {return Promise.resolve();} return Promise.reject('Las contraseñas no coinciden')}})/*, {validator: (_, value) => value === 'hola' ? Promise.resolve() : Promise.reject('formato malo, validar formato con regex aqui')}*/]}>
                    <Input.Password placeholder="Confirma contraseña" className='input'/>
                  </Form.Item>
                </div>

              <div className="create-admin--btn mt-20">
                <Button htmlType="submit" className="button purple" loading={buttonLoading}>
                  Cambiar Contraseña
                </Button>
              </div>
            </Form>
            {successUpdatePassword && <ModalBox success password setShowModal={setSuccessUpdatePassword} content={successUpdateMessagePassword}/>}
            {errorUpdatePassword && <ModalBox error setShowModal={setErrorUpdatePassword} content={ErrorUpdateMessagePassword}/>}
        </div>
      </ReserveWrapper>
    )
}
export default UpdatePasswordForm;
