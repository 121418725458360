import React, { useState } from 'react'
import ReserveFooter from '../../Organisms/ReserveFooter/ReserveFooter';
import './Login.scss';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logoLogin from '../../../assets/logo-vaac-blue.svg'
import ReCAPTCHA from "react-google-recaptcha";
// import { saveUser, saveNotifications } from '../../../redux/actionsCreators';
import { saveUser } from '../../../redux/actionsCreators';
import store from '../../../redux/store';
import { messageCatchRequest } from '../../../services/helpers';

const Login = (props) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const [validRecaptcha, setValidRecaptcha] = useState(false)
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorLoginMessage, setErrorLoginMessage] = useState('');

  const getTokenRecaptcha = (token) => {
    if(token) {
      setValidRecaptcha(true);
      setErrorCaptcha(false);
      // console.log("Captcha token:", token);
    } else {
      setValidRecaptcha(false);
      // console.log("Captcha token:", token);
    }  
  }

  const onFinish = async (e) => {
    const url = `${process.env.REACT_APP_API_URL}/login`
    if(!validRecaptcha) {
      setErrorCaptcha(true);
      return;
    } else {
      setButtonLoading(true);
      setErrorCaptcha(false);
      const settings = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": e.email.trim().toLowerCase(),
          "password": e.password.trim(),
        })
      };
      try {
        const response = await fetch(url, settings);
        const data = await response.json();
        // console.log('response', response)
        // console.log('data', data)
        if(response.status === 200) {
          setErrorLogin(false)
          localStorage.setItem('token', data.token);
          store.dispatch(saveUser(data.user))
          // store.dispatch(saveNotifications({page: 0}))
          props.history.push('/perfil');
        } else {
          setErrorLoginMessage(data.message)
          setButtonLoading(false);
          setErrorLogin(true)
        }
      } catch (error) {
        setErrorLoginMessage(messageCatchRequest)
        setButtonLoading(false);
        setErrorLogin(true)
      }
    } 
  }

  return (
    <div className="reserve-login-admin">
      <div className="background-vaac">
        <div className="capa"></div>
      </div>
      <div className="capa-gris"></div>
      <header  className="reserve-login-admin--header">
        <img src={logoLogin} alt="Logo Vaac"/>
      </header>
      <div className="reserve-login-admin--form">
        <div>
          <h3 className="reserve-login-admin--title mb-20">Sistema de reservas</h3>
          {/* <p className="reserve-login-admin--subtitle">Por favor, complete los siguientes campos para iniciar sesión:</p> */}
          <Form onFinish={(e) => onFinish(e)} >
            { errorLogin && <p className="mb-15 message-error center-text">{errorLoginMessage}</p> }
            <Form.Item label="Correo electrónico:" name="email" rules={[{required: true, message: 'Campo obligatorio' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Contraseña:" name="password" rules={[{ required: true, message: 'Campo obligatorio' }]}>
              <Input.Password />
            </Form.Item>

            <div className="recaptcha-login-container">
              <div className="recaptcha-login">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={getTokenRecaptcha}
                />
              </div>
              <div className="recaptcha-login-small">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={getTokenRecaptcha}
                size="compact"
              />
              </div>
            </div>

            <div>
              { errorCaptcha && <p className="mt-5 message-error">Por favor debe validar el recaptcha</p> }
            </div>        
            <Form.Item className="mt-15">
              <Button htmlType="submit" icon={<LockOutlined />} loading={buttonLoading} className="button blue center-btn btn-login">
                Ingresar
              </Button>
            </Form.Item>

            <div className="reserve-login-admin--links">
              <Link to="/cambiar-contraseña"><p className="mb-5 link">¿Olvidó su contraseña?, click aquí</p></Link>
            </div>
          </Form>
        </div>
      </div>
      <div className="reserve-login-admin--footer">
        <ReserveFooter />
      </div>
    </div>
  )
}

export default Login;
