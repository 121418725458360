import React from 'react'

const ErrorComponent = ({ message, customClass }) => {
  return (
    <div>
      <h1 className={`title-upper-red center-btn center-text ${customClass && customClass}`}>{message}</h1>
    </div>
  )
}

export default ErrorComponent
