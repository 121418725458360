import request from "./request"

  // Obtener una actvidad
export const getActivity = async (id) => {
    const url = `/activity/${id}`
    try {
      const response = await request('GET', url)
      if(response.status === 200) {
        const data = await response.json()
        return data.activity
      }else {
        return []
      }
    } catch (error) {
      return error
    }
  }
