import request from "./request"

export const getDays = async () => {
  const url = `/days`
  let daysArr = []
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      data.days.forEach(day =>
        daysArr.push(day)
      )
    }
    return daysArr
  } catch (error) {
    throw Error(error)
  }
}
