export function getSportId(sport) {
	const sports = {
		"tenis": 1,
		"indoor-cycling": 2,
		"otros": 3,
		"squash": 4
	}
	return sports[sport]
}
export function getDayString(id) {
	const days = {
		1: "Lunes",
		2: "Martes",
		3: "Miercoles",
		4: "Jueves",
		5: "Viernes",
		6: "Sabado",
		7: "Domingo",
	}
	return days[id]
}

export function getActivityId(activity) {
	const activities = {
		"single": 1,
		"double": 2,
		"spinning": 3,
		"spin-power": 4,
		"xtreme-bike": 5,
		"trx": 6,
		"zona-cardio": 7,
		"zona-heavy-weight": 8,
		"yoga": 9,
		"bailoterapia": 10,
		"glutabs": 11,
		"yin-yoga": 12,
		"pilates": 13,
		"baile": 14,
		"funcional": 15,
		"funcional-dance": 16,
		"stretching": 17,
		"synrgy": 18,
		"cardio-muay-thay": 19,
		"ballet-fitness": 20,
		"fit-combat": 21
	}
	return activities[activity]
}
export function getActivityName({activityId}) {
	const activities = {
		1: "single",
		2: "double",
		3: "spinning",
		4: "spin-power",
		5: "xtreme-bike",
		6: "trx",
		7: "zona-cardio",
		8: "zona-heavy-weight",
		9: "yoga",
		10: "bailoterapia",
		11: "glutabs",
		12: "yin-yoga",
		13: "pilates",
		14: "baile",
		15: "funcional",
		16: "funcional-dance",
		17: "stretching",
		18: "synrgy",
		19: "cardio-muay-thay",
		20: "ballet-fitness",
		21: "fit-combat"
	}
  
	return activities[activityId]
}

export const getActivityType = ({sportId}) => {
  let result =
        sportId === 4 ? {name: 'Squash', shortname: 'squash', id: 4}
        :
        sportId === 1 ? {name: 'Tenis', shortname: 'tenis', id: 1} 
        : 
        sportId === 3 ? {name: 'Otras Salas', shortname: 'others', id: 3} 
        :
        sportId === 2 && {name: 'Indoor Cycling', shortname: 'cycling', id: 2};
  return result
}

export const messageCatchRequest = "Ha ocurrido un error inesperado, por favor intenta de nuevo o más tarde"
