import request from "./request"

export const checkToken = async () => {
  const response = await request("POST", "/check-login-token", {})
  const data = await response.json()
  if (response.status === 200) {
    return {token: data.token, data: data.user}
  } else {
    return null
  }
}
