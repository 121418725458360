import React, { useState } from "react";
import "./ReserveHeader.scss";
import IconBell from "../../Atoms/IconBell/IconBell";
import logoDashboard from "../../../assets/logo-vaac-white.svg";
import ToggleMenu from "../../Atoms/ToggleMenu/ToggleMenu";
import { Link } from "react-router-dom";
import DrawerNotification from "../DrawerNotification/DrawerNotification";
// import { useDispatch } from "react-redux";

function ReserveHeader(props) {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(null);

  // const dispatch = useDispatch();

  const onClose = () => {
    setVisibleDrawer(false);
  };

  return (
    <div className="main-reserve-header">
      <div className="reserve-header">
        <div className="reserve-header--logo">
          <Link to="/datos">
            <img src={logoDashboard} alt="Logo VAAC" />
          </Link>
        </div>
        <div className="reserve-header--portal">
          {!props.simple && 
            <IconBell
              setVisibleDrawer={setVisibleDrawer}
              notificationsCount={notificationsCount}
            />
          }
          {!props.simple && <ToggleMenu />}
        </div>
      </div>
      {visibleDrawer && (
        <DrawerNotification
          visible={visibleDrawer}
          onClose={onClose}
          setNotificationsCount={setNotificationsCount}
          setVisibleDrawer={setVisibleDrawer}
          // loaderDrawer={loaderDrawer}
        />
      )}
    </div>
  );
}
export default ReserveHeader;
