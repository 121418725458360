import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import CreateAdmin from './components/Pages/CreateAdmin/CreateAdmin';
// import CreateReserve from './components/Pages/CreateReserve/CreateReserve';
import Login from './components/Pages/Login/Login';
import ChangePassword from './components/Pages/ChangePassword/ChangePassword';
import NotFound from './components/Pages/NotFound/NotFound';
import ProfileAdmin from './components/Pages/ProfileAdmin/ProfileAdmin';
import UpdatePasswordForm from './components/Pages/UpdatePasswordForm/UpdatePasswordForm';
import DetailSala from './components/Pages/DetailSala/DetailSala';
import Protected from './components/Routes/Protected';
import Public from './components/Routes/Public';
// import ReserveHome from './components/Pages/ReserveHome/ReserveHome';
// import ReserveList from './components/Pages/ReserveList/ReserveList';
import ReserveList2 from './components/Pages/ReserveList/ReserveList2';
import SalasList from './components/Pages/SalasList/SalasList';
import AdminList from './components/Pages/AdminList/AdminList';
// import TimeList from './components/Pages/TimeList/TimeList';
import DetailReserve from './components/Pages/DetailReserve/DetailReserve';
import EditMap from './components/Pages/EditMap/EditMap';
import FormInstructor from './components/Pages/FormInstructor/FormInstructor';
import InstructorList from './components/Pages/InstructorList/InstructorList';
import ShedulesList from './components/Pages/ShedulesList/ShedulesList';
import FormHorario from './components/Pages/FormHorario/FormHorario';
import FormRelationSchedule from './components/Pages/FormRelationSchedule/FormRelationSchedule';
import VerifyAcount from './components/Pages/VerifyAcount/VerifyAcount';
import NewPassword from './components/Pages/NewPassword/NewPassword';
import SportList from './components/Pages/SportList/SportList';
import ActivitiesList from './components/Pages/ActivitiesList/ActivitiesList';
import ActivityDetails from './components/Pages/ActivityDetails/ActivityDetails';
import ConsultaSocio from './components/Pages/ConsultaSocio/ConsultaSocio';
// import ConsultaSocioDetails from './components/Pages/ConsultaSocioDetails/ConsultaSocioDetails';
import ConsultaSocioTabs from './components/Pages/ConsultaSocioTabs/ConsultaSocioTabs';
import SearchReserveSocioDetails from './components/Pages/SearchReserveSocioDetails/SearchReserveSocioDetails';

const App = () => (
  <Router>
    <Switch>  
      <Protected path="/perfil" exact component={ProfileAdmin} />
      <Protected path="/datos" exact component={ProfileAdmin} />
      <Protected path="/detalle/administrador/:id" exact component={ProfileAdmin} />
      <Protected path="/datos/cambiar-contraseña" exact component={UpdatePasswordForm} />
      <Protected path="/crear-administrador" exact component={CreateAdmin} />
      <Protected path="/editar-administrador/:id" exact component={CreateAdmin} />
      <Protected path="/crear-instructor" exact component={FormInstructor} />
      <Protected path="/crear-horario" exact component={FormHorario} />
      <Protected path="/crear-relacion/horario/:id" exact component={FormRelationSchedule} />
      <Protected path="/editar-relacion/horario/:scheduleId/:relationId" exact component={FormRelationSchedule} />
      <Protected path="/editar-instructor/:id" exact component={FormInstructor} />
      <Protected path="/editar-horario/:id" exact component={FormHorario} />
      <Public path="/cambiar-contraseña" exact component={ChangePassword} />
      <Public path="/" exact component={Login} />
      <Public path="/verificar/:token" exact component={VerifyAcount} />
      <Public path="/new-password/:token" exact component={NewPassword} />
      <Protected path='/administradores' exact component={AdminList} /> {/*Listado de admins*/}
      <Protected path='/instructores' exact component={InstructorList} /> {/*Listado de Instructores*/}
      <Protected path='/horarios' exact component={ShedulesList} /> {/*Listado de horarios*/}
      {/*<Protected path={['/reservas', '/salas']} exact component={ReserveHome} />*/} {/*Seleccion de actividad*/}
      {/* <Protected path='/reservas/:activity' exact component={ReserveList} />  */}{/*Listado de reservas de alguna actividad*/}
      <Protected path='/reservas/lista' exact component={ReserveList2} /> {/*Listado de reservas de alguna actividad*/}
      <Protected path='/reserva/socio' exact component={SearchReserveSocioDetails} /> {/*Detalle de uan resrerva individual de un socio*/}
      <Protected path='/reservas/detalle/:id' exact component={DetailReserve} /> {/*Detalles de alguna reserva*/}
      <Protected path='/salas/:activity' exact component={SalasList} /> {/*Listado de salas de alguna actividad*/}
      <Protected path='/deportes' exact component={SportList} /> {/*Listado de deportes*/}
      <Protected path='/actividades' exact component={ActivitiesList} /> {/*Listado de actividades*/}
      <Protected path='/actividades/:id' exact component={ActivityDetails} />{/*Detalle de una actividad */}
      <Protected path='/salas/detalle/:id' exact component={DetailSala} /> {/*Detalles de alguna sala, falta diseño*/}
      {/* <Protected path='/salas/detalle/horarios/:id' exact component={TimeList} />  */} {/*Listado de horarios de alguna sala*/}
      <Protected path='/salas/:sport/mapa/:roomId' exact component={EditMap} />
      <Protected path='/consulta' exact component={ConsultaSocio} />
      {/* <Protected path='/consulta/:action/:uniqueId' exact component={ConsultaSocioDetails} /> */}
      <Protected path='/consulta/:action/:uniqueId' exact component={ConsultaSocioTabs} />
      <Route component={NotFound}/>
    </Switch>
  </Router>
)

export default App;
