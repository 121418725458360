import React, {useState, useEffect, useRef} from 'react';
import { Button, Form, Select, Table } from 'antd';
import {Link} from 'react-router-dom';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import { useDays } from '../../../customHooks/useDays';
import { useDispatch, useSelector } from 'react-redux';
import { saveDay } from '../../../redux/actionsCreators';
import { getDayString } from '../../../services/helpers';
import useSchedules from '../../../customHooks/useSchedules';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';

const columns = [
  {
      title: 'Bloque',
      dataIndex: 'scheduleBlock',
      key: 'scheduleBlock',
  },
  {
      title: 'Inicia',
      dataIndex: 'openingTime',
      key: 'openingTime'
  },
  {
      title: 'Finaliza',
      dataIndex: 'closingTime',
      key: 'closingTime',
  },
  {
      title: 'Acción',
      dataIndex: 'details',
      key: 'details',
      fixed: 'right',
      width: 185
  }
];

function ShedulesList(props) {
  const formRef = useRef()
  const dispatch = useDispatch()
  const dayHistory = useSelector(state => state.dayReducer)
  const [dayId, setDayId] = useState(dayHistory ? dayHistory.id : 1)
  const { schedules, schedulesCount, loadingSchedules, loadingNextSchedules, setPage } = useSchedules({ dayId })
  const { days } = useDays();

  useEffect(() => {
    formRef.current.setFieldsValue({ day: dayHistory ? dayHistory.id : 1 });
  }, [dayHistory])

    // Cambiar dia
    const changeDay = (value) => {
      setPage(0)
      setDayId(value)
      formRef.current.setFieldsValue({day: value})
      dispatch(saveDay({
        id: value,
        slug: getDayString(value)
      }))
    }

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title='Salas' breadcrumb={['Inicio > ', `Gestion de horarios`]}/>
        <div className='box mb-15'>
          <ReserveInnerTitle title={`LISTA DE HORARIOS`} subtitle={`A continuación un listado de los horaios`}/>
            {<Link to="/crear-horario"><Button className="button turquoise" on>Crear horario</Button></Link>}
              <Form ref={formRef} initialValues={{
                  day: dayHistory ? dayHistory.id : 1
                }}
              >
              <Form.Item name='day' className='input-filter inline-block mt-15'>
                <Select placeholder='Día' onChange={value => changeDay(value)}/*onChange={value => setSala(value)}*/>
                  {
                    days.map(day => 
                      <Select.Option key={day.id} value={day.id}>{day.name}</Select.Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Form>
            <Table loading={loadingSchedules} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={schedules} pagination={false}/>
            {loadingNextSchedules ? <SpinnerLoader small /> : schedules.length !== schedulesCount && <Button className="button turquoise outline center-btn mt-20" onClick={() =>  setPage(prevPage => prevPage + 1)}>Mostrar más</Button>}
          <ReserveInnerFooter back={() => {props.history.goBack()}} table/>
        </div>
      </ReserveWrapper>
    )
}

export default ShedulesList;
