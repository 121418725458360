import React, {useState, useEffect} from 'react';
import {Table, Button} from 'antd';
import {Link} from 'react-router-dom';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ErrorComponent from '../../Atoms/ErrorComponent/ErrorComponent';
import { useAdministrators } from '../../../customHooks/useAdministrators';
import { connect } from 'react-redux';

function AdminList(props) {
    const [rows, setRows] = useState([]);

    const { loadingUsers, users, setPage, usersCount, errorUsers } = useAdministrators()

    // paginacion
    const showMoreUsers = () => setPage(prevPage => prevPage + 1)

    const back = () => {
        props.history.goBack()
    }

    const columns = [{
        title: 'Nombre',
        dataIndex: 'fname',
        key: 'fname'
    },
    // INFORMACIÓN DE RESERVACIÓN:
    {
        title: 'Correo',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Rol',
        dataIndex: 'role',
        key: 'role',
    },
    {
        title: 'Detalles',
        dataIndex: 'details',
        key: 'details',
        fixed: 'right',
        width: 115
    }];

    useEffect(()=>{
    if(!props.userInfo.super)  return props.history.push('/datos')
    
    // Funcion para obtener administradores
     const getUsers = async () => {
       let dataRowsUser = []
       users.forEach(row => 
         {
          dataRowsUser.push({
            fname: `${row.privatePerson.firstName} ${row.privatePerson.lastName}`,
            // lname: row.privatePerson.lastName,
            email: row.email,
            role: row.privateUserRoleId === 1 ? 'Administrador' : 'Colaborador',
            details: <Link to={`/detalle/administrador/${row.id}`}>Ver detalle</Link>,
            key: row.id
           })   
         }
       )
       setRows(dataRowsUser)
     }
     
     getUsers();
     }, [props.history, props.userInfo.super, users])
    
    return (
        <ReserveWrapper centerX>
            <ReserveMainTitle title='Salas' breadcrumb={['Inicio > ', `Gestion de administradores`]}/>
            <div className='box mb-20'>
                <ReserveInnerTitle title={`LISTA DE ADMINISTRADORES`} subtitle={`A continuación un listado de administradores`}/>
                { errorUsers 
                  ? <ErrorComponent message={"Error al cargar administradores"}/> 
                  : <>
                    <Table loading={loadingUsers} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={rows} pagination={false}/>
                    {!loadingUsers && users.length !== usersCount && <Button onClick={showMoreUsers} className="button turquoise outline center-btn mt-20">Mostrar más</Button>}
                    </>
                }
                <ReserveInnerFooter back={back} table/>
            </div>
        </ReserveWrapper>
    )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(AdminList);
