import React, { useState, useEffect } from 'react';
import './ActivityDetails.scss';
import { useParams } from 'react-router-dom';
import { useActivity } from '../../../customHooks/useActivity';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import { Button, Form, Input } from 'antd';
import request from '../../../services/request';
import { messageCatchRequest } from '../../../services/helpers';
import Modal from '../../Molecules/Modal/Modal'
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';

const ActivityDetails = ({history}) => {
  const { id } = useParams();
  const { loadingActivity, activity, setLoadingnViewActivityDetails } = useActivity({activityId : id})
  const [sportIcon, setSportIcon] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonLoadingStatusActivity, setButtonLoadingStatusActivity] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [message, setMessage] = useState(false);
  const [showModalStatusActivity, setShowModalStatusActivity] = useState(false);

  const back = () => { history.goBack() }

  useEffect(() => {
    if(activity.sportId === 1) {
      setSportIcon('tenis')
    } else if(activity.sportId === 2) {
      setSportIcon('cycling')
    }else if(activity.sportId === 3) {
      setSportIcon('others')
    }else {
      setSportIcon('squash')
    }
  }, [activity.sportId])


  // Habilitar/Desabilitar una actividad
  const handleStatusActivity = async ({reason}) => {
    setButtonLoadingStatusActivity(true)
    const url = `/handle-activity-availability/${id}`
    const body = { reason, available: activity.available ? false : true  }
    try {
      const response = await request('PATCH', url, body)
      const data = await response.json()
      if(response.status === 200) {
        setLoadingnViewActivityDetails(true)
        setModalSuccess(true);
        setButtonLoadingStatusActivity(false);
        setMessage(data.message)
        setShowModalStatusActivity(false)
      } else {
        setModalError(true);
        setButtonLoadingStatusActivity(false);
        setMessage(data.message)
      }
    }
    catch (error) {
      // console.log(error)
      setModalError(true);
      setMessage(messageCatchRequest)
      setButtonLoadingStatusActivity(false);
    }
  }

  // Cambiar la cantida de cupos de una actividad
  const onFinishEditCoupon = async ({maxCoupons}) => {
    setButtonLoading(true)
    const url = `/activity-max-coupons/${id}`
    const body = { maxCoupons }
    try {
      const response = await request('PATCH', url, body)
      const data = await response.json()
      if(response.status === 200) {
        setModalSuccess(true);
        setButtonLoading(false);
        setMessage(data.message)
        setLoadingnViewActivityDetails(true)
      } else {
        setModalError(true);
        setButtonLoading(false);
        setMessage(data.message)
      }
    }
    catch (error) {
      // console.log(error)
      setModalError(true);
      setMessage(messageCatchRequest)
      setButtonLoading(false);
    }
  }

  return (
    <>
      {/* {console.log('activity', activity)} */}
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis datos: " breadcrumb={['Inicio']}/>
        { 
          loadingActivity ? <SpinnerLoader small /> :
          <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title='DETALLE DE ACTIVIDAD' subtitle='A continuación detalle de una actividad.'/>
          <ReserveInnerTitle activity={sportIcon} title={activity.name}/>
          <div className='main-detail-activity'>
           <div>
            <p className="title-upper-red no-space">Detalles: </p>
            <div className="main-detail-activity-status">
              <div className="mr-30">
                <p className="title-small-blue no-space">Estatus: </p>
                <div className="ml-10">{activity.available ? 'Habilitada' : 'Deshabilitada'}</div>
              </div>
              <Button className="button purple mt-15" onClick={() => setShowModalStatusActivity(true)}>{activity.available ? 'Deshabilitar' : 'Habilitar'}</Button>
            </div>
           </div>
            
           <div className="main-detail-activity-cupos mt-20">
            <p className="title-upper-red no-space">Cupos: </p>
            <div className="">
              <Form initialValues={{ maxCoupons: activity.maxCoupons }} onFinish={(e) => onFinishEditCoupon(e)}>
                <Form.Item name="maxCoupons" rules={[{required: true, message: 'Campo obligatorio' }]}>
                  <Input className="input" />
                </Form.Item>
                <Button htmlType="submit" className="button purple" loading={buttonLoading}>
                  Editar cupos
                </Button>
              </Form>
            </div>
           </div>
          </div>
          <ReserveInnerFooter back={back} table/>
         </div>
        }
        {modalSuccess && <Modal success setShowModal={setModalSuccess} content={message} />}
        {modalError && <Modal error setShowModal={setModalError} content={message} />}
        {showModalStatusActivity && <ReserveSuccessModal sportStatusAction={activity.available} buttonLoadingStatusActivity={buttonLoadingStatusActivity} title={`${activity.available ? 'DESHABILITAR' : 'HABILITAR'} ACTIVIDAD`} message={`Usted está por ${activity.available ? 'deshabilitar' : 'habilitar'} esta actividad ¿Estás seguro?`}  handleCancel={() => setShowModalStatusActivity(false)} handleStatusActivity={handleStatusActivity} setShowModalStatusActivity={setShowModalStatusActivity}  />}
      </ReserveWrapper>
    </>
  )
}

export default ActivityDetails

// 3) patch /handle-activity-availability/:activityId, le pasas por parametro el id de la actividad, y en el body le envias available (true para habilitar, false para deshabilitar) y le envias reason (razon de cancelacion)
