import React, { useState, useRef, useEffect} from 'react';
import {Table, Button, Tabs, Select, Form} from 'antd';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import { saveTable } from '../../../redux/actionsCreators';
import { useRooms } from '../../../customHooks/useRooms';
import { useActivities } from '../../../customHooks/useActivities';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityType } from '../../../services/helpers';
import useReservations from '../../../customHooks/useReservations';

const columns = [{
  title: 'N° Reserva',
  dataIndex: 'id',
  key: 'id',
  fixed: 'left',
  width: 110
},
{
  title: 'Fecha',
  dataIndex: 'date',
  key: 'date',
},
{
  title: 'Hora',
  dataIndex: 'time',
  key: 'time',
},
{
  title: 'Actividad',
  dataIndex: 'activity',
  key: 'activity',
},
{
  title: 'Detalles',
  dataIndex: 'details',
  key: 'details',
  fixed: 'right',
  width: 100
}];


const ReserveList2 = ()  => {
  const formRef = useRef();
  const dispatch = useDispatch()
  const tableHistory = useSelector(state => state.tableReducer)

  useEffect(() => {
    formRef.current.setFieldsValue({sport: tableHistory ? tableHistory.sport : 1})
    formRef.current.setFieldsValue({sala: tableHistory ? tableHistory.sala : null})
    formRef.current.setFieldsValue({especialidad: tableHistory ? tableHistory.especialidad : null})
  }, [tableHistory])

  // Ids
  const [sportId, setSportId] = useState(tableHistory ? tableHistory.sport : 1)
  const [activityId, setActivityId] = useState(tableHistory ? tableHistory.especialidad : null)
  const [roomId, setRoomId] = useState(tableHistory ? tableHistory.sala : null)
  const [currentTabs, setCurrentTabs] = useState(tableHistory ? tableHistory.tab : 'Activa')
  
  // Custom Hooks
  const { loadingRooms, rooms} = useRooms({sportId, activityId, reservations: true }) // Hooks que retorna las salas y el loading
  const { loadingActivities, activities } = useActivities({sport: sportId, reservations: true}) // Hooks que retorna las actividades y el loading
  const activityType = getActivityType({sportId})
  const { reserveList, reserveCount, errorGetReservations, setPage, reserveCountRow, loadingReservations, loadingNextPageReservations } = useReservations({status: currentTabs, sportId, activityId, roomId})

  // Cambiar deporte
  const changeSport = (value) => {
    dispatch(saveTable({
      sport: value,
      tab: currentTabs,
      especialidad: null,
      sala: null
    }))
    setPage(0)
    formRef.current.setFieldsValue({sala: null})
    formRef.current.setFieldsValue({especialidad: null})
    setSportId(value)
    setActivityId(null)
    setRoomId(null)
    if(value === 1 || value === 4) {
      setActivityId(null)
    }
  }

  // Cambiar Salas
  const changeRooms = (value) => {
    dispatch(saveTable({
      sport: sportId,
      tab: currentTabs,
      especialidad: activityId,
      sala: value
    }))
    setRoomId(value)
    setPage(0)
    formRef.current.setFieldsValue({sala: value})
    formRef.current.setFieldsValue({especialidad: activityId})
  }

  // Cambiar Actividades
  const changeActivities = (value) => {
    dispatch(saveTable({
      sport: sportId,
      tab: currentTabs,
      especialidad: value,
      sala: null
    }))
    setPage(0)
    setActivityId(value)
    formRef.current.setFieldsValue({sala: null})
    formRef.current.setFieldsValue({especialidad: value})
    setRoomId(null)
  }
  
  // Cambiar Status
  const changeStatus = (value) => {
    dispatch(saveTable({
      sport: sportId,
      tab: value,
      especialidad: activityId,
      sala: null
    }))
    setPage(0)
    setCurrentTabs(value)
  }

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title='Salas' breadcrumb={['Inicio > ', `Gestion de reservas`]}/>
          <div className='box mb-20'>
            <ReserveInnerTitle activity={errorGetReservations ? null : activityType.shortname} title={errorGetReservations ? `ERROR` : `RESERVAS DE ${activityType.name}`} subtitle={errorGetReservations ? `A ocurrido un error por favor intentelo de nuevo` : `A continuación un listado de reservas de ${activityType.name}`} />
            {
             errorGetReservations ? <h2 className="title-upper-red center-text">Error al obtener las reservaciones</h2>
            : 
            <>
            <Form ref={formRef} initialValues={{sport: tableHistory ? tableHistory.sport: 1}}>
                <Form.Item label='Actividad' name='sport' className='input-filter mr-20 mb-40'>
                  <Select onChange={value => changeSport(value)}>
                    <Select.Option value={1}>Tenis</Select.Option>
                    <Select.Option value={2}>Indoor Cycling</Select.Option>
                    <Select.Option value={3}>Otras Salas</Select.Option>
                    <Select.Option value={4}>Squash</Select.Option>
                  </Select>
                </Form.Item>
                {
                  (sportId !== 1 && sportId !== 4) && 
                  <Form.Item label='Especialidad' name='especialidad' className='input-filter mr-20' value={activityId}>
                   {loadingActivities ? <SpinnerLoader small /> :
                   <Select placeholder='Seleccionar'  onChange={value => changeActivities(value)}>
                      {
                        activities.map(act => 
                          <Select.Option key={act.id} value={act.id}>{act.name}</Select.Option>
                        )
                      }
                    </Select>}
                  </Form.Item>
                }
                {
                (sportId !== 3) &&
                <Form.Item label='Sala' name='sala' className='input-filter' value={roomId} >
                 {loadingRooms ? <SpinnerLoader small /> :
                  <Select placeholder='Seleccionar' onChange={value => changeRooms(value)}>
                    {
                      rooms.map(room => 
                        <Select.Option key={room.id} value={room.room.id}>{room.room.name}</Select.Option>
                      )
                    }
                  </Select>}
                 </Form.Item>
                }
              </Form>
              <Tabs defaultActiveKey={currentTabs} centered onChange={tab => changeStatus(tab)}>
                <Tabs.TabPane tab="Reservas activas" key="Activa">
                  <Table loading={loadingReservations} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={reserveList} pagination={false}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Reservas pendientes" key="por-confirmar">
                  <Table loading={loadingReservations} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={reserveList} pagination={false}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Historial de reservas" key="Usada">
                  <Table loading={loadingReservations} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={reserveList} pagination={false}/>
                </Tabs.TabPane>
              </Tabs>
              </>
          }
          {((reserveList.length !== reserveCount) && (reserveCountRow > 0)) && <Button loading={loadingNextPageReservations} className="button turquoise outline center-btn mt-20" onClick={() => setPage(prevPage => prevPage + 1)}>Mostrar más</Button>}
        </div>
      </ReserveWrapper>
    )
}


export default ReserveList2
