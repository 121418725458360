
import React, {useState} from 'react';
import { Button, Form, Select } from 'antd';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './ProfileAdmin.scss';
import { connect } from 'react-redux';
// import { CameraOutlined } from '@ant-design/icons';
// import imageCompression from 'browser-image-compression';
// import ModalBox from '../../Molecules/Modal/Modal';
// import store from '../../../redux/store';
// import { saveUser } from '../../../redux/actionCreators';
import { Link } from 'react-router-dom';
import { useCurrentAdmin } from '../../../customHooks/useCurrentAdmin';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import request from '../../../services/request';
import Modal from '../../Molecules/Modal/Modal';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import { messageCatchRequest } from '../../../services/helpers';


const ProfileAdmin = (props) => {
    // const [successUpdateImage, setSuccessUpdateImage] = useState(false);
    // const [errorUpdateImage, setErrorUpdateImage] = useState(false);
    const [buttonLoadingDelete, setButtonLoadingDelete] = useState(false);
    const [buttonLoadingRol, setButtonLoadingRol] = useState(false);
    const [buttonLoadingBlocked, setButtonLoadingBlocked] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [deleteSuccess, setDeleteSucess] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [blockedSuccess, setBlockedSuccess] = useState(false);
    const [blockedError, setBlockedError] = useState(false);
    const [rolSuccess, setRolSuccess] = useState(false);
    const [rolError, setRolError] = useState(false);
    const [rolMessageModal, setRolMessageModal] = useState('');
    const [desabledDeleteMessageModal, setDesabledDeleteMessageModal] = useState('');
    const [showFormEditRol, setShowFormEditRol] = useState(false);

    let isDetail = props.history.location.pathname.includes('/detalle');
    const back = () => props.history.push('/administradores');

    const { currentAdmin, loadingAdmin, setLoadingCurrentAdmin } = useCurrentAdmin({idAdmin: props.match.params.id})

      // Eliminar un admin
      const deleteAdmin = async () => {
        setButtonLoadingDelete(true)
        // console.log('Aqui borrar')
        const url = `/delete-user/${props.match.params.id}` 
        try {
          const response = await request('DELETE', url)
          const data = await response.json()
          // console.log('response', response)
          if(response.status === 200) {
            setDesabledDeleteMessageModal(data.message)
            setDeleteSucess(true);
            props.history.push('/administradores');
            setButtonLoadingDelete(false);
            setDeleteError(false)
           
          } else {
            setDesabledDeleteMessageModal(data.message)
            setDeleteError(true)
            setShowModalDelete(false)
            setButtonLoadingDelete(false);
          }
        } catch (error) {
          // console.log(error)
          setDesabledDeleteMessageModal(messageCatchRequest)
          setDeleteError(true)
          setShowModalDelete(false)
          setButtonLoadingDelete(false);
        }
      }

      // Bloquear/desbloquear admin
      const handleBlockedUser = async (blockedValue) => {
        setButtonLoadingBlocked(true)
        const url = `/handle-user-blocking/${props.match.params.id}` 
        const body = { block: blockedValue }
        try {
          const response = await request('PATCH', url, body)
          const data = await response.json()
          // console.log('response', response)
          if(response.status === 200) {
            setDesabledDeleteMessageModal(data.message)
            setBlockedSuccess(true)
            setBlockedError(false)
            setLoadingCurrentAdmin(true)
            setButtonLoadingBlocked(false)
          } else {
            setDesabledDeleteMessageModal(data.message)
            setBlockedError(true)
            setBlockedSuccess(false)
            setButtonLoadingBlocked(false)
          }
        } catch (error) {
          // console.log(error)
          setDesabledDeleteMessageModal(messageCatchRequest)
          setButtonLoadingBlocked(false)
          setBlockedError(true)
          setBlockedSuccess(false)
        }
      }


      // Cambiar rol del admin
    const changeRol = async (e) => {
      setButtonLoadingRol(true)
      try {
        const response = await request('PATCH', `/update-user-role/${props.match.params.id}`, e)
        const data = await response.json()
        // console.log(data)
        // console.log(data.message)
        if(response.status === 200) {
          setRolMessageModal(data.message)
          setButtonLoadingRol(false)
          setLoadingCurrentAdmin(true)
          setShowFormEditRol(false)
          setRolSuccess(true)
        } else {
          setRolMessageModal(data.message)
          setButtonLoadingRol(false)
          setRolError(true)
        }
      } catch (error) {
        setRolError(true)
        setRolMessageModal('A ocurrido un error inesperado, intentelo más tarde')
        setButtonLoadingRol(false)
      }
    }

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis datos: " breadcrumb={['Inicio > ', 'Mis datos']}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title='DATOS DEL ADMINISTRADOR' subtitle='A continuación una lista de información.'/>
          <div className='main-profile-partner'>
            <div className="main-profile-partner--parte-1">
              <div className="main-profile-partner--img-container">
                {
                  !isDetail ? <img src={props.userInfo.pictureUrl ? props.userInfo.pictureUrl : process.env.REACT_APP_DEFAULT_PHOTO_USER} alt={props.userInfo.privatePerson.firstName} />
                  : <img src={currentAdmin.pictureUrl || process.env.REACT_APP_DEFAULT_PHOTO_USER} alt="Admin detalle" />
                }
              </div>
            </div>
            <div className="main-profile-partner--parte-2 mb-25">
              <div className="mb-15">
              {
                isDetail && 
                loadingAdmin ? <SpinnerLoader small/> :
                <>
                <p className="no-space"><span className="title-small-blue">Titular: </span> {isDetail ? `${currentAdmin.privatePerson.firstName} ${currentAdmin.privatePerson.lastName}` : props.userInfo.privatePerson ? props.userInfo.privatePerson.firstName : 'Admin'} {props.userInfo.privatePerson && props.userInfo.privatePerson.lastName}</p>
                <p className="no-space"><span className="title-small-blue">CI: </span>C.I. {isDetail ? currentAdmin.privatePerson.legalId : props.userInfo.privatePerson ? props.userInfo.privatePerson.legalId : 'Admin'}</p>
                <p className="no-space"><span className="title-small-blue">Correo: </span>{isDetail ? currentAdmin.email : props.userInfo.email ? props.userInfo.email : 'desarrolloweb@ninweb.net'}</p>
                {isDetail && 
                <>
                <p className="toggle-rol" onClick={() => setShowFormEditRol(!showFormEditRol)}>Editar rol</p>
                  {showFormEditRol && <div className="container-change-role">
                    <Form onFinish={(e) => changeRol(e)}  className="form-change-role"
                      initialValues={{
                        privateUserRoleId: currentAdmin.privateUserRoleId,
                      }}
                    >
                      <Form.Item className="input-filter mt-15 mb-5" name="privateUserRoleId" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Select>
                          <Select.Option key={1} value={1}>Administrador</Select.Option>
                          <Select.Option key={2} value={2}>Colaborador</Select.Option>
                        </Select>
                      </Form.Item>
                      <Button htmlType="submit" loading={buttonLoadingRol} className="button purple pb-5">
                        Editar rol
                      </Button>
                    </Form>
                  </div>}
                  </>
                  }
                </>
              }  
              </div>
              {!isDetail && <div className="mb-15 container-button">
                <Link to="/datos/cambiar-contraseña"><Button className="button purple">Cambiar Contraseña</Button></Link>
              </div>}
              {!isDetail &&<div className="mb-15 container-button">
                <Link to={`/editar-administrador/${props.userInfo.privatePerson.privateUserId}`}><Button className="button purple outline">Editar mis datos</Button></Link>
              </div>}
              {isDetail &&
                <Button className="button purple outline mb-15 mr-15" loading={buttonLoadingDelete} onClick={() => setShowModalDelete(true)}>
                  Eliminar
                </Button>
              }
              {isDetail && 
                <Button className="button purple outline mb-15mr-15" loading={buttonLoadingBlocked} onClick={() => handleBlockedUser(currentAdmin.blocked ? false : true)}>
                  {currentAdmin.blocked ? 'Habilitar' : 'Deshabilitar'}
                </Button>
              }
            </div>
          </div>
          <ReserveInnerFooter back={back}/>
          {/* {successUpdateImage && <ModalBox success setShowModal={setSuccessUpdateImage} content="Foto actualizada con éxito"/>} */}
          {/* {errorUpdateImage && <ModalBox error setShowModal={setErrorUpdateImage} content="Error al actualizar su foto, intentelo de nuevo"/>} */}
          {showModalDelete && <ReserveSuccessModal buttonLoading={buttonLoadingDelete} title='ELIMINAR ADMINISTRADOR' message={`Usted está por eliminar al administrador ${currentAdmin.privatePerson.firstName}, ¿Estás seguro?`}  handleCancel={() => setShowModalDelete(false)}  deleteAdmin={deleteAdmin} setShowModalDelete={setShowModalDelete}  />}

          {deleteSuccess && <Modal success setShowModal={setDeleteSucess} content={desabledDeleteMessageModal} />}
          {deleteError && <Modal error setShowModal={setDeleteError} content={desabledDeleteMessageModal} />}
          {blockedSuccess && <Modal success setShowModal={setBlockedSuccess} content={desabledDeleteMessageModal} />}
          {blockedError && <Modal error setShowModal={setBlockedError} content={desabledDeleteMessageModal} />}
         

          {rolSuccess && <Modal success  setShowModal={setRolSuccess} content={rolMessageModal}/>}
          {rolError && <Modal error setShowModal={setRolError} content={rolMessageModal}/>}
        </div>
      </ReserveWrapper>
    )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(ProfileAdmin);
