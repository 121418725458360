import { useState, useEffect } from 'react'
import { getInstructors } from '../services/getInstructor'
import { getInstructorsSelect } from '../services/getInstructorsSelect'

const INITIAL_PAGE = 0
export const useInstructors = ({ sport, activityId, pagination = true } = {}) => {
  const [loadingInstructors, setLoadingInstructors] = useState(true)
  const [instructors, setInstructors] = useState([]) 
  const [instructorsCount, setInstructorsCount] = useState([]) 
  const [page, setPage] = useState(INITIAL_PAGE) 
  const [errorInstructors, setErrorInstructors] = useState(null) 
  
  useEffect(() => {
    setLoadingInstructors(true)
    pagination ?
    getInstructors({page: page}).then(data => {
      setInstructors(prevInstructors => prevInstructors.concat(data.rows)) 
      setInstructorsCount(data.count) 
      setLoadingInstructors(false) 
    }).catch(err => {
      setErrorInstructors(err)
    })
    :
    getInstructorsSelect({sport: sport}).then(data => {
      setInstructors(data) 
      setInstructorsCount(data) 
      setLoadingInstructors(false) 
    }).catch(err => {
      setErrorInstructors(err)
    })
  }, [activityId, sport, page, pagination])

  return { loadingInstructors, instructors, instructorsCount, setPage, errorInstructors }
}
