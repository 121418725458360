import { Button, Input, Form } from "antd";
import React, { useState } from "react";
import { withRouter } from "react-router";
import request from "../../../services/request";
import ReserveInnerTitle from "../../Atoms/ReserveInnerTitle/ReserveInnerTitle";
// import SpinnerLoader from "../../Atoms/SpinnerLoader/SpinnerLoader";
// import Modal from "../../Molecules/Modal/Modal";
import ReserveFooter from "../../Organisms/ReserveFooter/ReserveFooter";
import ReserveHeader from "../../Organisms/ReserveHeader/ReserveHeader";
import ModalBox from '../../Molecules/Modal/Modal';
import './NewPassword.scss'

const NewPassword = ({ match }) => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const [successPassword, setSuccessPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');
  
  const onFinish = async (e) => {
    setButtonLoading(true)
    const newHeaders = {
      "Content-Type": "application/json",
       Authorization: `Bearer ${match.params.token}`,
    };
    try {
      const response = await request('POST', '/recover-password', {password : e.password}, newHeaders)
      // console.log('response', response)
      const data = await response.json()
      // console.log('data', data)
      if(response.status === 200) {
        setSuccessPassword(true)
        setButtonLoading(false)
        setPasswordMessage(data.message)
      } else {
        setErrorPassword(true)
        setPasswordMessage(data.message)
        setButtonLoading(false)
      }
    }catch(err) {
      // console.log(err)
      setButtonLoading(false)
      setErrorPassword(true)
      setPasswordMessage('Ha ocurrido un error inesperado, por favor intenta de nuevo o más tarde')
    }
  }
  
  return (
    <div className="container-new-password">
      <ReserveHeader simple/>
      <div className="form-new-password box mb-30">
        <ReserveInnerTitle activity={false} title='RECUPERA TU CONTRASEÑA' subtitle='Complete el formulario para actualizar su contraseña '/>
        <Form onFinish={(e) => onFinish(e)}>
          <div>
            <p className="title-small-blue mb-5">Nueva contraseña: </p>
            <Form.Item
              name="password"
              hasFeedback
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: "Campo obligatorio",
                },
                {
                  validator: (_, value) =>
                    /\S{8,}/.test(value)
                      ? Promise.resolve()
                      : Promise.reject("Minimo 8 catacteres"),
                },
              ]}
            >
              <Input.Password placeholder="Nueva contraseña" className="input" />
            </Form.Item>
          </div>

          <div>
            <p className="title-small-blue mb-5">Confirma contraseña: </p>
            <Form.Item
              name="confirmed-password"
              hasFeedback
              dependencies={["password"]}
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: "Campo obligatorio",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("password") ||
                      getFieldValue("password").trim() === value.trim()
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Las contraseñas no coinciden");
                  },
                }) /*, {validator: (_, value) => value === 'hola' ? Promise.resolve() : Promise.reject('formato malo, validar formato con regex aqui')}*/,
              ]}
            >
              <Input.Password
                placeholder="Confirma contraseña"
                className="input"
              />
            </Form.Item>
          </div>

          <div className="create-admin--btn mt-20">
            <Button
              htmlType="submit"
              className="button purple"
              loading={buttonLoading}
            >
              Cambiar Contraseña
            </Button>
          </div>
        </Form>
      </div>
      <ReserveFooter />
      {successPassword && <ModalBox success successRecoverPassword setShowModal={setSuccessPassword} content={passwordMessage}/>}
      {errorPassword && <ModalBox error setShowModal={setErrorPassword} content={passwordMessage}/>}
    </div>
  );
};

export default withRouter(NewPassword);
