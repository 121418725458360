import { useState, useEffect } from 'react'
import { getCurrentAdmin } from '../services/getCurrentAdmin'

export const useCurrentAdmin = ({idAdmin}) => {
  const [loadingAdmin, setLoadingAdmin] = useState(true)
  const [currentAdmin, setCurrentAdmin] = useState([]) 
  const [loadingCurrentAdmin, setLoadingCurrentAdmin] = useState(false) 

  useEffect(() => {
    if(idAdmin) {
      setLoadingCurrentAdmin(false)
      setLoadingAdmin(true)
      getCurrentAdmin({idAdmin}).then(data => {
        setCurrentAdmin(data) 
        // console.log('currentAdmin: ', data) 
        setLoadingAdmin(false) 
      })
    }else {
      return
    }
  }, [idAdmin, loadingCurrentAdmin])

  return { currentAdmin, loadingAdmin, setLoadingCurrentAdmin }
}
