import request from "./request"

export const getStatusWeekedCurrent = async ({action, uniqueId}) => {
  try {
    const response = await request('GET', `/partner/${action}/${uniqueId}`) 
    const data = await response.json()  
    // console.log('data partner current', data)
    if(response.status === 200) {
      return data
    } else if(response.status === 404) {
      return data
    } else {
      throw Error('Error al obtener el estado actual de la semana')
    }
  } catch (error) {
    // console.log('error', error)
    throw Error('Error al obtener el estado actual de la semana')
  }
}
