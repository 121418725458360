import { useState, useEffect } from 'react'
import { getSchudulesRelation } from '../services/getSchudulesRelation'

export const useSchedulesByRelation = (scheduleId,limit,offset) => {
  const [loadingSchedulesByRelation, setLoadingSchedulesByRelation] = useState(true)
  const [reloadShudules, setReloadShudules] = useState(false)
  const [schedulesByRelation, setSchedulesByRelation] = useState([]) 
  
  useEffect(() => {
    setReloadShudules(false)
    if(scheduleId) {
      // console.log('scheduleId,limit,offset', scheduleId,limit,offset)
      setLoadingSchedulesByRelation(true)
      getSchudulesRelation(scheduleId, limit, offset).then(data => {
        setSchedulesByRelation(data) 
        setLoadingSchedulesByRelation(false) 
      }).catch(err => {
        throw Error(err)
      })
    } else {
      return
    }
  }, [scheduleId, limit, offset, reloadShudules])

  return { loadingSchedulesByRelation, schedulesByRelation, setReloadShudules, setLoadingSchedulesByRelation }
}
