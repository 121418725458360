import React from 'react';
import ReserveFooter from '../ReserveFooter/ReserveFooter';
import ReserveHeader from '../ReserveHeader/ReserveHeader';
import ReserveMenu from '../ReserveMenu/ReserveMenu';
import {withRouter} from 'react-router';
import './ReserveWrapper.scss';
import {
  LoadingOutlined,
} from '@ant-design/icons';

function ReserveWrapper(props) {
	return (
		<>
			<ReserveHeader />
			<div className="reserve-body">
				<ReserveMenu {...props} hidden/> {/* hidden para ocultarlo en movil */}
				<div className={'reserve-body-main '+(props.centerX ? 'reserve-body-center-x ':'')+(props.centerY ? 'reserve-body-center-y':'')}>
					{
	          props.loading === true ?
	          <div className="reserve-body-center-x reserve-body-center-y loading-icon"><LoadingOutlined /></div>
	          :
	          <div>
						{props.children}
						</div>
	        }
				</div>
			</div>
			<ReserveFooter />
		</>
	)
}

export default withRouter(ReserveWrapper);
