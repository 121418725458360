import React, { useState } from 'react';
import {Modal, Button} from 'antd';
import {withRouter} from 'react-router-dom';
import logo from '../../../assets/logo-vaac-blue.svg';
// import iconTenis from '../../../assets/icon-tenis.svg';
// import iconCycling from '../../../assets/icon-cycling.svg';
// import iconTrx from '../../../assets/icon-trx.svg';
// import iconOthers from '../../../assets/icon-others.svg';
import './ReserveQualifyModal.scss'
import Qualifyitem from './QualifyItem';

const ReserveQualifyModal = (props) => {
  const [attendedUsers, setAttendedUsers] = useState({});

  // useEffect(() => {
  //   // console.log('ReserveQualifyModal Render!!')
  // }, [])
  
  const confirmAttended = (id, attended, responsable) => {
    setAttendedUsers(users => {
      // console.log('users', users)
      // console.log('responsable', responsable)
      const usersCopy = {...users};
      usersCopy[id] = [attended, responsable];
      return usersCopy
    })
  }

  const submit = () => {
    // console.log('attendedUsers', attendedUsers)
    props.qualifyReserve(attendedUsers);
  }

  return (
    <>
     <div className="modal-reserves">
      <Modal footer={null} visible={true} centered closable={props.handleCancel ? true : false} onCancel={() => props.handleCancel && props.handleCancel()}>
        <div className="modal-body-reserves">
          <div className="main-modal-body-reserves--logo"><img src={logo} alt="Logo Vaac" className="modal-body-reserves--logo"/></div>
          {/* {props.activity && <div><img src={icons[props.activity]} alt="Icono" className="modal-body-reserves--icon"/></div>} */}
          <h6 className="title-upper-blue mt-25">{props.title}</h6>
          <p className="modal-body-reserves-message">{ props.message }</p>
          {
            props.qualifyReserve && // Botones para cuando quiere calificar una reserva
            <>
              <div className="mb-35 mt-20 reserve-qualify-list">
                {
                  props.listSocio.map((socio, index) => 
                    <Qualifyitem key={index} listSocio={props.listSocio} socio={socio} confirmAttended={(id, attended, responsable) => confirmAttended(id, attended, responsable)} />
                  )
                }
              </div>
              <Button className="button purple mb-10  ml-5 mr-5" loading={props.buttonLoading} onClick={submit}>Confirmar</Button> {/*Cuando ejecuto la funcion le paso los socios ya calificados, el resultado viene en la propiedad qualifyResult creada en el componente Quilify Item*/}
            </>
          } 
        </div>
      </Modal>
     </div>     
    </>
  )
}
export default withRouter(ReserveQualifyModal);
