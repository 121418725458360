import React from 'react'
import { BellOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import './IconBell.scss';
import { useSelector } from 'react-redux';

const IconBell = ( { setVisibleDrawer }) => {
  const notificationsCount = useSelector(state => state.userReducer.notificationsCount)

  const handleClickBell = () => {
    setVisibleDrawer(true)
  }

  return (
    <>
     <Badge className="count-notification" count={notificationsCount > 9 ? `${9}+` :notificationsCount} gap={1}>
      <div className="main-icon-bell" onClick={() => handleClickBell()}>
          <div className="icon-bell">
            <BellOutlined />
          </div>
          <CaretDownOutlined />
        </div>
    </Badge>
    </>
  )
}

export default IconBell




// import React from 'react'
// import {BellOutlined, CaretDownOutlined} from '@ant-design/icons';
// import './IconBell.scss';
// // import DrawerNotification from '../../Molecules/DrawerNotification/DrawerNotification';

// const IconBell = () => {
//   // const [visibleDrawer, setVisibleDrawer] = useState(false);
//   // const onClose = () => setVisibleDrawer(false);
    
//   return (
//     <>
//       {/* <div className="main-icon-bell" onClick={() => setVisibleDrawer(true)}> */}
//       <div className="main-icon-bell">
//         <div className="icon-bell">
//           <BellOutlined />
//         </div>
//         <CaretDownOutlined />
//       </div>
//       {/* <DrawerNotification visible={visibleDrawer} onClose={onClose}/> */}
//     </>
//   )
// }

// export default IconBell
