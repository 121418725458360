
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
// import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './FormInstructor.scss';
import { connect } from 'react-redux';
import { CameraOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import ModalBox from '../../Molecules/Modal/Modal';
import request from '../../../services/request';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import { messageCatchRequest } from '../../../services/helpers';


const FormInstructor = (props) => {
      // const [profileImg, setProfileImg] = useState(props.history.location.pathname.includes('/editar') ? 'https://www.precor.com/es-xl/blog/wp-content/uploads/sites/6/2017/02/Precor_In-Club_DPL-DSL-Coach_C1_1311-600x400.jpg' : null)
    const [profileImg, setProfileImg] = useState(null)
    const [buttonLoading, setButtonLoading] = useState(false);
    // const [successUpdateImage, setSuccessUpdateImage] = useState(false);
    // const [errorUpdateImage, setErrorUpdateImage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [buttonLoadingStatus, setButtonLoadingStatus] = useState(false);
    const [currentInstructor, setCurrentInstructor] = useState({});
    const [statusInstructor, setStatusInstructor] = useState(null); // Estatus del instdcutor
    const [showModalStatus, setShowModalStatus] = useState(false); // Estatus del instdcutor
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [buttonLoadingDelete, setButtonLoadingDelete] = useState(false);
    const [successDeleteInstructor, setSuccessDeleteInstructor] = useState(false); //C UANDO ELIMINO UN INSTRUCTOR MUESTRO EL MODAL 
    const [successEditInstructor, setSuccessEditInstructor] = useState(false); //C UANDO editor UN INSTRUCTOR MUESTRO EL MODAL 
    const [errorDeleteCreateInstructor, setErrorDeleteCreateInstructor] = useState(false); //C UANDO editor UN INSTRUCTOR MUESTRO EL MODAL 
    const [successCreateInstructor, setSuccessCreateInstructor] = useState(false); //C UANDO CREO UN INSTRUCTOR MUESTRO EL MODAL 
    const [successStatusInstructor, setSuccessStatusInstructor] = useState(false); //C UANDO CREO UN INSTRUCTOR MUESTRO EL MODAL 
    const [messageInstructor, setMessageInstructor] = useState(''); //C UANDO CREO UN INSTRUCTOR MUESTRO EL MODAL 
    let isEdit = props.history.location.pathname.includes('/editar');
    
    const back = () => props.history.push('/instructores');

    useEffect(() => {
      if(isEdit) {
        const getInstructor = async () => {
          const url = `/instructor/${props.match.params.id}`
          try {
            const response = await request('GET', url)
            // console.log(response)
            if(response.status === 200) {
              const data = await response.json()
              // console.log(data)
              setCurrentInstructor(data.instructor)
            }else {
              props.history.push('/instructores')
            }
            setLoading(false)
          } catch (error) {
            props.history.push('/instructores')
            // console.log(error)
          }
        }
        getInstructor();
      }
    }, [loading, isEdit, props.match.params.id, props.history])

    // Cargando la  foto del usuari/instructor
    const changeUserPicture = async (e) => {  
      const imageFile = e.target.files[0];  // Foto que sube el usuario 
      const options = {   // Opciones para comprimir la foto 
        maxSizeMB: 1,
        maxWidthOrHeight: 1080,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        if(compressedFile.type.includes('image/')) {   // Previsualizando el archivo
          const reader = new FileReader();
          reader.onload = () =>{
            if(reader.readyState === 2){
              setProfileImg(reader.result) // Convierto la foto a base64
              // console.log('reader 2', reader) 
            }
          }
          reader.readAsDataURL(compressedFile)
        }
      } catch (error) {
        // console.log('Error al comprimir la foto', error);
        return error
      }
    }

    // Creando/Editando instructor
    const onFinish = async (e) => { 
      setButtonLoading(true);
      if(isEdit) {
        const url = `/update-instructor/${props.match.params.id}` 
        let body = {
          firstName: e.firstName,
          lastName: e.lastName,
          legalId: e.legalId,
          profilePicture: profileImg
        }
        // console.log(body)
        // console.log('profileImg', profileImg)
        try {
          const response = await request('PATCH', url, body)
          const data = await response.json()
          // console.log('response', response)
          if(response.status === 200) {
            setMessageInstructor(data.message)
            setSuccessEditInstructor(true);
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
            setMessageInstructor(data.message)
            setErrorDeleteCreateInstructor(true)
          }
        } catch (error) {
          setMessageInstructor(messageCatchRequest)
          setButtonLoading(false);
          setErrorDeleteCreateInstructor(true)
          // console.log(error)
        }
      } else {
        const url = `/create-instructor`
        let body = {
          firstName: e.firstName,
          lastName: e.lastName,
          legalId: e.legalId,
          profilePicture: profileImg
        }
        try {
          const response = await request('POST', url, body)
          const data = await response.json()
          // console.log('response', response)
          if(response.status === 200) {
            setButtonLoading(false);
            setSuccessCreateInstructor(true)
            setMessageInstructor(data.message)
          } else {
            setButtonLoading(false);
            setMessageInstructor(data.message)
            setErrorDeleteCreateInstructor(true)
          }
        } catch (error) {
          setMessageInstructor(messageCatchRequest)
          setButtonLoading(false);
          setErrorDeleteCreateInstructor(true)
          // console.log(error)
        }
      }
    }

    // Cambiar estatus de instductor 
    const changeStatus = async(value) => {
      // console.log(value)
      setShowModalStatus(true);
      setStatusInstructor(value)
    }

    // funcnion Cambiar estatus de instductor 
    const handleChangeStatus = async () => {
      setButtonLoadingStatus(true)
      const body = {
        available : statusInstructor
      }
      // console.log(statusInstructor)
      const url = `/handle-instructor-availability/${props.match.params.id}` 
      try {
        const response = await request('PATCH', url, body)
        const data = await response.json()
        // console.log('response', response)
        if(response.status === 200) {
          setSuccessStatusInstructor(true);
          // setSuccessCreateInstructor(true)
          setMessageInstructor(data.message)
          setShowModalStatus(false)
          setButtonLoadingStatus(false);
        } else {
          setButtonLoadingStatus(false);
          setMessageInstructor(data.message)
          setErrorDeleteCreateInstructor(true)
        }
      } catch (error) {
        setMessageInstructor(messageCatchRequest)
        setButtonLoadingStatus(false);
        setErrorDeleteCreateInstructor(true)
        // console.log(error)
      }
    }

    // Eliminar un instructor
    const deleteInstructor = async () => {
      setButtonLoadingDelete(true)
      // console.log('Aqui borrar')
      const url = `/delete-instructor/${props.match.params.id}` 
      try {
        const response = await request('DELETE', url)
        const data = await response.json()
        // console.log('response', response)
        if(response.status === 200) {
          setMessageInstructor(data.message)
          setSuccessDeleteInstructor(true);
          // props.history.push('/instructores');
          setButtonLoadingDelete(false);
        } else {
          setButtonLoadingDelete(false);
          setMessageInstructor(data.message)
          setErrorDeleteCreateInstructor(true)
        }
      } catch (error) {
        setMessageInstructor(messageCatchRequest)
        setButtonLoadingDelete(false)
        setErrorDeleteCreateInstructor(true)
        // console.log(error)
      }
    }

    return ( 
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis datos: " breadcrumb={['Inicio > ', 'Mis datos']}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title={isEdit ? 'EDITAR INSTRUCTOR' : 'CREAR INSTRUCTOR'} subtitle={`A continuación puede ${isEdit ? 'editar' : 'crear'} un instuctor.`}/>
          <div className='main-profile-partner'>
            <div className="main-profile-partner--parte-1">
              {
                profileImg !== null ?  /* Imagen previsualisada */
                <div className="main-profile-partner--img-container">
                  {!buttonLoading && <label htmlFor="input-file-user"><CameraOutlined className="main-profile-partner--icon-camera"/></label>}
                  <img src={profileImg} alt="Instructor" />
                </div>
                :
                <div className="main-profile-partner--img-container">
                  {!isEdit ? 
                    <img className="create-picture--instuctor" src={process.env.REACT_APP_ICON_CREATE_USER_PHOTO} alt="Instructor" />
                    : loading ? <SpinnerLoader small /> :
                    <img src={currentInstructor.pictureUrl || process.env.REACT_APP_DEFAULT_PHOTO_USER} alt="Instructor" />
                  }
                </div>
              }
              <input 
                type="file" 
                onChange={e => changeUserPicture(e)}
                className="input-file"
                accept=".png, .jpg, .jpeg, image/jpeg, image/jpg, image/png, image/x-png"
                id="input-file-user"
              />
            {/* Muestro el boton de cargar foto si aun no la he puesto */}
             { 
                profileImg === null && /* Si aun no coloco foto pongo Cargar foto */
               <label htmlFor="input-file-user" className="button turquoise outline mt-15 label-file-user">Cargar foto</label>
             }
            </div>
            <div className="main-profile-instuctor--parte-2 mb-25">
              {/* {console.log('currentInstructor',currentInstructor)} */}
                {
                  isEdit && loading ? <SpinnerLoader small/>
                  : 
                  <Form onFinish={(e) => onFinish(e)} className="create-form_instructor"
                    initialValues={{
                      firstName: isEdit ? currentInstructor.firstName : '',
                      lastName: isEdit ? currentInstructor.lastName : '',
                      legalId: isEdit ? currentInstructor.legalId : '',
                      status: isEdit ? currentInstructor.available : ''
                    }}
                  >
                  <div>
                    <div>
                      <p className='title-small-blue mb-5'>Nombre: </p>
                      <Form.Item name="firstName" placeholder="Ingrese su nombre" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Input className="input" />
                      </Form.Item>
                    </div>

                    <div>
                      <p className='title-small-blue mb-5'>Apellido: </p>
                      <Form.Item name="lastName" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Input className="input" />
                      </Form.Item>
                    </div>

                    <div>
                      <p className='title-small-blue mb-5'>Cédula: </p>
                      <Form.Item name="legalId" rules={[{required: true, message: 'Campo obligatorio' }]}>
                        <Input className="input" />
                      </Form.Item>
                    </div>

                    {
                      isEdit &&
                      <div>
                      <p className='title-small-blue mb-5'>Estatus: </p>
                      <Form.Item name='status' className='input-filter block'>
                        <Select placeholder='Estatus' onChange={value => changeStatus(value)}>
                          <Select.Option key={true} value={true}>Activo</Select.Option>,
                          <Select.Option key={false} value={false}>Inactivo</Select.Option>,
                        </Select>
                      </Form.Item>
                    </div>
                    }
                  </div>

                  <div className="create-admin--btn bt-15">
                    {!isEdit && <Button htmlType="submit" className="button purple" loading={buttonLoading}>
                      Crear Instructor
                    </Button>}
                    {isEdit && 
                    <>
                      <Button className="button purple outline mr-15" loading={buttonLoadingDelete} onClick={() => setShowModalDelete(true)}>
                        Eliminar
                      </Button>
                      <Button htmlType="submit" className="button purple mt-15" loading={buttonLoading}>
                        Guardar Datos
                      </Button>
                    </>
                    }
                  </div>
                </Form>
                }
              </div>
              
          </div>
          {/* {successUpdateImage && <ModalBox success setShowModal={setSuccessUpdateImage} content={messageInstructor} />} */}
          {/* {errorUpdateImage && <ModalBox error setShowModal={setErrorUpdateImage} content={messageInstructor}  />} */}

          {/* modal para eliminar instrucor  */}
          {showModalDelete && <ReserveSuccessModal buttonLoading={buttonLoadingDelete} title='ELIMINAR INSTRUCTOR' message={`Usted está por eliminar al instructor ${currentInstructor.firstName} ${currentInstructor.lastName}, ¿Estás seguro?`}  handleCancel={() => setShowModalDelete(false)}  deleteInstructor={deleteInstructor} setShowModalDelete={setShowModalDelete}  />}
          
          {/* modalcuando se elimina el instrucor  */}
          {successDeleteInstructor && <ModalBox success instructorDelete setShowModal={setSuccessDeleteInstructor} content={messageInstructor} />}
          {/* modal cuando se creo/edito al instrucor y sale mal */}
          {errorDeleteCreateInstructor && <ModalBox error setShowModal={setErrorDeleteCreateInstructor} content={messageInstructor} />}
          {/* modalcuando se edita el instrucor  */}
          {successEditInstructor && <ModalBox success instructorEdit setShowModal={setSuccessEditInstructor} content={messageInstructor} />}
          {successStatusInstructor && <ModalBox success setShowModal={setSuccessStatusInstructor} content={messageInstructor} />}
          {/* modalcuando se CREO el instrucor  */}
          {successCreateInstructor && <ModalBox instructorCreate success setShowModal={setSuccessCreateInstructor} content={messageInstructor} />}
          {/* modal para cmabiar el status del instrucor  */}
          {showModalStatus && <ReserveSuccessModal buttonLoading={buttonLoadingStatus} title='CAMBIAR ESTATUS' message={`Seguro que desea cambiar el estatus del instructor ${currentInstructor.firstName} ${currentInstructor.lastName}, a ${statusInstructor ? "Activo" : "Inactivo"}`}  handleCancel={() => setShowModalStatus(false)} setShowModalStatus={setShowModalStatus} changeStatus={handleChangeStatus} />}
          <ReserveInnerFooter back={back}/>
        </div>
      </ReserveWrapper>
    )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(FormInstructor);
