import React, { useState } from 'react'
import ReserveFooter from '../../Organisms/ReserveFooter/ReserveFooter';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logoLogin from '../../../assets/vaac-dashboard-logo-white.svg'
import request from '../../../services/request';
import ModalBox from '../../Molecules/Modal/Modal';

const Login = () => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const [successPassword, setSuccessPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordMessage, setErrorPasswordMessage] = useState('');
 
  const onFinish = async (e) => {
   setButtonLoading(true);
   const body = { email: e.email }
   try {
      const response = await request('POST', '/forgot-password', body)
      const data = await response.json()
      // console.log('response', response)
      // console.log('data', data)
      if(response.status === 200) {
        setSuccessPassword(true)
        setErrorPassword(false)
        setButtonLoading(false);
      } else if(response.status === 406) {
        if(data.message === 'Tu cuenta se encuentra bloqueada. Contacta al administrador principal.') {
          setErrorPassword(true)
          setErrorPasswordMessage('Tu cuenta se encuentra bloqueada. Contacta al administrador principal.')
          setButtonLoading(false);
        } else {
          setErrorPassword(true)
          setErrorPasswordMessage('Su cuenta aun no ha sido verificada, por favor verifíquela para realizar esta acción.')
          setButtonLoading(false);
        } 
      } else if(response.status === 404) {
        setErrorPassword(true)
        setErrorPasswordMessage('Correo no encontrado, revíselo e inténtelo nuevamente.')
        setButtonLoading(false);
      } else {
        setErrorPassword(true)
        setErrorPasswordMessage('Error, intentelo más tarde. ')
        setButtonLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setErrorPassword(true)
      setErrorPasswordMessage('Error, intentelo más tarde. ')
      setButtonLoading(false);
    }
  }

  return (
    <div className="reserve-login-admin">
      <div className="background-vaac">
        <div className="capa"></div>
      </div>
      <div className="capa-gris"></div>
      <header  className="reserve-login-admin--header">
        <img src={logoLogin} alt="Logo Vaac"/>
      </header>
      <div className="reserve-login-admin--form">
        <div>
          <h3 className="reserve-login-admin--title mb-10">Cambiar contraseña</h3>
          
          <Form onFinish={(e) => onFinish(e)} >
           
            <Form.Item label="Correo electrónico:" name="email" rules={[{required: true, message: 'Campo obligatorio' }]}>
              <Input />
            </Form.Item>
      
            <Form.Item className="mt-15">
              <Button htmlType="submit" icon={<LockOutlined />} loading={buttonLoading} className="button blue center-btn btn-login">
                Solicitar cambio
              </Button>
            </Form.Item>

            <div className="reserve-login-admin--links">
              <Link to="/"><p className="mb-5 link">Recordé mi contraseña, ¡Quiero ingresar!</p></Link>
            </div>
          </Form>
        </div>
      </div>
      <div className="reserve-login-admin--footer">
        <ReserveFooter />
      </div>
      {successPassword && <ModalBox success setShowModal={setSuccessPassword} content={`Por favor revise su correo para el cambio de contraseña`}/>}
      {errorPassword && <ModalBox error setShowModal={setErrorPassword} content={errorPasswordMessage}/>}
    </div>
  )
}

export default Login;
