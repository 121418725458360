import React, {useState, useEffect} from 'react';
import {Table, Button} from 'antd';
import {Link, withRouter} from 'react-router-dom';
// import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
// import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
// import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import request from '../../../services/request';
import { useSchedulesByRelation } from '../../../customHooks/useSchudulesByRelation';
import ModalBox from '../../Molecules/Modal/Modal';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
// import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import './ListRelationByShedules.scss';

function ListRelationByShedules( {scheduleId, ...props} ) {
    const [successStatusSchedule, setSuccessStatusSchedule] = useState(false);
    const [successCancelClass, setSuccessCancelClass] = useState(false);
    const [errorStatusSchedule, setErrorStatusSchedule] = useState(false);
    const [errorCancelClass, setErrorCancelClass] = useState(false);
    const [rows, setRows] = useState([]);
    const [limit, setLimit] = useState(300)
    const [showcancelClass, setShowcancelClass] = useState(false)
    const [scheduleAssociationId, setScheduleAssociationId] = useState(null)
    const [totalCount, setTotalCount] = useState(0)
    const [loader, setLoader] = useState(false)
    const [buttonLoadingCancelClass, setButtonLoadingCancelClass] = useState(false)
    const offset = 0;

    const { loadingSchedulesByRelation, schedulesByRelation, setReloadShudules, setLoadingSchedulesByRelation } = useSchedulesByRelation(scheduleId,limit,offset)
    
    const columns = [{
        title: 'Deporte/Actividad',
        dataIndex: 'sport',
        key: 'sport'
    },
    {
        title: 'Sala',
        dataIndex: 'room',
        key: 'room',
    },
    {
        title: 'Instructor',
        dataIndex: 'instructor',
        key: 'instructor',
    },
    {
        title: 'Editar',
        dataIndex: 'edit',
        key: 'edit',
        width: 85
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        fixed: 'right',
        width: 120
    },
    {
        title: 'Cancelar',
        dataIndex: 'cancel',
        key: 'cancel',
        fixed: 'right',
        width: 120
    }
  ];

      //habilitar o deshabilitar relacion de horario
      const changeAvailability = async (relationId, available) => {
        setLoadingSchedulesByRelation(true)
        // console.log('valor relationId antes ', relationId)
        // console.log('valor availability antes ', available)
        // setButtonLoadingStatus(true)
        const url = `/handle-schedule-association-availability/${relationId}`
        const body = {
          available: !available
        }
        try {
          // console.log('valor availability', body)
          const response = await request('PATCH', url, body)
          // console.log('response', response)
          if(response.status === 200) {
            // console.log(response)
            setSuccessStatusSchedule(true);
            setReloadShudules(true)
            setLoadingSchedulesByRelation(false)
            // props.history.push(`/editar-horario/${scheduleId}`);
          } else {
            // setButtonLoading(false);
            setLoader(false)
            setLoadingSchedulesByRelation(false)
            setErrorStatusSchedule(true)
          }
        } catch (error) {
          // console.log(error)
          setLoadingSchedulesByRelation(false)
          setErrorStatusSchedule(true)
        }
      }

    // Cancelar una relacion / 
    const cancelClass = async ({reason}) => {
      setButtonLoadingCancelClass(true)

      const body = {
        scheduleAssociationId: parseInt(scheduleAssociationId),
        cancellationReason: reason
      }
      // console.log(body)
      const url = '/cancel-class'
      try {
        const response = await request('POST', url, body)
        // console.log('response', response)
        if(response.status === 200) {
          // console.log(response)
          setSuccessCancelClass(true)
          setShowcancelClass(false)
          setButtonLoadingCancelClass(false)
        } else {
          // console.log(response)
          setSuccessCancelClass(false)
          setErrorCancelClass(true)
          setButtonLoadingCancelClass(false)
        }
      } catch (error) {
        setButtonLoadingCancelClass(false)
        // console.log(error)
      }
    }

    useEffect(()=> {
      setLoader(false)

      // Funcion para obtener instructores
      const getSchudulesRelation = async () => {
        // console.log('schedulesByRelation', schedulesByRelation)
        let dataRows = []
        if(!loadingSchedulesByRelation && schedulesByRelation) {
          schedulesByRelation.rows.forEach(row => 
            {
              dataRows.push({
                sport: row.sport ? row.sport.name : 'N/A',
                room: row.room ? row.room.name : 'N/A',
                instructor: row.instructor ? row.instructor.firstName : 'N/A',
                edit: <Link to={`/editar-relacion/horario/${row.scheduleId}/${row.id}`}>Editar</Link>,
                status: <span className="link" onClick={() => changeAvailability(row.id, row.available)}>{row.available ? 'Deshabilitar' : 'Habilitar'}</span>,
                cancel: 
                  <span className="link" onClick={() => {
                    setScheduleAssociationId(row.id)
                    setShowcancelClass(true)
                  }}>
                  Cancelar
                  </span>,
                key: row.id,
              })   
            }
          ) 
            setRows(dataRows)
            setTotalCount(schedulesByRelation.count)
        }  
      }
    
      getSchudulesRelation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSchedulesByRelation, schedulesByRelation, limit, loader])

    // Funcion para mostrar mas instructores
    const showMoreInstructors = () => {
      setLimit(limit + 5)
      //getSchudulesRelation();
    }

    return (
      <>
      <div className='mb-15'>
        <ReserveInnerTitle title={`RELACIONES`} subtitle={`A continuación un listado de las relaciones del horario`}/>
        <div className="list-relation-btns">
          <span></span>
          <Link to={`/crear-relacion/horario/${scheduleId}`}>
            <Button className="button purple button-create-relation">
                Crear relación
            </Button>
          </Link>
        </div>
       
        {<Table loading={loadingSchedulesByRelation} scroll={{ x: 620 }} rowClassName={(a,b)=>b%2 === 0 && 'bg-light'} className='reserve-active-table' columns={columns} dataSource={rows} pagination={false}/>}
        {!loadingSchedulesByRelation && limit < totalCount && <Button className="button turquoise outline center-btn mt-20" onClick={() => showMoreInstructors()}>Mostrar más</Button>}
      </div>
      {successStatusSchedule && <ModalBox changeStatusRelation idRelationStatus={props.match.params.id} success setShowModal={setSuccessStatusSchedule} content={`Estatus cambiado`}/>}
      {successCancelClass && <ModalBox successCancelClass idRelationStatus={props.match.params.id} success setShowModal={setSuccessCancelClass} content={`Clase cancelada.`}/>}
      {errorStatusSchedule && <ModalBox error setShowModal={setErrorStatusSchedule} content={`Error al cambiar el estatus, intentelo de nuevo`}/>}
      {errorCancelClass && <ModalBox error setShowModal={setErrorCancelClass} content={`Error al cancelar la clase, intentelo de nuevo`}/>}
      {showcancelClass && <ReserveSuccessModal buttonLoadingCancelClass={buttonLoadingCancelClass} title='CANCELAR CLASE' message={`Usted está por cancelar esta clase, todas las clases asociadas se cancelarán ¿Estás seguro?`} cancelClassRelation handleCancel={() => setShowcancelClass(false)} cancelClass={cancelClass} setShowcancelClass={setShowcancelClass}  />}
      </>
    )
}

export default withRouter(ListRelationByShedules);
