import React from 'react';
import Seat from '../../Atoms/Seat/Seat';
import AdminSeat from '../../Atoms/Seat/AdminSeat';
import './SeatMap.scss';

function SeatMap(props) {
    return (
        <div className='map-container'>
            {props.seatList && props.seatList.length > 0 && props.seatList.map((row, rowIndex) => {
				return (
					//fila
					<div className='map-row' key={rowIndex}>
						{row.map((seat, seatIndex) => props.admin ? 
							<AdminSeat key={seatIndex} data={seat} saveSeat={props.saveSeat} row={rowIndex} column={seatIndex} /> 
							: 
							<Seat key={seatIndex} data={seat} reservedSeats={props.reservedSeatList} selectedSeats={props.selectedSeats} saveSeat={props.saveSeat} row={rowIndex} column={seatIndex} />)}
					</div>
				)
			})}
        </div>
    )
}
export default SeatMap;