import request from "./request"

export const getSports = async () => {
  const url = `/sports`
  try {
    const response = await request('GET', url)
    if(response.status === 200) {
      const data = await response.json()
      return data.sports
    } else {
      throw Error('Error al obtener los deportes')
    }
  } catch (error) {
    throw Error('Error al obtener los deportes')
  }
}
