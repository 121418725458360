import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import './DetailSala.scss';
import ReserveSuccessModal from '../../Molecules/ReserveSuccessModal/ReserveSuccessModal';
import Modal from '../../Molecules/Modal/Modal';
import request from '../../../services/request';
import ModalBox from '../../Molecules/Modal/Modal';
import { messageCatchRequest } from '../../../services/helpers';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';

const DetailSala = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loaderRoom, setLoaderRoom] = useState(true)
  const [loadingDesactivatedSale, setLoadingDesactivatedSale] = useState(false);
  const [salaDetails, setSalaDetails] = useState(null);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState(false);
  const [sportIcon, setSportIcon] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [sportUrl, setSportUrl] = useState('');
  const [sportTitle, setSportTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const back = () => {
    props.history.goBack()
  }

  // Activar / desactivar sala
  const handleActivateRoom = async ({activate, cancellationReason}) => {
      setLoadingDesactivatedSale(true)
      const response = await request('PATCH', `/handle-room-availability/${props.match.params.id}`, {available: activate, cancellationReason: cancellationReason.cancellationReason});
      const data = await response.json()
      try {
        if (response.ok) {
          setShowModal(false)
          setLoadingDesactivatedSale(false)
          setLoader(true)
          setSuccess(data.message)
        } else {
          setError(true)
          setErrorMessage(data.message)
          setLoadingDesactivatedSale(false)
        }
      } catch (error) {
        setError(true)
        setErrorMessage(messageCatchRequest)
        setLoadingDesactivatedSale(false)
      }
  }

  useEffect(() => {
    setLoaderRoom(true)
    // console.log('Ok recargu la sala')
    const salaRequest = async () => {
      const response = await request('GET', `/room/${props.match.params.id}`);
      if (response.ok) {
        const data = await response.json();
        setSalaDetails(data)
        // console.log('data', data)
        if(data.room.sportId === 1) {
          setSportIcon('tenis')
          setSportUrl('tenis')
          setSportTitle('tenis')
        } else if(data.room.sportId === 2) {
          setSportIcon('cycling')
          setSportUrl('indoor-cycling')
          setSportTitle('indoor-cycling')
        } else if(data.room.sportId === 3) {
          setSportIcon('others')
          setSportUrl('otros')
          setSportTitle('Clases de Salón')
        } else {
          setSportIcon('squash')
          setSportUrl('squash')
          setSportTitle('squash')
        }
        setLoaderRoom(false)
      }
    }
    salaRequest()
  }, [props.match.params.id, loader])

    return (
      <ReserveWrapper centerX>
        <ReserveMainTitle title="Mis datos: " breadcrumb={['Inicio > ', 'Sala/Actividades >', 'Indoor Cyclin >', 'Editar']}/>
        <div className='box mb-30'>
          <ReserveInnerTitle activity={false} title='EDITAR SALA O ACTIVIDAD' subtitle='A continuación horarios e instructores habilitados para esta sala o actividad.'/>
         {salaDetails && <ReserveInnerTitle activity={sportIcon} title={`${sportTitle} - ${salaDetails.room.name}`} />}
            { loaderRoom ? <SpinnerLoader small_x2 /> :
              salaDetails && <div className='main-detail-sale'>
            <div className='main-detail-sale--row-1'>
              <p className="title-upper-red no-space">Detalles: </p>
              <div>
                  <div className="">
                  {(salaDetails.room.sportId !== 1 && salaDetails.room.sportId !== 4) ? 
                  <>
                  <p className="title-small-blue no-space">Reservaciones activas: </p>
                  <div>
                    {
                      salaDetails.checkingCounting.totalActiveReservations.map(activity => 
                      <div key={activity.activity}> 
                        <span className="ml-5"><span className="title-small-blue no-space">{activity.activity}</span> : {activity.total}</span> 
                      </div>
                      )
                    }
                  </div> 
                  </>
                  :
                  <>
                  <p className="title-small-blue no-space">Reservaciones activas para single y double: </p>
                  <div>
                    <span className="ml-5">{salaDetails.checkingCounting.totalActiveReservations}</span> 
                  </div>
                  </>
                  }
                </div>
                <Link to={`/reservas/lista`}>Ver reservaciones</Link>
              </div>
              <div className="mt-20">
                {/* <div className="">
                  <p className="title-small-blue no-space">Reservaciones pendientes: <span className="ml-5">{salaDetails[2].totalPendingReservations}</span></p>
                </div> */}
                <div className="">
                  {(salaDetails.room.sportId !== 1 && salaDetails.room.sportId !== 4) ? 
                  <>
                  <p className="title-small-blue no-space">Reservaciones pendientes:</p>
                  <div>
                    {
                      salaDetails.checkingCounting.totalPendingReservations.map(activity => 
                        <div key={activity.activity}> 
                          <span className="ml-5"><span className="title-small-blue no-space">{activity.activity}</span> : {activity.total}</span> 
                        </div>
                      )
                    }
                  </div>
                  </>
                  :
                  <>
                  <p className="title-small-blue no-space">Reservaciones pendientes:</p>
                  <div>
                    <span className="ml-5">{salaDetails.checkingCounting.totalPendingReservations}</span> 
                  </div>
                  </>
                  }
                </div>
                {/*<Link to="/reservas/tenis">Ver reservaciones</Link>*/}
              </div>
            </div>
            <div className='main-detail-sale--row-2'>
              {salaDetails.totalActivePlaces !== 0 && <div>
                <div className="">
                  <p className="title-small-blue no-space">Activos o espacios habilitados: <span className="ml-5">{salaDetails.totalActivePlaces}</span></p>
                </div>
                <div className="mb-20"><Link to={`/salas/indoor-cycling/mapa/${props.match.params.id}`}>Editar mapa de activos</Link> </div>
              </div>}
              {/*  */}
              <div>
                <div className="">
                  <p className="title-small-blue no-space">Horarios: </p>
                </div>
                <Link to={`/horarios`}>Ver horarios</Link>
                {/* <Link to={`/salas/detalle/horarios/${props.match.params.id}`}>Ver horarios</Link> */}
              </div>
              <div className="mt-20">
                <div className="flex center-cross ">
                  <p className="title-small-blue no-space">Estatus: </p>
                  {salaDetails.room.available ? 
                    <Button className="button small purple ml-10" disabled={salaDetails.room.id === 5 ? true : false} onClick={() => setShowModal(true)}>Desactivar</Button>
                    :
                    <Button className="button small purple ml-10" onClick={() => setShowModal(true)}>Activar</Button>
                    /* <Button className="button small purple ml-10" onClick={() => handleActivateRoom(true)}>Activar</Button> */
                  }
                </div>
                {salaDetails.room.available && <p className="message-detail-sale mt-25">Si desactivas esta sala se bloqueará para ser reservada</p>}
              </div>
            </div>
          </div>}
          <ReserveInnerFooter back={back} table/>
          {showModal && <ReserveSuccessModal statusSale={salaDetails.room.available} loadingDesactivatedSale={loadingDesactivatedSale} activity={sportIcon} title={`SEGURO QUE DESEA ${salaDetails.room.available ? 'DESACTIVAR' : 'ACTIVAR'} ESTA SALA?`} message={`Por favor confirma si desea  ${salaDetails.room.available ? 'desactivar' : 'activar'} ésta sala`} detailSala setShowModal={setShowModal} disableSale={(cancellationReason) => handleActivateRoom({activate: salaDetails.room.available ? false : true, cancellationReason})}/>}
          {success && <Modal success setShowModal={setSuccess} content={success} />}
          {error && <ModalBox error setShowModal={setError} content={errorMessage}/>}
        </div>
      </ReserveWrapper>
    )
}


export default DetailSala
