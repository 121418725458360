import React from 'react'
import { Tabs, Collapse, Button } from 'antd';
import { useParams } from 'react-router-dom';
import ReserveInnerFooter from '../../Atoms/ReserveInnerFooter/ReserveInnerFooter';
import ReserveInnerTitle from '../../Atoms/ReserveInnerTitle/ReserveInnerTitle';
import ReserveMainTitle from '../../Atoms/ReserveMainTitle/ReserveMainTitle';
import ReserveWrapper from '../../Organisms/ReserveWrapper/ReserveWrapper';
import ConsultaSocioDetails from '../ConsultaSocioDetails/ConsultaSocioDetails';
import SpinnerLoader from '../../Atoms/SpinnerLoader/SpinnerLoader';
import ErrorComponent from '../../Atoms/ErrorComponent/ErrorComponent';
import useStatusWeekedCurrent from '../../../customHooks/useStatusWeekedCurrent';
import useStatusWeekedHistory from '../../../customHooks/useStatusWeekedHistory';
import ReserveHistory from '../ReserveHistory/ReserveHistory';
const { TabPane } = Tabs;
const { Panel } = Collapse;


const ConsultaSocioTabs = ({history}) => {
  const { action, uniqueId } = useParams();
  const { partner, penalizations, hoursPlayed, buttonLoading, notFoundPartner, errorSearchSocio } = useStatusWeekedCurrent({ action, uniqueId })
  const { notFoundPartnerHistory, loadingHistory, dataHistoryCount, dataHistory, errorSearchSocioHistory, setPage } = useStatusWeekedHistory({ uniqueId })

  return (
    <ReserveWrapper centerX>
    <ReserveMainTitle title='Consulta:' breadcrumb={['Inicio > ', 'Consulta']}/>
    <div className='box mb-30'>
      {!buttonLoading && <ReserveInnerTitle activity={false} title={notFoundPartner ? 'SIN RESULTADOS' : `DETALLES DEL SOCIO ${partner.name || 'N/A'} `} subtitle={`Acción N°: ${action}`} />}
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Estatus semana actual" key="1">
          {
            buttonLoading 
            ? <SpinnerLoader small /> 
            : errorSearchSocio  
            ? <ErrorComponent customClass="pt-10 pb-10" message="Error al obtener los datos"/>
            : notFoundPartner
            ? <ErrorComponent customClass="pt-10 pb-10" message="Socio no encontrado"/>
            : <ConsultaSocioDetails penalizations={penalizations} hoursPlayed={hoursPlayed} />
          }
        </TabPane>
        <TabPane tab="Histórico semanas" key="2">
          <Collapse /*defaultActiveKey={1} */ accordion expandIconPosition="right">
            {
              loadingHistory 
              ? <SpinnerLoader small /> 
              : errorSearchSocioHistory  
              ? <ErrorComponent customClass="pt-10 pb-10" message="Error al obtener los datos"/>
              : notFoundPartnerHistory
              ? <ErrorComponent customClass="pt-10 pb-10" message="Socio no encontrado"/>
              : dataHistoryCount < 1
              ? <ErrorComponent customClass="pt-10 pb-10" message="Histórico vacío"/>
              :
              dataHistory.map((history, i) => 
                <Panel header={`Semana del: ${history.historyFromDate}`} key={i + 1} >
                  <ConsultaSocioDetails 
                    penalizations={history.penalizations.filter(penalizationesFilter => penalizationesFilter.quantity > 0)} 
                    hoursPlayed={history.hoursPlayed.filter(hourPlayed => hourPlayed.quantity > 0)}   
                  />
                </Panel>
              )
            }
          </Collapse>
          {!loadingHistory && dataHistory.length !== dataHistoryCount && <Button className="button turquoise outline center-btn mt-20" onClick={() => setPage(prevPage => prevPage + 1)}>Mostrar más</Button>}
        </TabPane>
        <TabPane tab="Histórico Reservaciones" key="3">
          <ReserveHistory />
        </TabPane>
      </Tabs>
      <ReserveInnerFooter back={() => history.goBack()}/>
    </div>
    </ReserveWrapper>
  )
}

export default ConsultaSocioTabs
