import React, {useEffect} from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCheckToken } from '../../customHooks/useCheckToken';
import { saveNotifications } from '../../redux/actionsCreators';
import store from '../../redux/store';
import SpinnerLoader from '../Atoms/SpinnerLoader/SpinnerLoader';

const Protected = ({component: Component, ...rest}) => {
  const { loadingUser, isLogged } = useCheckToken()

  useEffect(() => {
    store.dispatch(saveNotifications({page: 0}))
  }, [])
 

  return (
    loadingUser ? <SpinnerLoader /> :
    !isLogged ? <Redirect to="/" /> : <Route {...rest} component={Component} />
  )   
}

export default Protected;
