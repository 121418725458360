import request from "./request"

  // Obteber todas las salas
 export const getRooms = async ({sportId, activityId, reservations = false}) => {
    const url = `/rooms-by-association/${sportId}/${activityId}/${reservations ? 1 : 0}`
    try {
      const response = await request('GET', url)
      if(response.status === 200) {
        const data = await response.json()
        // console.log('data desde getRooms', data)
        return data.rooms
      }else {
        throw Error('Error al obtener las salas')
      }
    } catch (error) {
      throw Error('Error al obtener las salas')
    }
  }
